import React, { FC, useState, useEffect } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import "./TimePicker.css";
import { IAvailabilityTime, IMenu } from "../../../../interface/Menus/IMenu";

interface TimePickerProps {
  control: Control<IMenu>; // No need to get control from useFormContext
  setValue?: (name: string, value: any) => void;
  name: "availability_start_time" | "availability_end_time";
  defaultValue?: IAvailabilityTime;
}

const TimePicker: FC<TimePickerProps> = ({
  control,
  setValue = () => {},
  name,
  defaultValue = { hour: "", minute: "" },
}) => {
  const [isOpenHour, setIsOpenHour] = useState(false);
  const [isOpenMinute, setIsOpenMinute] = useState(false);

  useEffect(() => {
    if (setValue) {
      setValue(`${name}.hour`, defaultValue.hour || "");
      setValue(`${name}.minute`, defaultValue.minute || "");
    }
  }, [setValue, defaultValue.hour, defaultValue.minute, name]);

  const hours = Array.from({ length: 24 }, (_, i) => `${i < 10 ? "0" + i : i}`);
  const minutes = ["00", "15", "30", "45"];

  const handleIsOpen = (timeType, open) => {
    if (timeType === "hour") {
      setIsOpenHour(open);
      setIsOpenMinute(false);
    } else {
      setIsOpenMinute(open);
      setIsOpenHour(false);
    }
  };

  return (
    <div className="relative menu-form-input-field-timepicker">
      <Controller
        name={`${name}.hour`}
        control={control}
        render={({ field }) => (
          <>
            <button
              type="button"
              className="timepicker-btn"
              onClick={() => handleIsOpen("hour", !isOpenHour)}
            >
              {field.value ? `${field.value} h` : "Heure"}
            </button>
            {isOpenHour && (
              <ul className="custom-menu-multiselect-list timepicker-direction-top">
                <li
                  className="custom-menu-multiselect-item custom-menu-multiselect-item-red"
                  onClick={() => {
                    field.onChange("");
                    handleIsOpen("hour", false);
                  }}
                >
                  <FaTimes color="#d51515" />
                </li>
                {hours.map((hour) => (
                  <li
                    key={hour}
                    className="custom-menu-multiselect-item"
                    onClick={() => {
                      field.onChange(hour);
                      handleIsOpen("hour", false);
                    }}
                  >
                    {hour}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      />
      <Controller
        name={`${name}.minute`}
        control={control}
        render={({ field }) => (
          <>
            <button
              type="button"
              className="timepicker-btn"
              onClick={() => handleIsOpen("minute", !isOpenMinute)}
            >
              {field.value ? `${field.value} min` : "Minute"}
            </button>
            {isOpenMinute && (
              <ul className="custom-menu-multiselect-list timepicker-height-and-for-min">
                <li
                  className="custom-menu-multiselect-item custom-menu-multiselect-item-red"
                  onClick={() => {
                    field.onChange("");
                    handleIsOpen("minute", false);
                  }}
                >
                  <FaTimes color="#d51515" />
                </li>
                {minutes.map((minute) => (
                  <li
                    key={minute}
                    className="custom-menu-multiselect-item"
                    onClick={() => {
                      field.onChange(minute);
                      handleIsOpen("minute", false);
                    }}
                  >
                    {minute}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      />
    </div>
  );
};

export default TimePicker;
