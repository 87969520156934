import "./TermsOfService.css";
import BackgroundImage from "../../assets/img/background-login.png";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    height: "100vh",
    maxHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBlock: "2rem",
    overflow: "hidden",
  };

  return (
    <div className="tos-container" style={style}>
      <div className="tos-content-container">
        <div className="tos-p-container">
          <h1 className="tos-title">Conditions Générales d'Utilisation</h1>
          <p>En vigueur au 09/07/2024</p>
          <p>
            Les présentes conditions générales d'utilisation (dites « CGU ») ont
            pour objet l'encadrement juridique des modalités de mise à
            disposition du site et des services par SafeEat et de définir les
            conditions d’accès et d’utilisation des services par « l'Utilisateur
            ».
          </p>
          <p>
            Les présentes CGU sont accessibles sur le site à la rubrique «{" "}
            <Link to={"/conditions-generales-d-utilisation"}>CGU</Link> ».
          </p>
          <p>
            Toute inscription ou utilisation du site implique l'acceptation sans
            aucune réserve ni restriction des présentes CGU par l’utilisateur.
            Lors de l'inscription sur le site via le Formulaire d’inscription,
            chaque utilisateur accepte expressément les présentes CGU en cochant
            la case précédant le texte suivant : « Je reconnais avoir lu et
            compris les CGU et je les accepte ».
          </p>
          <p>
            En cas de non-acceptation des CGU stipulées dans le présent contrat,
            l'Utilisateur se doit de renoncer à l'accès des services proposés
            par le site.
          </p>
          <p>
            <Link to="/">https://app.safeeat.fr</Link> se réserve le droit de
            modifier unilatéralement et à tout moment le contenu des présentes
            CGU.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title" id="article1">
            Article 1 : Les mentions légales
          </h2>
          <p>
            L’édition et la direction de la publication du site{" "}
            <Link to="/">https://app.safeeat.fr</Link> est assurée par Tom
            Lemelle, domicilié 474 rue Gabriel Crochet, 76520
            Franqueville-Saint-Pierre. Numéro de téléphone est 0767338365
            Adresse e-mail{" "}
            <a href="mailto:tom.lemelle@gmail.com">tom.lemelle@gmail.com</a>.
          </p>
          <p>
            L’hébergement de ce site est assuré par la société HOSTINGER
            INTERNATIONAL LTD, dont le siège social se situe 61 Lordou Vironos
            Street, 6023 Larnaca, Chypre, joignable par le moyen suivant :{" "}
            <a href="https://www.hostinger.fr/contact" target="_blank">
              https://www.hostinger.fr/contact
            </a>
            .
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 2 : Accès au site</h2>
          <p>
            Le site <Link to="/">https://app.safeeat.fr</Link> permet à
            l'Utilisateur (client du restaurant) un accès gratuit aux services
            suivants :
          </p>
          <ul>
            <li>
              Scan d'un QR-Code amenant à la page des recettes / menus du
              restaurant.
            </li>
            <li>
              Digitalisation de la carte des menus / recettes des restaurateurs,
              cantines et traiteurs sur notre plateforme : SafeEat disponible
              via le site.
            </li>
            <li>
              Filtrage dynamique des allergènes présents dans les recettes et
              menus du restaurateur.
            </li>
            <li>
              À venir : Filtrage dynamique des régimes alimentaires dans les
              recettes et menus du restaurateur.
            </li>
          </ul>
          <p>
            Le site est accessible gratuitement en tout lieu à tout Utilisateur
            (client du restaurant) ayant un accès à Internet. Tous les frais
            supportés par l'Utilisateur (client du restaurant) pour accéder au
            service (matériel informatique, logiciels, connexion Internet, etc.)
            sont à sa charge.
          </p>
          <p>
            L’Utilisateur non membre (client du restaurant) n'a pas accès aux
            services réservés. L’Utilisateur membre (restaurateur) s’engage à
            fournir des informations sincères et exactes concernant son état
            civil et ses coordonnées, notamment son adresse email et son numéro
            de téléphone ainsi que toutes les données de son restaurant et de
            ses recettes ainsi que ses menus.
          </p>
          <p>
            Pour accéder aux services, l’Utilisateur (restaurateur) doit ensuite
            s'identifier à l'aide de son identifiant et de son mot de passe et
            peut le réinitialiser grâce à la réinitialisation de mot de passe
            sur la page d'accueil de la plateforme.
          </p>
          <p>
            Tout Utilisateur membre (restaurateur) régulièrement inscrit pourra
            également solliciter sa désinscription en se rendant à la page
            dédiée sur son espace personnel. Celle-ci sera effective dans un
            délai raisonnable.
          </p>
          <p>
            Tout événement dû à un cas de force majeure ayant pour conséquence
            un dysfonctionnement du site ou serveur et sous réserve de toute
            interruption ou modification en cas de maintenance, n'engage pas la
            responsabilité de <Link to="/">https://app.safeeat.fr</Link>. Dans
            ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à
            l’éditeur de toute interruption ou suspension de service, même sans
            préavis.
          </p>
          <p>
            L'Utilisateur a la possibilité de contacter le site par messagerie
            électronique à l’adresse email de l’éditeur communiqué à{" "}
            <a href="#article1">l’ARTICLE 1.</a>
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 3 : Collecte des données</h2>
          <p>
            Le site assure à l'Utilisateur une collecte et un traitement
            d'informations personnelles dans le respect de la vie privée
            conformément à la{" "}
            <a
              href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000886460"
              target="_blank"
            >
              loi n°78-17 du 6 janvier 1978
            </a>{" "}
            relative à l'informatique, aux fichiers et aux libertés.
          </p>
          <p>
            En vertu de la loi Informatique et Libertés, en date du 6 janvier
            1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de
            suppression et d'opposition de ses données personnelles.
            L'Utilisateur exerce ce droit :
          </p>
          <ul>
            <li>par mail à l'adresse email ilona.deschamps@safeeat.fr</li>
            <li>via son espace personnel ;</li>
          </ul>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 4 : Propriété intellectuelle
          </h2>
          <p>
            Les marques, logos, signes ainsi que tous les contenus du site
            (textes, images, son…) font l'objet d'une protection par le Code de
            la propriété intellectuelle et plus particulièrement par le droit
            d'auteur.
          </p>
          <p>
            La marque SafeEat est une marque déposée par SafeEat, SAS .Toute
            représentation et/ou reproduction et/ou exploitation partielle ou
            totale de cette marque, de quelque nature que ce soit, est
            totalement prohibée.
          </p>
          <p>
            L'Utilisateur doit solliciter l'autorisation préalable du site pour
            toute reproduction, publication, copie des différents contenus. Il
            s'engage à une utilisation des contenus du site dans un cadre
            strictement privé, toute utilisation à des fins commerciales et
            publicitaires est strictement interdite.
          </p>
          <p>
            Toute représentation totale ou partielle de ce site par quelque
            procédé que ce soit, sans l’autorisation expresse de l’exploitant du
            site Internet constituerait une contrefaçon sanctionnée par{" "}
            <a
              href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032655082#:~:text=La%20contrefa%C3%A7on%20en%20France%20d,300%20000%20euros%20d'amende."
              target="_blank"
            >
              l’article L 335-2
            </a>{" "}
            et suivants du Code de la propriété intellectuelle.
          </p>
          <p>
            Il est rappelé conformément à{" "}
            <a
              href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000037388886/"
              target="_blank"
            >
              l’article L122-5
            </a>{" "}
            du Code de propriété intellectuelle que l’Utilisateur qui reproduit,
            copie ou publie le contenu protégé doit citer l’auteur et sa source.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 5 : Responsabilité</h2>
          <p>
            Les sources des informations diffusées sur le site{" "}
            <Link to="/">https://app.safeeat.fr</Link> sont réputées fiables
            mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs
            ou d’omissions.
          </p>
          <p>
            Les informations communiquées sont présentées à titre indicatif et
            général sans valeur contractuelle. Malgré des mises à jour
            régulières, le site <Link to="/">https://app.safeeat.fr</Link> ne
            peut être tenu responsable de la modification des dispositions
            administratives et juridiques survenant après la publication. De
            même, le site ne peut être tenue responsable de l’utilisation et de
            l’interprétation de l’information contenue dans ce site.
          </p>
          <p>
            L'Utilisateur (restaurateur) s'assure de garder son mot de passe
            secret. Toute divulgation du mot de passe, quelle que soit sa forme,
            est interdite. Il assume les risques liés à l'utilisation de son
            identifiant et mot de passe. Le site décline toute responsabilité.
          </p>
          <p>
            Le site <Link to="/">https://app.safeeat.fr</Link> ne peut être tenu
            pour responsable d’éventuels virus qui pourraient infecter
            l’ordinateur ou tout matériel informatique de l’Internaute, suite à
            une utilisation, à l’accès, ou au téléchargement provenant de ce
            site.
          </p>
          <p>
            La responsabilité du site ne peut être engagée en cas de force
            majeure ou du fait imprévisible et insurmontable d'un tiers.
          </p>
        </div>

        <div className="tos-p-container">
          <h2 className="article-title">Article 6 : Liens hypertextes</h2>
          <p>
            Des liens hypertextes peuvent être présents sur le site.
            L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira
            du site <Link to="/">https://app.safeeat.fr</Link>. Ce dernier n’a
            pas de contrôle sur les pages web sur lesquelles aboutissent ces
            liens et ne saurait, en aucun cas, être responsable de leur contenu.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">Article 7 : Cookies</h2>
          <p>
            L’Utilisateur est informé que lors de ses visites sur le site, un
            cookie peut s’installer automatiquement sur son logiciel de
            navigation.
          </p>
          <p>
            Les cookies sont de petits fichiers stockés temporairement sur le
            disque dur de l’ordinateur de l’Utilisateur par votre navigateur et
            qui sont nécessaires à l’utilisation du site{" "}
            <Link to="/">https://app.safeeat.fr</Link>. Les cookies ne
            contiennent pas d’information personnelle et ne peuvent pas être
            utilisés pour identifier quelqu’un. Un cookie contient un
            identifiant unique, généré aléatoirement et donc anonyme. Certains
            cookies expirent à la fin de la visite de l’Utilisateur, d’autres
            restent.
          </p>
          <p>
            L’information contenue dans les cookies est utilisée pour améliorer
            le site <Link to="/">https://app.safeeat.fr</Link>.
          </p>
          <p>En naviguant sur le site, L’Utilisateur les accepte.</p>
          <p>
            L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des
            paramètres figurant au sein de son logiciel de navigation.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 8 : Publication par l’Utilisateur (Restaurateur)
          </h2>
          <p>
            Le site permet aux membres (restaurateurs) de publier les contenus
            suivants : ajout de son restaurant (nom, email, téléphone,
            description, adresse, type de restauration, type de restaurant,
            couleurs de la charte graphique, logo), recettes du restaurant
            (image, nom, allergènes, description, prix), menus (nom, prix, une
            information sur le menu en question, les dates de disponibilités du
            menu, menu enfant, les recettes associées).
          </p>
          <p>
            Dans ses publications, le membre (restaurateur) s’engage à respecter
            les règles de la Netiquette (règles de bonne conduite de l’internet)
            et les règles de droit en vigueur.
          </p>
          <p>
            Le site peut exercer une modération sur les publications et se
            réserve le droit de refuser leur mise en ligne, sans avoir à s’en
            justifier auprès du membre (restaurateur).
          </p>
          <p>
            Le membre (restaurateur) reste titulaire de l’intégralité de ses
            droits de propriété intellectuelle. Mais en publiant une publication
            sur le site, il cède à la société éditrice le droit non exclusif et
            gratuit de représenter, reproduire, adapter, modifier, diffuser et
            distribuer sa publication, directement ou par un tiers autorisé,
            dans le monde entier, sur tout support (numérique ou physique), pour
            la durée de la propriété intellectuelle. Le Membre (restaurateur)
            cède notamment le droit d'utiliser sa publication sur internet et
            sur les réseaux de téléphonie mobile.
          </p>
          <p>
            La société éditrice s'engage à faire figurer le nom du membre
            (restaurateur) à proximité de chaque utilisation de sa publication.
          </p>
          <p>
            Tout contenu mis en ligne par l'Utilisateur (restaurateur) est de sa
            seule responsabilité. L'Utilisateur (restaurateur) s'engage à ne pas
            mettre en ligne de contenus pouvant porter atteinte aux intérêts de
            tierces personnes. Tout recours en justice engagé par un tiers lésé
            contre le site sera pris en charge par l'Utilisateur (restaurateur).
          </p>
          <p>
            Le contenu de l'Utilisateur (restaurateur) peut être à tout moment
            et pour n'importe quelle raison supprimé ou modifié par le site,
            sans préavis.
          </p>
        </div>
        <div className="tos-separator-container">
          <div className="tos-separator"></div>
        </div>
        <div className="tos-p-container">
          <h2 className="article-title">
            Article 9 : Droit applicable et juridiction compétente
          </h2>
          <p>
            La législation française s'applique au présent contrat. En cas
            d'absence de résolution amiable d'un litige né entre les parties,
            les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l’application des présentes CGU, vous
            pouvez joindre l’éditeur aux coordonnées inscrites à{" "}
            <a href="#article1">l’ARTICLE 1.</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
