import { MdEdit } from "react-icons/md";
import { IRestaurantRecipe } from "../../../../interface/IRestaurantRecipe";
import "./LastRecipesWidget.css";
import { formatDate } from "../../../../helpers/getDate";
import colors from "../../../constants/colors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { addRecipeToStore } from "../../../../store/Restaurant";

const LastRecipesWidget = ({ recipes }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const imageUrl = process.env.REACT_APP_BASE_URL + "uploads/";

  const sortedAndLimitedRecipes: IRestaurantRecipe[] = [...recipes]
    .sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    )
    .slice(0, 2);

  const dispatchToStoreAndRedirect = (recipe: IRestaurantRecipe) => {
    dispatch(
      addRecipeToStore({
        id: recipe.id,
        category: recipe.category,
        name: recipe.name,
        description: recipe.description,
        image: recipe.image,
        allergens: recipe.allergens,
        price: recipe.price,
        archived: recipe.archived,
      })
    );
    navigate("/pro/modifier-une-recette");
  };

  return (
    <div className="last-recipes-container">
      <div className="last-recipe-title">Mes dernières recettes</div>
      <div className="last-recipes-list-container">
        {sortedAndLimitedRecipes.map((recipe: IRestaurantRecipe, idx) => (
          <div key={recipe.id} className="last-recipes-content-wrapper">
            {idx !== sortedAndLimitedRecipes.length && (
              <div className="last-recipes-separator"></div>
            )}
            <div className="last-recipes-content-container">
              <div className="last-recipe-left-container">
                <div className="last-recipe-logo-container">
                  <img
                    src={
                      recipe.image?.breakpoints?.thumbnail?.name
                        ? imageUrl + recipe.image.breakpoints.thumbnail.name
                        : "https://placehold.co/60"
                    }
                    alt={`Photo de ${recipe.name}`}
                  />
                </div>
                <div className="last-recipe-data">
                  <div className="last-recipe-name">{recipe.name}</div>
                  <div className="last-recipe-date">
                    Modifié le{" "}
                    <span style={{ color: colors.green }}>
                      {formatDate(recipe.updated_at)}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="last-recipe-goto-edit-container"
                onClick={() => dispatchToStoreAndRedirect(recipe)}
              >
                <MdEdit className="last-recipe-edit-icon" size={20} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LastRecipesWidget;
