const categoryOptions = [
  {
    name: "Entrée",
  },
  {
    name: "Plat",
  },
  {
    name: "Accompagnement",
  },
  {
    name: "Dessert",
  },
  {
    name: "Cocktail",
  },
  {
    name: "Mocktail",
  },
  {
    name: "Sans alcool",
  },
];

export default categoryOptions;
