import React, { FC, ChangeEvent, useState } from "react";
import { CiSearch } from "react-icons/ci";
import "./SearchComponent.css";

interface SearchComponentProps {
  items: Array<{
    name?: string;
    email?: string;
    phone?: string;
    [key: string]: any;
  }>;
  setFilteredItems: (filteredItems: any[]) => void;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  iconClassName?: string;
}

const SearchComponent: FC<SearchComponentProps> = ({
  items,
  setFilteredItems,
  placeholder = "Chercher...",
  className = "",
  inputClassName = "",
  iconClassName = "",
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    applyFilter(newSearchTerm);
  };

  const applyFilter = (searchTerm: string) => {
    const normalizedSearchTerm = searchTerm
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    const filteredItems = items
      .filter((item) => {
        // Concatenate the values of name, email, and phone then normalize for searching
        const itemData = [
          item.name
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, ""),
          item.email
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, ""),
          item.tel
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, ""),
          item.category
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, ""),
          item.price
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, ""),
        ]
          .filter(Boolean)
          .join(" "); // Only use existing, non-null properties

        return itemData.includes(normalizedSearchTerm);
      })
      .sort((a, b) => (a.name || "").localeCompare(b.name || ""));

    setFilteredItems(filteredItems);
  };

  return (
    <label
      htmlFor="searchInput"
      className={`search-m-e-r-l-container ${className}`}
    >
      <CiSearch className={`search-m-e-r-l-icon ${iconClassName}`} />
      <input
        id="searchInput"
        type="text"
        className={`search-m-e-r-l-input ${inputClassName}`}
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
      />
    </label>
  );
};

export default SearchComponent;
