import { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Review } from "../../../interface/Review";
import { AppDispatch } from "../../../interface/Types/AppDispatch";
import "./ReviewPopUp.css";
import RatingStars from "./RatingStars/RatingStars";
import {
  selectPublicRestaurant,
  selectUserAllergens,
} from "../../../store/PublicRestaurant/PublicRestaurant";
import { restaurantProfile } from "../../../api/api";

interface ReviewPopUpProps {
  setToggleReviewPopUp: (value: boolean) => void;
  toggleReviewPopUp: boolean;
}

const ReviewPopUp: FC<ReviewPopUpProps> = ({
  setToggleReviewPopUp,
  toggleReviewPopUp,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const userAllergens = useSelector(selectUserAllergens);
  const restaurantScanned = useSelector(selectPublicRestaurant);

  const [btnDoNotRecommendIsClicked, setBtnDoNotRecommendIsClicked] =
    useState<boolean>(false);
  const [btnRecommendIsClicked, setBtnRecommendIsClicked] =
    useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Review>({
    defaultValues: {
      rating: 0,
    },
  });

  useEffect(() => {
    setValue("allergens", userAllergens);
    setValue("restaurantProfileId", +restaurantScanned.id);
  }, [userAllergens, restaurantScanned]);

  const onSubmit = async (data: Review) => {
    const dataToSubmit: any = { ...data };
    dataToSubmit.allergens = JSON.stringify(data.allergens);
    const response = await restaurantProfile.addReviewToRestaurantProfile(
      dataToSubmit
    );
    setToggleReviewPopUp(false);
  };

  return (
    <>
      {toggleReviewPopUp && (
        <div
          className="review-pop-up-overlay"
          onClick={() => setToggleReviewPopUp(false)}
        >
          <div
            className={`review-pop-up ${
              toggleReviewPopUp ? "review-pop-up-visible" : ""
            }`}
            style={{
              transform: toggleReviewPopUp
                ? "translateY(0)"
                : "translateY(-100vh)",
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <div className="form-container">
              <div className="form-field-container-without-files rate-title">
                Donnez-nous votre avis 😊
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="form-field-container-without-files">
                  <Controller
                    name="rating"
                    control={control}
                    render={({ field }) => (
                      <RatingStars
                        onChange={(value) => {
                          setValue("rating", value, { shouldValidate: true });
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                  <div className="form-field-title">Votre message</div>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        placeholder="Super restaurant, très bonne gestion des allergènes ..."
                        className="form-field"
                      />
                    )}
                  />
                  <div className="form-field-title">Votre avis</div>
                  <div className="recommendation-buttons">
                    <button
                      type="button"
                      className="recommend-button"
                      onClick={() => {
                        setValue("recommend", true);
                        setBtnRecommendIsClicked(true);
                        setBtnDoNotRecommendIsClicked(false);
                      }}
                    >
                      <div
                        className={
                          btnRecommendIsClicked && "btn-recommend-isClicked"
                        }
                      >
                        Je recommande
                      </div>
                    </button>
                    <button
                      type="button"
                      className="do-not-recommend-button"
                      onClick={() => {
                        setValue("recommend", false);
                        setBtnDoNotRecommendIsClicked(true);
                        setBtnRecommendIsClicked(false);
                      }}
                    >
                      <div
                        className={
                          btnDoNotRecommendIsClicked &&
                          "btn-do-not-recommend-isClicked"
                        }
                      >
                        Je ne recommande pas
                      </div>
                    </button>
                  </div>
                  <button
                    type="submit"
                    className="restaurant-submit-btn review-submit-btn"
                  >
                    Envoyer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewPopUp;
