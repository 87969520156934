import { FC, useRef } from "react";
import "./QRCodeComponent.css";
import QRCode from "react-qr-code";
import { IoCopyOutline, IoPrintOutline } from "react-icons/io5";
import colors from "../../constants/colors";
import { IRestaurantProfile } from "../../../interface/IRestaurantProfile";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../interface/Types/AppDispatch";
import { addPublicRestaurantToStore } from "../../../store/PublicRestaurant/PublicRestaurant";
import useRestaurant from "../../../hooks/useRestaurant";
import { useToast } from "../../../contexts/Toast/ToastProvider";
import { isEmpty } from "../../../helpers/isEmpty";

const QRCodeComponent: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { restaurant } = useRestaurant();
  const { addToast } = useToast();

  const qrCodeRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => qrCodeRef.current,
  });

  const baseUrl = "/restaurants/";
  const url = new URL(window.location.href).origin;

  const formatName = (name: string) => {
    return name
      .normalize("NFD") // Normalise la chaîne de caractères
      .replace(/[\u0300-\u036f]/g, "") // Supprime les accents
      .toLowerCase() // Convertit en minuscules
      .replace(/[^a-z0-9 '-]/g, "") // Supprime les caractères spéciaux restants sauf les apostrophes
      .replace(/\s+/g, "-") // Remplace les espaces par des tirets
      .replace(/-+/g, "-"); // Supprime les tirets multiples consécutifs
  };

  const handleScannedRestaurant = (restaurant: IRestaurantProfile) => {
    dispatch(addPublicRestaurantToStore(restaurant));
    navigate(baseUrl + formatName(restaurant.name));
  };

  const handleCopyQRCode = () => {
    const url = new URL(window.location.href).origin;

    if (!isEmpty(restaurant) && restaurant.qrcode) {
      navigator.clipboard.writeText(url + "/" + restaurant.qrcode.slug);
      addToast(
        "info",
        "QR-Code copié",
        `Le QR-Code du restaurant ${restaurant.qrcode.restaurant_name} a été copié ! 🙌`
      );
    } else {
      addToast(
        "error",
        "Erreur de copie du QR-Code",
        `Une erreur est survenie lors de la copie du QR-Code du restaurant ${restaurant.qrcode.restaurant_name}. 😓`
      );
    }
  };

  return (
    <div className="qr-code-container">
      <div className="qr-code-buttons"></div>
      {isEmpty(restaurant) ? (
        <div className="wrapping-message">
          Aucun QR-Code n'est disponible pour le restaurant.
        </div>
      ) : (
        <>
          <div className="qr-code">
            <div className="qr-code-restaurant-name">
              {restaurant.qrcode.restaurant_name}
            </div>
            <QRCode
              style={{
                width: "100%",
                cursor: "pointer",
                borderRadius: "var(--ten)",
              }}
              value={
                url + baseUrl + formatName(restaurant.qrcode.restaurant_name)
              }
              viewBox={`0 0 256 256`}
              ref={qrCodeRef}
              onClick={() => handleScannedRestaurant(restaurant)}
            />
            <div className="qr-code-links">
              <IoCopyOutline
                size={28}
                color={colors.brown}
                cursor={"pointer"}
                onClick={handleCopyQRCode}
              />
              <IoPrintOutline
                size={28}
                color={colors.brown}
                cursor={"pointer"}
                onClick={handlePrint}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QRCodeComponent;
