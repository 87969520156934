import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectRestaurant } from "../store/Restaurant";
import { selectMenu, selectMenus } from "../store/Menus/Menus";

const useRestaurant = () => {
  const restaurant = useSelector(selectRestaurant);
  const menus = useSelector(selectMenus);
  const menu = useSelector(selectMenu);
  useEffect(() => {}, [restaurant, menus, menu]);
  return {
    restaurant,
    menus,
    menu,
  };
};

export default useRestaurant;
