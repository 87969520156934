import AdminAddMenu from "../../components/Admin/Menus/Add/AdminAddMenu";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminAddMenuPage = () => {
  return (
    <LayoutAdmin subHeading="Ajouter un menu au restaurant">
      <AdminAddMenu />
    </LayoutAdmin>
  );
};

export default AdminAddMenuPage;
