import { FC, useEffect } from "react";
import "./ResetComponent.css";
import "../../../../assets/css/Form.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import handleAxiosError from "../../../../helpers/handleAxiosError";
import { useForm } from "react-hook-form";
import { IReset } from "../../../../interface/IUser";
import { UserProfiles } from "../../../../api/api";
import { PasswordInput } from "../../../Reusables/InputField/InputField";
import { FaSignInAlt } from "react-icons/fa";
import Logo from "../../../../assets/img/safeeat-logo.png";
import colors from "../../../constants/colors";
import BackgroundImage from "../../../../assets/img/background-login.png";

const ResetComponent: FC = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IReset>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const navigate = useNavigate();

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Les mots de passe ne sont pas identiques",
      });
    } else {
      clearErrors("confirmPassword");
    }
  }, [password, confirmPassword, setError, clearErrors]);

  const onSubmit = async (data: IReset) => {
    if (data.password !== data.confirmPassword) {
      return;
    }

    delete data.confirmPassword;
    data.token = token;

    try {
      const response = await UserProfiles.resetPassword(data);
      if (response.message) {
        navigate("/connexion");
      } else {
        console.error("Erreur interne");
      }
    } catch (error: any) {
      handleAxiosError(error);
    }
  };

  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="reset-pwd-container" style={style}>
      <div className="reset-pwd-logo-container">
        <img
          src={Logo}
          className="reset-pwd-safeeat-logo pointer"
          alt="SafeEat Logo"
          onClick={() => navigate("/connexion")}
        />
      </div>
      <div className="reset-pwd-box-container">
        <h2 className="reset-pwd-title">Réinitialiser votre mot de passe</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="reset-pwd-form-container"
        >
          <PasswordInput
            control={control}
            name="password"
            iconStyle={{ color: colors.brown }}
          />
          <PasswordInput
            control={control}
            name="confirmPassword"
            iconStyle={{ color: colors.brown }}
          />
          <div className="reset-pwd-actions-container">
            <button type="submit" className="reset-pwd-btn">
              <FaSignInAlt className="reset-pwd-btn-icon" />
              Envoyer la demande
            </button>
          </div>
        </form>
        <p className="reset-pwd-copyright">
          © 2024 SafeEat. Tous droits réservés.
        </p>
      </div>
    </div>
  );
};

export default ResetComponent;
