import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "./AdminRestaurants.css";
import AdminRestaurantTable from "./AdminRestaurantTable";
import { selectAdminRestaurants } from "../../../../store/Admin/Restaurants/AdminRestaurants";
import { IRestaurantProfile } from "../../../../interface/IRestaurantProfile"; // Check the path and exact interface
import SearchComponent from "../../../../layout/MenusAndRecipesLayout/Search/SearchComponent";

const AdminRestaurants = () => {
  const adminRestaurants = useSelector(selectAdminRestaurants);
  const [filteredRestaurants, setFilteredRestaurants] = useState<
    IRestaurantProfile[]
  >([]);

  useEffect(() => {
    setFilteredRestaurants(adminRestaurants);
  }, [adminRestaurants]);

  return (
    <div className="admin-restaurants-container">
      <h2 className="admin-restaurants-title">
        Tous les restaurants inscrits sur SafeEat
      </h2>
      <div className="admin-restaurants-content-container">
        <SearchComponent
          items={adminRestaurants}
          setFilteredItems={setFilteredRestaurants}
          placeholder="Rechercher un restaurant ..."
        />
        {filteredRestaurants && filteredRestaurants.length > 0 ? (
          <AdminRestaurantTable restaurants={filteredRestaurants} />
        ) : (
          <div>
            Il n'y aucun restaurant de disponible sur la plateforme pour le
            moment.
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminRestaurants;
