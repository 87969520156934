import React, { useState, useEffect } from "react";
import {
  FaCheckCircle,
  FaInfoCircle,
  FaExclamationTriangle,
  FaTimesCircle,
  FaTimes,
} from "react-icons/fa";
import colors from "../constants/colors";
import "./Toast.css";

type ToastType = "success" | "info" | "warning" | "error";

interface ToastProps {
  id: string;
  type: ToastType;
  title: string;
  message: string;
  onClose: () => void;
}

const toastColors = {
  success: colors.darkerGreen,
  info: colors.blue,
  warning: colors.darkerBrown,
  error: colors.red,
};

const Toast: React.FC<ToastProps> = ({ id, type, title, message, onClose }) => {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
    }, 10200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isExiting) {
      const exitTimer = setTimeout(onClose, 200);
      return () => clearTimeout(exitTimer);
    }
  }, [isExiting, onClose]);

  const Icon = {
    success: <FaCheckCircle size={22} />,
    info: <FaInfoCircle size={22} />,
    warning: <FaExclamationTriangle size={22} />,
    error: <FaTimesCircle size={22} />,
  }[type];

  return (
    <div
      className={`relative toast-container ${
        isExiting ? "toast-exit-active" : ""
      }`}
      id={`toast-${id}`}
    >
      <div
        className="toast-wrapper"
        onClick={() => setIsExiting(true)}
        aria-label="Fermer la notification"
      >
        <div className="toast-icon" style={{ color: toastColors[type] }}>
          {Icon}
        </div>
        <div className="toast-content">
          <div className="toast-title" style={{ color: toastColors[type] }}>
            {title}
          </div>
          <div className="toast-message">{message}</div>
        </div>
        <button
          className="toast-close"
          onClick={() => setIsExiting(true)}
          aria-label="Fermer la notification"
        >
          <FaTimes />
        </button>
        <div
          className="toast-time"
          style={{ backgroundColor: toastColors[type] }}
        ></div>
      </div>
    </div>
  );
};

export default Toast;
