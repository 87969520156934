type PathType = { path: string; title: string }[];

const navigationDrawerTop: PathType = [
  { path: "/pro/tableau-de-bord", title: "Tableau de bord" },
  { path: "/pro/mon-restaurant", title: "Mon restaurant" },
  { path: "/pro/mes-recettes", title: "Mes recettes" },
  { path: "/pro/mes-menus", title: "Mes menus" },
  { path: "/pro/qr-code", title: "Mes QR Codes" },
];

const navigationDrawerBottom: PathType = [
  { path: "/pro/ajouter-un-restaurant", title: "Ajouter un restaurant" },
  { path: "/pro/ajouter-une-recette", title: "Ajouter une recette" },
  { path: "/pro/ajouter-un-menu", title: "Ajouter un menu" },
];

const pages = {
  primary: navigationDrawerTop,
  secondary: navigationDrawerBottom,
};

export default pages;
