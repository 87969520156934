import { FC } from "react";
import LayoutPro from "../../layout/LayoutPro";
import EditRecipeComponent from "../../components/Pro/Recipe/Edit/EditRecipeComponent";

const EditRecipe: FC = () => {
  return (
    <LayoutPro subHeading="Modifier une recette de votre carte !">
      <EditRecipeComponent />
    </LayoutPro>
  );
};

export default EditRecipe;
