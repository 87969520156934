import React, { useEffect, useRef, useState } from "react";
import { MdMoreHoriz, MdOutlineModeEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { IRestaurantProfile } from "../../../../interface/IRestaurantProfile";
import { formatDate } from "../../../../helpers/getDate";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { useNotification } from "../../../../hooks/useNotification";
import { addAdminRestaurantToStore } from "../../../../store/Admin/Restaurants/AdminRestaurants";
import { addRestaurantToStore } from "../../../../store/Restaurant";
import { IMenu } from "../../../../interface/Menus/IMenu";
import { deleteMenuForAdmin } from "../../../../store/Admin/Menus/Requests";
import { addMenuToStore } from "../../../../store/Admin/Menus/Menus";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import PaginationButtons from "../../Pagination/PaginationComponent";
import usePagination from "../../../../hooks/usePagination";

interface MenusProps {
  menus: IMenu[];
  restaurant: IRestaurantProfile;
}

const AdminMenusTable: React.FC<MenusProps> = ({ menus, restaurant }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const itemsPerPage = 5;
  const { currentPage, totalPages, next, prev } = usePagination(
    menus.length,
    itemsPerPage
  );
  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const [activePopupId, setActivePopupId] = useState<number | null>(null);
  const [activePreviewPopupId, setActivePreviewPopupId] = useState<
    number | null
  >(null);

  const popUpRef = useRef(null);
  const reviewPopUpRef = useRef(null);

  const togglePopUp = (e, restaurantId: number) => {
    e.stopPropagation();
    setActivePopupId(activePopupId === restaurantId ? null : restaurantId);
  };

  const togglePreviewPopUp = (e, recipeId: number) => {
    e.stopPropagation();
    setActivePreviewPopupId(
      activePreviewPopupId === recipeId ? null : recipeId
    );
  };

  const handleEditRestaurant = (restaurant: IRestaurantProfile) => {
    setActivePopupId(null);
    dispatch(addAdminRestaurantToStore(restaurant));
    navigate("/admin/modifier-un-restaurant");
  };

  const countRecipes = (menu: IMenu): number => {
    const starters = menu.recipes.starter?.length || 0;
    const dishes = menu.recipes.dish?.length || 0;
    const accompaniments = menu.recipes.accompaniment?.length || 0;
    const desserts = menu.recipes.dessert?.length || 0;
    return starters + dishes + accompaniments + desserts;
  };

  const handleDeleteMenu = (menu: IMenu) => {
    handleAsyncResponse(
      dispatch,
      deleteMenuForAdmin(menu.id),
      {
        successTitle: "Menu supprimé",
        successMessage: `Le menu ${menu.name} a été supprimé ! 🙌`,
        successDescription: `Le menu ${menu.name} a été supprimé du restaurant.`,
        navigateTo: "/admin/tous-les-menus",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleEditMenu = (menu: IMenu) => {
    dispatch(addMenuToStore(menu));
    dispatch(addRestaurantToStore(restaurant));
    navigate("/admin/modifier-un-menu");
  };

  useOutsideAlerter(popUpRef, () => setActivePopupId(null));
  useOutsideAlerter(reviewPopUpRef, () => setActivePreviewPopupId(null));

  const mapOverAddress = (address: string) => {
    return address
      .split(" ")
      .map((a) => a + "+")
      .join("")
      .slice(0, -1);
  };

  // Filter, sort, and then paginate the users
  const filteredAndSortedMenus = [...menus].sort((a, b) => a.id - b.id);

  const paginatedMenus = filteredAndSortedMenus.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="admin-restaurant-table-container">
      <div className="admin-restaurant-table-content-container">
        <table>
          <thead>
            <tr>
              <th>Icon</th>
              <th>Nom</th>
              <th>Quantités</th>
              <th>Prix</th>
              <th>Restaurant</th>
              <th>Date de modification</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedMenus &&
              paginatedMenus.map((menu) => {
                return (
                  <tr key={menu.id}>
                    <td>
                      <div className="admin-restaurant-table-no-logo">
                        {menu.name[0].toUpperCase()}
                      </div>
                    </td>
                    <td>{menu.name}</td>
                    <td>{`${countRecipes(menu)} recettes`}</td>
                    <td>{`${menu.price} €`}</td>
                    <td className="relative">
                      <button
                        className="admin-restaurant-table-preview-button"
                        onMouseDown={(e) => togglePreviewPopUp(e, menu.id)}
                      >
                        {restaurant ? restaurant.name : "Aucun restaurant"}
                      </button>
                      {activePreviewPopupId === menu.id && (
                        <div
                          className="admin-restaurant-table-preview-popup-container"
                          ref={reviewPopUpRef}
                        >
                          <div className="admin-restaurant-table-preview-text">
                            {restaurant.name}
                          </div>
                          <div className="admin-restaurant-table-preview-text">
                            <a
                              href={`https://www.google.fr/maps/place/${mapOverAddress(
                                restaurant.address
                              )}`}
                              target="_blank"
                            >
                              {restaurant.address}
                            </a>
                          </div>
                          <div className="admin-restaurant-table-preview-text">
                            <a href={`mailto:${restaurant.email}`}>
                              {restaurant.email}
                            </a>
                          </div>
                          <div className="admin-restaurant-table-preview-text">
                            <a href={`tel:${restaurant.tel}`}>
                              {restaurant.tel}
                            </a>
                          </div>
                          <button
                            className="admin-restaurant-table-preview-button"
                            onClick={() => handleEditRestaurant(restaurant)}
                          >
                            <MdOutlineModeEdit />
                            Modifier
                          </button>
                        </div>
                      )}
                    </td>
                    <td>{formatDate(menu.updated_at)}</td>
                    <td className="relative">
                      <button
                        className="admin-restaurant-table-more-btn"
                        onMouseDown={(e) => togglePopUp(e, menu.id)}
                      >
                        <MdMoreHoriz size={22} />
                      </button>
                      {activePopupId === menu.id && (
                        <div
                          className="admin-restaurant-table-popup-container"
                          ref={popUpRef}
                        >
                          <div
                            className="admin-restaurant-table-popup-edit"
                            onClick={() => handleEditMenu(menu)}
                          >
                            <MdOutlineModeEdit className="admin-restaurant-table-popup-edit-icon" />
                            <span className="admin-restaurant-table-popup-edit-icon">
                              Modifier
                            </span>
                          </div>
                          <div className="admin-restaurants-table-separator-container">
                            <div className="admin-restaurants-table-separator-content"></div>
                          </div>
                          <div
                            className="admin-restaurant-table-popup-delete"
                            onClick={() => handleDeleteMenu(menu)}
                          >
                            <FaRegTrashAlt className="admin-restaurant-table-popup-delete-icon" />
                            <span className="admin-restaurant-table-popup-delete-icon">
                              Supprimer
                            </span>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="admin-restaurant-table-bottom">
        <div className="admin-restaurant-table-restaurants-number">
          Nombre total de menus sur la plateforme : {menus.length}
        </div>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={prev}
          onNext={next}
        />
      </div>
    </div>
  );
};

export default AdminMenusTable;
