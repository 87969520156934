import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminRecipesTable from "./AdminRecipesTable";
import GenericSelectWithoutRHF from "../../../Reusables/GenericSelectWithoutRHF";
import {
  selectAdminRestaurants,
  selectAdminRestaurantId,
  addRestaurantIdToStore,
} from "../../../../store/Admin/Restaurants/AdminRestaurants";
import SearchComponent from "../../../../layout/MenusAndRecipesLayout/Search/SearchComponent";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { fetchAllRecipesForAdmin } from "../../../../store/Admin/Recipes/Requests";
import { selectAdminRecipes } from "../../../../store/Admin/Recipes/Recipes";
import { IRestaurantProfile } from "../../../../interface/IRestaurantProfile";

const AdminRecipes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const adminRestaurants = useSelector(selectAdminRestaurants);
  const recipes = useSelector(selectAdminRecipes);
  const adminRestaurantId = useSelector(selectAdminRestaurantId);
  const [selectedRestaurant, setSelectedRestaurant] =
    useState<IRestaurantProfile | null>(null);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (adminRestaurantId) {
        setIsLoading(true);
        dispatch(fetchAllRecipesForAdmin(adminRestaurantId)).then(() => {
          setIsLoading(false);
        });
      }
    }
  }, [adminRestaurantId, dispatch]);

  useEffect(() => {
    setSelectedRestaurant(
      adminRestaurants.find((r) => r.id === adminRestaurantId) || null
    );
    setFilteredRecipes(recipes);
  }, [adminRestaurantId, adminRestaurants, recipes]);

  const handleRestaurantChange = async (selectedOption) => {
    if (selectedOption.id !== adminRestaurantId) {
      setIsLoading(true);
      await dispatch(fetchAllRecipesForAdmin(selectedOption.id));
      setSelectedRestaurant(selectedOption);
      await dispatch(addRestaurantIdToStore(selectedOption.id));
      setIsLoading(false);
    }
  };

  const restaurantOptions = adminRestaurants.map((restaurant) => ({
    name: restaurant.name,
    id: restaurant.id,
  }));

  return (
    <div className="admin-restaurants-container">
      <h2 className="admin-restaurants-title">
        Toutes les recettes du restaurant
      </h2>
      <div className="admin-restaurants-content-container">
        <GenericSelectWithoutRHF
          options={restaurantOptions}
          category="restaurant"
          onChange={handleRestaurantChange}
          returnObject
          searchBar
          value={selectedRestaurant ? selectedRestaurant.id : ""}
        />
        <SearchComponent
          items={filteredRecipes}
          setFilteredItems={setFilteredRecipes}
          placeholder="Rechercher une recette ..."
        />
        {isLoading ? (
          <div>Chargement des recettes...</div>
        ) : selectedRestaurant ? (
          filteredRecipes.length > 0 ? (
            <AdminRecipesTable
              recipes={filteredRecipes}
              restaurant={selectedRestaurant}
            />
          ) : (
            <div>
              Il n'y a aucune recette disponible pour ce restaurant pour le
              moment.
            </div>
          )
        ) : (
          <div>
            Pour voir les recettes d'un restaurant, veuillez choisir un
            restaurant dans le sélecteur.
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminRecipes;
