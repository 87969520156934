import AdminUsers from "../../components/Admin/Users/AdminUsers/AdminUsers";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminUsersPage = () => {
  return (
    <LayoutAdmin subHeading="Tous les restaurants de SafeEat">
      <AdminUsers />
    </LayoutAdmin>
  );
};

export default AdminUsersPage;
