import { FC, useRef } from "react";
import "./PublicAllergensModal.css";
import { IPublicAllergensModal } from "../../../../../../interface/Public/IPublic";
import useOutsideAlerter from "../../../../../../hooks/useOutsideAlerter";
import { getTextColor } from "../../../../../../helpers/color";
import { capitalize } from "../../../../../../helpers/capitalize";

const PublicAllergensModal: FC<IPublicAllergensModal> = ({
  setToggleAllergensModal,
  allergens,
  category,
  name,
  primaryColor,
}) => {
  const allergensModalRef = useRef(null);
  const textColor: "black" | "white" = getTextColor(primaryColor);

  useOutsideAlerter(allergensModalRef, () => setToggleAllergensModal(false));

  const getCorrectSentence = () => {
    if (category === "entrée" || category === "accompagnement") {
      return "l'" + category;
    } else if (category === "tout") {
      return " : " + name;
    } else {
      return "le " + category;
    }
  };

  return (
    <div className="public-allergens-modal-overlay">
      <div className="public-allergens-modal-container" ref={allergensModalRef}>
        <div className="public-allergens-modal-title">
          Allergènes présents dans {getCorrectSentence()}
        </div>
        <div className="public-allergens-modal-list">
          {allergens.length !== 0 &&
            allergens.map((allergen) => (
              <div
                key={allergen.name}
                className="public-current-allergens-tag"
                style={{ backgroundColor: primaryColor, color: textColor }}
              >
                <div>{capitalize(allergen.name)}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PublicAllergensModal;
