import "./MenusAndRecipesLayout.css";
import { FC } from "react";
import Tabs from "../../components/Tabs/Tabs";
import { MenusAndRecipesLayoutProps } from "./MenusAndRecipes";
import SearchComponent from "./Search/SearchComponent";
import useRestaurant from "../../hooks/useRestaurant";
import SortByDate from "./SortByDate/SortByDate";
import PriceSlider from "./PriceSlider/PriceSlider";
import FilterSelect from "../../components/Reusables/FilterSelect";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../helpers/isEmpty";
import { useToast } from "../../contexts/Toast/ToastProvider";

const MenusAndRecipesLayout: FC<MenusAndRecipesLayoutProps> = ({
  children,
  items,
  setFilteredItems,
  searchPlaceholder,
  pricePlaceholder,
  isRecipePage,
}) => {
  const navigate = useNavigate();

  const { restaurant } = useRestaurant();
  const { addToast } = useToast();

  const categoryOptions = [
    { name: "Entrée" },
    { name: "Plat" },
    { name: "Accompagnement" },
    { name: "Dessert" },
  ];

  const handleCategoryChange = (categoryName) => {
    if (categoryName) {
      const filtered = items.filter((item) => item.category === categoryName);
      setFilteredItems(filtered);
    } else {
      setFilteredItems(items);
    }
  };

  const handleNavigate = () => {
    if (isEmpty(restaurant)) {
      addToast(
        "info",
        "Aucun restaurant",
        "Aucun profil de restaurant trouvé pour cet utilisateur 😓"
      );
      return;
    }
    if (isRecipePage && !isEmpty(restaurant)) {
      return navigate("/pro/ajouter-une-recette");
    } else if (
      !isRecipePage &&
      restaurant.restaurantRecipe &&
      restaurant.restaurantRecipe.length !== 0
    ) {
      return navigate("/pro/ajouter-un-menu");
    } else if (
      !isEmpty(restaurant) &&
      restaurant.restaurantRecipe.length === 0
    ) {
      addToast(
        "info",
        "Aucune recette",
        "Une recette est nécessaire pour créer un menu 😓"
      );
      return;
    }
  };

  const getButtonClassName = () => {
    if (isRecipePage) {
      if (isEmpty(restaurant)) {
        return "m-e-r-l-action-btn-not-allowed";
      }
    } else {
      if (
        !restaurant.restaurantRecipe ||
        restaurant.restaurantRecipe.length === 0
      ) {
        return "m-e-r-l-action-btn-not-allowed";
      }
    }
    return "";
  };

  return (
    <div className="m-e-r-l-container">
      <Tabs restaurant={restaurant} canAddInRestaurantPage={false} />
      <div className="m-e-r-l-filters">
        <div className="m-e-r-l-filters-left">
          <SearchComponent
            items={items}
            setFilteredItems={setFilteredItems}
            placeholder={searchPlaceholder}
          />
          <PriceSlider
            min={0}
            max={500}
            items={items}
            setItems={setFilteredItems}
            pricePlaceholder={pricePlaceholder}
          />
          {isRecipePage && (
            <FilterSelect
              options={categoryOptions}
              category="categorie"
              onCategoryChange={handleCategoryChange}
              isFemale
            />
          )}
        </div>
        <div className="m-e-r-l-filters-right">
          <SortByDate items={items} setItems={setFilteredItems} />
          <div className="m-e-r-l-action-container">
            <button
              onClick={handleNavigate}
              className={`m-e-r-l-action-btn ${getButtonClassName()}`}
            >
              <FaPlus className="m-e-r-l-action-icon" />
            </button>
          </div>
        </div>
      </div>

      <div>{children}</div>
    </div>
  );
};

export default MenusAndRecipesLayout;
