import { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useToast } from "../../../contexts/Toast/ToastProvider";

interface ImageInputProps {
  name: string;
  rules: any;
  defaultValue?: any;
  imageUrl?: string;
}

const ImageInput: FC<ImageInputProps> = ({
  name,
  rules,
  defaultValue = "",
  imageUrl = "",
}) => {
  const { control, setValue } = useFormContext();

  const { addToast } = useToast();

  const [preview, setPreview] = useState<string>(imageUrl || "");

  useEffect(() => {
    const image =
      defaultValue &&
      defaultValue.breakpoints &&
      defaultValue.breakpoints.thumbnail;

    setValue(name, image || null);
  }, [setValue, name]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const validImageTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/webp",
    ];

    if (!validImageTypes.includes(file.type)) {
      addToast(
        "error",
        "Extension de l'image",
        "Veuillez sélectionner une image valide (JPEG, JPG, PNG, WEPB)."
      );
      return;
    }

    const maxSizeInBytes = 2 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      addToast(
        "error",
        "Taille de l'image",
        "La taille de l'image doit être inférieure à 2MB."
      );
      return;
    }

    setPreview(URL.createObjectURL(file));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, ref }, fieldState: { error } }) => (
        <div className="input-container">
          <div className="form-recipe-image-container">
            <label htmlFor={name} className="form-recipe-form-label">
              <AiOutlineCloudUpload size={30} className="upload-icon" />
              <span>Importer l'image</span>
            </label>
            <input
              id={name}
              type="file"
              accept="image/*"
              hidden
              onChange={(e) => {
                handleFileChange(e);
                onChange(e.target.files[0]);
              }}
              onBlur={onBlur}
              ref={ref}
            />
            {preview && (
              <img
                src={preview}
                alt="Image Preview"
                className="image-preview image-preview-logo"
                onClick={() => {
                  setPreview("");
                  onChange(null);
                }}
              />
            )}
          </div>
          {error && <p className="is-mandatory error-msg">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default ImageInput;
