import { useState } from "react";
import "./FilterSelect.css";
import { FaTag, FaCaretDown, FaCaretUp } from "react-icons/fa";

const FilterSelect = ({
  options,
  category,
  onCategoryChange,
  style = {},
  iconColor = "",
  isPublic = false,
  isFemale = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    if (selectedOption === option.name) {
      setSelectedOption("");
      onCategoryChange("");
    } else {
      setSelectedOption(option.name);
      onCategoryChange(option.name);
    }
    setIsOpen(false);
  };

  return (
    <div
      className="relative filter-select-menu-form-input-field-container pointer"
      style={{ ...style }}
      onClick={toggleDropdown}
    >
      <div className="filter-select-name-container">
        <FaTag className="filter-select-icon" color={iconColor && iconColor} />
        <div
          className="color-placeholder"
          style={{ color: isPublic && selectedOption !== "" && "black" }}
        >
          {selectedOption
            ? selectedOption
            : `Sélectionner un${isFemale ? "e" : ""} ${category}`}
        </div>
      </div>
      {isOpen ? (
        <FaCaretUp
          className="filter-select-icon"
          color={iconColor && iconColor}
        />
      ) : (
        <FaCaretDown
          className="filter-select-icon"
          color={iconColor && iconColor}
        />
      )}
      {isOpen && (
        <ul className="filter-select-filter-select-list">
          <li
            key="none"
            className={`filter-select-filter-select-item ${
              !selectedOption ? "selected" : ""
            }`}
            onClick={() => selectOption({ name: "" })}
          >
            Aucun
          </li>
          {options.map((option, index) => (
            <li
              key={index}
              className="filter-select-filter-select-item"
              onClick={() => selectOption(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterSelect;
