const allergenMapping: { [key: string]: string[] } = {
  "gluten/blé": ["blé", "gluten", "avoine", "orge", "seigle", "épeautre"],
  crustacés: [
    "crustacés",
    "crabe",
    "crevette",
    "homard",
    "langoustine",
    "écrevisse",
  ],
  œufs: ["œufs", "oeufs"],
  poisson: [
    "poisson",
    "saumon",
    "thon",
    "cabillaud",
    "aiglefin",
    "flétan",
    "dorade",
    "merlan",
    "maquereau",
    "mérou",
    "perche",
    "raie",
    "sardine",
    "sole",
    "tilapia",
    "truite",
    "turbot",
    "hareng",
    "colin",
    "bar",
    "rouget",
    "anguille",
    "anchois",
    "loup",
    "lotte",
    "morue",
    "vivaneau",
  ],
  "arachide / cacahuètes": ["arachide", "cacahuètes", "cacahuete"],
  soja: ["soja", "tofu", "tempeh"],
  lait: [
    "lait",
    "fromage",
    "lait de vache",
    "lait de bufflone",
    "lait de brebis",
    "lait de chèvre",
    "lait de coco",
  ],
  "fruit à coques": [
    "noix",
    "amande",
    "noisette",
    "noix de coco",
    "pignon de pin",
    "pistache",
    "châtaigne",
  ],
  céleri: ["céleri", "celeri"],
  moutarde: ["moutarde"],
  sésame: ["sésame", "sesame"],
  sulfites: ["sulfites", "vin", "vinaigre", "vinaigre balsamique"],
  lupin: ["lupin"],
  mollusques: [
    "mollusques",
    "calamar",
    "huître",
    "ormeau",
    "coquille Saint-Jacques",
    "praire",
    "bulot",
    "palourde",
    "bigorneau",
    "poulpe",
  ],
};

import { useState, useEffect } from "react";
import { IRestaurantRecipe } from "../../interface/IRestaurantRecipe";
import { IPublicMenus } from "../../interface/Public/IPublic";

// Fonction pour étendre la liste des allergènes avec leurs termes associés
const getAllergenRelatedTerms = (
  allergenFilterIds: string[],
  allergenMapping: { [key: string]: string[] }
) => {
  let extendedAllergenFilterIds = [...allergenFilterIds];

  allergenFilterIds.forEach((id) => {
    Object.values(allergenMapping).forEach((allergenGroup) => {
      if (allergenGroup.includes(id)) {
        extendedAllergenFilterIds = [
          ...new Set([...extendedAllergenFilterIds, ...allergenGroup]),
        ];
      }
    });
  });

  return extendedAllergenFilterIds;
};

const useFilteredItems = (
  publicMenus: IPublicMenus[],
  restaurantRecipes: IRestaurantRecipe[],
  allergenFilterIds: string[],
  category: string
) => {
  const [filteredItems, setFilteredItems] = useState<
    IPublicMenus[] | IRestaurantRecipe[]
  >([]);

  useEffect(() => {
    const safeFilterByAllergens = (recipes: IRestaurantRecipe[]) => {
      // Étendre la liste des allergènes sélectionnés
      const extendedAllergenFilterIds = getAllergenRelatedTerms(
        allergenFilterIds,
        allergenMapping
      );

      return recipes.filter(
        (recipe) =>
          recipe.archived === true &&
          (!recipe.allergens ||
            !recipe.allergens.some((allergen) =>
              extendedAllergenFilterIds.includes(allergen.name)
            ))
      );
    };

    if (category === "menu") {
      const filteredMenus = publicMenus
        .filter((menu) => menu.archived === true)
        .map((menu) => ({
          ...menu,
          recipes: {
            starters: safeFilterByAllergens(menu.recipes.starters),
            dishes: safeFilterByAllergens(menu.recipes.dishes),
            accompaniments: safeFilterByAllergens(menu.recipes.accompaniments),
            desserts: safeFilterByAllergens(menu.recipes.desserts),
          },
        }))
        .filter(
          (menu) =>
            menu.recipes.starters.length > 0 ||
            menu.recipes.dishes.length > 0 ||
            menu.recipes.accompaniments.length > 0 ||
            menu.recipes.desserts.length > 0
        );

      setFilteredItems(filteredMenus);
    } else if (category === "tout") {
      const filteredRecipes = restaurantRecipes.filter(
        (recipe) =>
          recipe.archived === true &&
          (allergenFilterIds.length === 0 ||
            !recipe.allergens?.some((allergen) =>
              getAllergenRelatedTerms(
                allergenFilterIds,
                allergenMapping
              ).includes(allergen.name)
            ))
      );

      setFilteredItems(filteredRecipes);
    } else {
      const filteredRecipes = restaurantRecipes.filter(
        (recipe) =>
          recipe.archived === true &&
          recipe.category.toLowerCase() === category.toLowerCase() &&
          (allergenFilterIds.length === 0 ||
            !recipe.allergens?.some((allergen) =>
              getAllergenRelatedTerms(
                allergenFilterIds,
                allergenMapping
              ).includes(allergen.name)
            ))
      );

      setFilteredItems(filteredRecipes);
    }
  }, [publicMenus, restaurantRecipes, allergenFilterIds, category]);

  return filteredItems;
};

export default useFilteredItems;
