import { useEffect, useState } from "react";
import "./../FormRecipeComponent.css";
import { IRestaurantRecipe } from "../../../../interface/IRestaurantRecipe";
import { FormProvider, useForm } from "react-hook-form";
import { FaTag, FaEuroSign, FaPencilAlt } from "react-icons/fa";
import ImagePlaceholder from "../../../../assets/img/placeholder-logo.svg";
import useRestaurant from "../../../../hooks/useRestaurant";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { selectRecipe } from "../../../../store/Restaurant";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../hooks/useNotification";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import {
  updateRestaurantRecipe,
  deleteRestaurantRecipe,
} from "../../../../store/Restaurant/Recipes/Requests";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import AllergensSelect from "../../../Reusables/InputField/AllergensSelect";
import GenericSelect from "../../../Reusables/InputField/GenericSelect";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import categoryOptionsModel from "../../../../models/categoryOptionsModel";
import { RecipeValidationRules } from "../../../../helpers/Validators/RecipeValidationRules";

const EditRecipeComponent = () => {
  const methods = useForm<IRestaurantRecipe>();
  const validationRules = RecipeValidationRules(methods.getValues);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const { restaurant } = useRestaurant();

  const { addNotification } = useNotification();

  const { addToast } = useToast();

  const recipe = useSelector(selectRecipe);

  const [currentTags, setCurrentTags] = useState<any>(recipe.allergens);

  const imagePath = process.env.REACT_APP_BASE_URL + "uploads/";

  const imagePreview =
    recipe.image && recipe.image.breakpoints
      ? imagePath + recipe.image.breakpoints.thumbnail.name
      : ImagePlaceholder;

  const [wordCount, setWordCount] = useState(0);

  const description = methods.watch("description");

  useEffect(() => {
    const words = description ? description.match(/\b\w+\b/g) : [];
    setWordCount(words ? words.length : 0);
  }, [description, recipe]);

  const onSubmit = async (data: IRestaurantRecipe) => {
    data.archived = true;

    if (!data.price) {
      data.price = null;
    }

    const formData = new FormData();

    if (restaurant.id)
      formData.append("restaurantProfileId", restaurant.id.toString());
    if (data.category) formData.append("category", data.category);
    if (data.name) formData.append("name", data.name);
    if (data.description) formData.append("description", data.description);
    if (data.allergens && data.allergens.length > 0)
      formData.append("allergens", JSON.stringify(data.allergens));
    if (data.price) formData.append("price", data.price);
    if (data.archived) formData.append("archived", String(data.archived));

    // Ajoutez l'image
    if (data.image) {
      formData.append("image", data.image);
    }

    const recipeName = data.name || recipe.name;
    const id = recipe.id;

    handleAsyncResponse(
      dispatch,
      updateRestaurantRecipe({
        id,
        formData,
      }),
      {
        successTitle: `Recette modifiée`,
        successMessage: `Votre recette ${recipeName} a été modifiée ! 🙌`,
        successDescription:
          "Vous avez modifié la recette de votre restaurant: " +
          restaurant.name +
          " 🙌",
        navigateTo: "/pro/mes-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleDeleteRecipe = () => {
    try {
      dispatch(deleteRestaurantRecipe(recipe.id));

      navigate("/pro/mes-recettes");
      addToast(
        "success",
        "Recette supprimée",
        `Votre recette ${recipe.name} a été supprimée ! 🙌`
      );
      addNotification({
        title: `${recipe.name} a été supprimée avec succès ! 🙌`,
        description:
          "Vous avez supprimé la recette : " +
          recipe.name +
          " de votre restaurant",
        originLink: "/pro/mes-recettes",
        type: "recipe",
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de la recette :", error);
      addToast(
        "error",
        "Erreur de suppression de la recette",
        `Une erreur a été détecté lors de la suppression de la recette. 😓`
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Les données de votre recette</h1>
          <p>Remplissez ces champs pour modifier votre recette.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="image-input">L'image</label>
              <ImageInput
                name="image"
                rules={validationRules.image}
                defaultValue={recipe.image}
                imageUrl={imagePreview}
              />
            </div>
          </div>
          <div className="form-recipe-grid">
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-end" }}
            >
              <label htmlFor="name">
                Le nom <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="name"
                rules={validationRules.name}
                placeholder="Ecrivez le nom de votre recette ici"
                defaultValue={recipe.name}
                id="name"
                icon={FaTag}
              />
            </div>
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-end" }}
            >
              <label htmlFor="name">
                La catégorie <span className="is-mandatory">*</span>
              </label>
              <GenericSelect
                name="category"
                rules={validationRules.category}
                options={categoryOptionsModel}
                defaultValue={recipe.category}
                category={"catégorie"}
              />
            </div>
          </div>
          <div className="form-recipe-grid">
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="description">
                La description <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="description"
                rules={validationRules.description}
                placeholder="Ecrivez la description de votre recette ici"
                defaultValue={recipe.description}
                id="name"
                icon={FaPencilAlt}
              />
              <div className="recipe-form-input-words">
                {wordCount} / 50 mots utilisés
              </div>
            </div>

            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="price">Le prix</label>
              <GenericInput
                name="price"
                rules={validationRules.price}
                min={0}
                step={0.01}
                max={1000}
                placeholder="Le prix de votre recette"
                defaultValue={recipe.price}
                id="price"
                icon={FaEuroSign}
                isFlex1={false}
              />
            </div>
          </div>
          <div className="recipe-input-wrapper-container">
            <label htmlFor="price">
              Les allergènes <span className="is-mandatory">*</span>
            </label>
            <AllergensSelect
              name="allergens"
              rules={validationRules.allergens}
              defaultValue={recipe.allergens}
            />
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left">
              <button
                type="button"
                className="recipe-form-delete-button"
                onClick={() => methods.reset()}
              >
                Supprimer la recette
              </button>
            </div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Modifier la recette
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default EditRecipeComponent;
