import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { FaTag } from "react-icons/fa";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import colors from "../constants/colors";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

interface GenericSelectProps {
  options: { name: string; id: string | number }[];
  category: string;
  defaultValue?: string | { name: string; id: string | number };
  value?: string | number; // Specify the type correctly based on your usage
  Icon?: React.ElementType;
  returnObject?: boolean;
  searchBar?: boolean;
  feminineForm?: boolean;
  onChange: (value: string | { name: string; id: string | number }) => void;
}

const GenericSelectWithoutRHF: React.FC<GenericSelectProps> = ({
  options,
  category,
  defaultValue = "",
  value,
  Icon = FaTag,
  returnObject = false,
  searchBar = false,
  feminineForm = false,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    string | { name: string; id: string | number }
  >(typeof defaultValue === "object" ? defaultValue.name : defaultValue);

  const GenericSelectRef = useRef(null);

  useEffect(() => {
    if (!value && !defaultValue) return;

    const initialSelection = options.find(
      (option) =>
        option.id.toString() === value.toString() ||
        option.id.toString() === defaultValue.toString()
    );
    if (initialSelection) {
      const selected = returnObject ? initialSelection : initialSelection.name;
      setSelectedOption(selected);
    }
  }, [value, defaultValue, options, returnObject]);

  const handleSetIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectOption = (option, event) => {
    event.stopPropagation();
    setIsOpen(false);
    const selected = returnObject ? option : option.name;
    setSelectedOption(selected);
    onChange(selected);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.name
          ? option.name.toLowerCase().includes(searchTerm.toLowerCase())
          : false
      ),
    [options, searchTerm]
  );

  useOutsideAlerter(GenericSelectRef, () => setIsOpen(false));

  return (
    <div className="input-container" ref={GenericSelectRef}>
      <div
        className="relative generic-select-container pointer"
        style={{ flex: 1 }}
        onClick={handleSetIsOpen}
      >
        <div className="generic-select-names-container">
          <Icon className="generic-select-icon" />
          <div
            className={`color-placeholder ${
              selectedOption ? "color-black" : ""
            }`}
          >
            {typeof selectedOption === "object"
              ? selectedOption.name
              : selectedOption ||
                `Sélectionnez ${feminineForm ? "une" : "un"} ${category}`}
          </div>
        </div>
        {isOpen ? (
          <IoIosArrowUp color={colors.green} />
        ) : (
          <IoIosArrowDown color={colors.green} />
        )}
        {isOpen && (
          <ul className="generic-select-list">
            {searchBar && (
              <li style={{ marginBottom: "0.3rem" }}>
                <input
                  type="text"
                  className="generic-select-search"
                  placeholder={`Rechercher ${
                    feminineForm ? "une" : "un"
                  } ${category}`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </li>
            )}
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                className="generic-select-item"
                onClick={(event) => handleSelectOption(option, event)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default GenericSelectWithoutRHF;
