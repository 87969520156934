import { FormProvider, useForm } from "react-hook-form";
import { IEditUser, IUser } from "../../../../interface/IUser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import { AuthUpdateUserValidationRules } from "../../../../helpers/Validators/AuthUpdateValidationRules";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import { FaEnvelope, FaUser } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import { editMyAccountForAdmin, selectUser } from "../../../../store/User";

const AdminEditMyAccount = () => {
  const methods = useForm<IUser>();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const adminMyAccount = useSelector(selectUser);

  const onSubmit = async (data: IEditUser) => {
    const formData = new FormData();

    if (data.avatar !== adminMyAccount.avatar)
      formData.append("avatar", data.avatar);
    if (data.firstName !== adminMyAccount.firstName)
      formData.append("firstName", data.firstName);
    if (data.lastName !== adminMyAccount.lastName)
      formData.append("lastName", data.lastName);
    if (data.email !== adminMyAccount.email)
      formData.append("email", data.email);
    if (data.tel !== adminMyAccount.tel) formData.append("tel", data.tel);

    handleAsyncResponse(
      dispatch,
      editMyAccountForAdmin(formData),
      {
        successTitle: `Profil modifié`,
        successMessage: `Votre profil a été modifié ! 🙌`,
        successDescription:
          "Vous avez modifié les données de votre compte professionnel SafeEat.",
        navigateTo: "/admin/mon-profil",
      },
      { addToast, addNotification, navigate }
    );
  };

  const imagePath = process.env.REACT_APP_BASE_URL + "uploads/";
  const avatarPreview =
    adminMyAccount.avatar && adminMyAccount.avatar.breakpoints
      ? imagePath + adminMyAccount.avatar.breakpoints.thumbnail.name
      : null;

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Modifier votre profil</h1>
          <p>Remplissez ces champs pour modifier votre profil.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="logo">Votre photo</label>
              <ImageInput
                name="avatar"
                rules={AuthUpdateUserValidationRules.avatar}
                defaultValue={adminMyAccount.avatar}
                imageUrl={avatarPreview}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="firstName">
                Votre prénom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="firstName"
                rules={AuthUpdateUserValidationRules.firstName}
                defaultValue={adminMyAccount.firstName}
                placeholder="Exemple : Patrick"
                id="firstName"
                icon={FaUser}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="lastName">
                Votre nom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="lastName"
                rules={AuthUpdateUserValidationRules.lastName}
                defaultValue={adminMyAccount.lastName}
                placeholder="Exemple : Dupont"
                id="lastName"
                icon={FaUser}
              />
            </div>
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="email">
                Votre email <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                type="email"
                name="email"
                rules={AuthUpdateUserValidationRules.email}
                defaultValue={adminMyAccount.email}
                placeholder="Exemple : patrick@safeeat.fr"
                id="email"
                icon={FaEnvelope}
              />
            </div>
          </div>
          <div className="recipe-input-wrapper-container">
            <label htmlFor="tel">
              Le téléphone <span className="is-mandatory">*</span>
            </label>
            <GenericInput
              name="tel"
              rules={AuthUpdateUserValidationRules.tel}
              step={0.01}
              defaultValue={adminMyAccount.tel}
              placeholder="Exemple : 07 08 09 04 05"
              id="tel"
              icon={BsFillTelephoneFill}
              isFlex1={false}
            />
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left"></div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Modifier mon profil
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default AdminEditMyAccount;
