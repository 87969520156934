export const getTokenFromLocalStorage = (): string | null => {
  const tokenData = localStorage.getItem("userToken");
  return tokenData ? JSON.parse(tokenData).token : null;
};

export const removeTokenFromLocalStorage = () => {
  localStorage.removeItem("userToken");
};

// Fonction pour récupérer la valeur d'une clé du Local Storage
const getLocalStorageItem = (key: string): string | null =>
  localStorage.getItem(key);

// Fonction pour vérifier si une clé a été modifiée
const isLocalStorageKeyModified = (
  key: string,
  previousValue: string | null
): boolean => {
  const currentValue = getLocalStorageItem(key);
  return currentValue !== previousValue;
};

// Fonction pour vérifier si une clé a été supprimée
const isLocalStorageKeyDeleted = (key: string): boolean => {
  const currentValue = getLocalStorageItem(key);
  return currentValue === null; // La clé n'existe plus
};

// Fonction pour surveiller les changements du Local Storage
export const watchLocalStorageKey = (
  key: string,
  callback: (result: boolean) => void
) => {
  // Stocker la valeur initiale de la clé
  const initialValue = getLocalStorageItem(key);

  // Écouter l'événement storage
  window.addEventListener("storage", (event) => {
    if (event.key === key) {
      // Vérifier si la clé a été modifiée ou supprimée
      if (
        isLocalStorageKeyModified(key, initialValue) ||
        isLocalStorageKeyDeleted(key)
      ) {
        callback(false);
      } else {
        callback(true);
      }
    }
  });
};
