import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateMenu, IMenu } from "../../interface/Menus/IMenu";
import { restaurantMenu } from "../../api/api";
import { ApiErrorResponse } from "../../interface/RejectValue";

export const createMenu = createAsyncThunk<
  IMenu, // Specify the expected type of the data returned by the API
  { restaurantProfileId: number; menuData: ICreateMenu }, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "menus/createMenu",
  async ({ restaurantProfileId, menuData }, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.createMenu(
        restaurantProfileId,
        menuData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const getAllMenus = createAsyncThunk(
  "menus/getAllMenus",
  async (restaurantProfileId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.getAllMenus(restaurantProfileId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const updateRestaurantMenu = createAsyncThunk<
  IMenu, // Specify the expected type of the data returned by the API
  { menuId: number; menuData: ICreateMenu }, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "menus/updateRestaurantMenu",
  async ({ menuId, menuData }, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.updateRestaurantMenu(
        menuData,
        menuId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const toggleArchivedRestaurantMenu = createAsyncThunk<
  { archived: boolean; id: number }, // Type de la réponse en cas de succès
  { id: number; checked: boolean }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "restaurants/toggleArchivedRestaurantMenu",
  async ({ id, checked }, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.updateToggleRestaurantMenu(
        { archived: checked },
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

interface MenuResponse {
  id: number;
  response: any; // Specify a more accurate type based on what your API returns
}

interface RejectValue {
  error: string;
}

export const deleteRestaurantMenu = createAsyncThunk<
  MenuResponse,
  number,
  {
    rejectValue: RejectValue;
  }
>("restaurants/deleteRestaurantMenu", async (id, { rejectWithValue }) => {
  try {
    const response = await restaurantMenu.deleteRestaurantMenu(id);
    if (!response) {
      // Assuming the API returns a non-ok response correctly implies an error
      throw new Error("Failed to delete the menu");
    }
    // Properly structure your response or adapt this line based on your actual API response structure
    return { id, response: response };
  } catch (error: any) {
    // Use rejectWithValue to handle errors properly
    return rejectWithValue({ error: error.message || "Unknown error" });
  }
});
