import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserDocuments, UserProfiles } from "../../../api/api";
import { IDocument } from "../../../interface/Documents/IDocument";
import { IEditUser } from "../../../interface/IUser";
import { ApiErrorResponse } from "../../../interface/RejectValue";

export const reviewDocumentForAdmin = createAsyncThunk<
  IDocument, // Specify the expected type of the data returned by the API
  number, // Parameters passed to the thunk action which is the documentId
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "admin/reviewDocumentForAdmin",
  async (documentId: number, { rejectWithValue }) => {
    try {
      const response = await UserDocuments.reviewDocumentForAdmin(documentId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const validateDocumentForAdmin = createAsyncThunk<
  IDocument, // Specify the expected type of the data returned by the API
  number, // Parameters passed to the thunk action which is the documentId
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "admin/validateDocumentForAdmin",
  async (documentId: number, { rejectWithValue }) => {
    try {
      const response = await UserDocuments.validateDocumentForAdmin(documentId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const refuseDocumentForAdmin = createAsyncThunk<
  IDocument, // Specify the expected type of the data returned by the API
  number, // Parameters passed to the thunk action which is the documentId
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "admin/refuseDocumentForAdmin",
  async (documentId: number, { rejectWithValue }) => {
    try {
      const response = await UserDocuments.refuseDocumentForAdmin(documentId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteDocumentForAdmin = createAsyncThunk(
  "admin/deleteDocumentForAdmin",
  async (documentId: number, { rejectWithValue }) => {
    try {
      const response = await UserDocuments.deleteDocumentForAdmin(documentId);
      return { documentId, ...response }; // Renvoie les données de réponse pour une utilisation dans le fulfilled reducer
    } catch (error) {
      return rejectWithValue(error.response.data); // Gère les erreurs en renvoyant les données d'erreur
    }
  }
);

export const fetchAllDocumentsForAdmin = createAsyncThunk<
  IDocument[], // Type de la réponse attendue de l'API
  number, // Parameters passed to the thunk action which is the userId
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées en cas d'erreur
>("admin/fetchAllDocumentsForAdmin", async (userId, { rejectWithValue }) => {
  try {
    const response = await UserDocuments.getDocuments(userId);
    return response; // Assurez-vous de renvoyer les données appropriées
  } catch (error) {
    // Assurez-vous que le backend renvoie un objet d'erreur qui contient `data.errors`
    return rejectWithValue(error.response.data);
  }
});
