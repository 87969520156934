// Fonction pour convertir une couleur hexadécimale en tableau RGB
function hexToRGB(hex: string): number[] {
  // Supprimer le # s'il est présent
  hex = hex.replace(/^#/, "");

  // Séparer les composantes R, G et B
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
}

// Fonction pour récupérer la luminosité d'une couleur
function getLuminance(color: string): number {
  const rgb = hexToRGB(color);
  const [r, g, b] = rgb.map((value) => value / 255);

  // Calculer la luminosité selon la formule perceptuelle
  return 0.299 * r + 0.587 * g + 0.114 * b;
}

export function getTextColor(bgColor: string | undefined): "black" | "white" {
  if (!bgColor) {
    return "black";
  }

  // Récupérer la luminosité de la couleur de fond
  const luminance = getLuminance(bgColor);

  // Si la couleur de fond est suffisamment sombre, conserver cette couleur.
  if (luminance < 0.6) {
    return "white"; // Texte en blanc pour une couleur de fond sombre
  }

  return "black"; // Texte en noir pour une couleur de fond claire
}
