import { FC } from "react";
import "./../css-pages/Profile.css";
import LayoutPro from "../../layout/LayoutPro";
import RestaurantComponent from "../../components/Pro/Restaurant/RestaurantComponent";

const RestaurantProfile: FC = () => {
  return (
    <LayoutPro subHeading="Mettez à jour le profil de votre restaurant !">
      <RestaurantComponent />
    </LayoutPro>
  );
};

export default RestaurantProfile;
