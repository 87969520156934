import { FC } from "react";
import LayoutPro from "../../layout/LayoutPro";
import RecipesComponent from "../../components/Pro/Recipe/View/RecipesComponent";

const Recipes: FC = () => {
  return (
    <LayoutPro subHeading="Toutes vos recettes au même endroit !">
      <RecipesComponent />
    </LayoutPro>
  );
};

export default Recipes;
