import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logoutAndRemoveUserToStore,
  selectToken,
  setAuthenticated,
} from "../store/User";
import { removeTokenFromLocalStorage } from "../helpers/localStorage";

export const useProtectedRoute = (): void => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken); // Selector pour récupérer le token du store
  const url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.get(url + "validate-token", {
          headers: {
            Authorization: `Bearer ${token.token?.token}`,
            "ngrok-skip-browser-warning": "true",
          },
        });

        // Mise à jour de l'état d'authentification avec la réponse
        dispatch(setAuthenticated(response.data.valid));

        if (!response.data.valid) {
          removeTokenFromLocalStorage();
          dispatch(logoutAndRemoveUserToStore());
          navigate("/connexion");
        }
      } catch (error) {
        console.error("Erreur lors de la validation du token:", error);
        removeTokenFromLocalStorage();
        dispatch(setAuthenticated(false));
        dispatch(logoutAndRemoveUserToStore());
        removeTokenFromLocalStorage();
        navigate("/connexion");
      }
    };

    if (token) {
      validateToken();
    } else {
      dispatch(setAuthenticated(false));
      dispatch(logoutAndRemoveUserToStore());
      removeTokenFromLocalStorage();
      navigate("/connexion");
    }
  }, [token, dispatch, navigate]);
};
