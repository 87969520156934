import { FC } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Dashboard from "../../pages/dashboard/Dashboard";
import AddRecipe from "../../pages/Recipe/AddRecipe";
import Recipes from "../../pages/Recipe/Recipes";
import Profile from "../../pages/Profile/Profile";
import QRCode from "../../pages/QRCode/QRCode";
import RestaurantProfile from "../../pages/RestaurantProfile/RestaurantProfile";
import AddRestaurantProfile from "../../pages/RestaurantProfile/AddRestaurantProfile";
import EditRecipe from "../../pages/Recipe/EditRecipe";
import PublicRestaurant from "../../pages/PublicRestaurant/PublicRestaurant";
import ProtectedRoute from "./ProtectedRoute";
import AskEmail from "../../pages/ResetPassword/AskEmail";
import Reset from "../../pages/ResetPassword/Reset";
import ChartWidget from "../../pages/Widgets/ChartWidget";
import ReviewWidget from "../../pages/Widgets/ReviewWidget";
import AddMenu from "../../pages/Menu/AddMenu";
import Menus from "../../pages/Menu/Menus";
import EditMenu from "../../pages/Menu/EditMenu";
import Login from "../../pages/Login";
import useRestaurant from "../../hooks/useRestaurant";
import { isEmpty } from "../../helpers/isEmpty";
import Roles from "../../enums/Roles";
import AdminRestaurantsPage from "../../pages/Admin/AdminRestaurantsPage";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/User";
import AdminUsersPage from "../../pages/Admin/AdminUsersPage";
import AdminEditUserPage from "../../pages/Admin/AdminEditUserPage";
import AdminEditRestaurantPage from "../../pages/Admin/AdminEditRestaurantPage";
import AdminCreateRecipesPage from "../../pages/Admin/AdminCreateRecipesPage";
import AdminCreateRecipePage from "../../pages/Admin/AdminCreateRecipePage";
import AdminEditRecipePage from "../../pages/Admin/AdminEditRecipePage";
import AdminRecipesPage from "../../pages/Admin/AdminRecipesPage";
import AdminCreateUserPage from "../../pages/Admin/AdminCreateUserPage";
import AdminAddRestaurantPage from "../../pages/Admin/AdminAddRestaurantPage";
import AdminAddMenuPage from "../../pages/Admin/AdminAddMenuPage";
import AdminEditMenuPage from "../../pages/Admin/AdminEditMenuPage";
import AdminMenusPage from "../../pages/Admin/AdminMenusPage";
import DocumentsPage from "../../pages/Documents/DocumentsPage";
import AdminUsersDocuments from "../../pages/Admin/AdminUsersDocuments";
import AdminEditMyAccountPage from "../../pages/Admin/AdminEditMyAccountPage";
import TermsOfService from "../../pages/Legals/TermsOfService";
import PrivacyPolicy from "../../pages/Legals/PrivacyPolicy";
import LegalNotices from "../../pages/Legals/LegalNotices";
import ChooseSidePage from "../../pages/ChooseSidePage";
import RestaurantsPage from "../../pages/RestaurantsPage";

const CustomRouter: FC = () => {
  const { restaurant } = useRestaurant();
  const user = useSelector(selectUser);

  const userDesactivateButLoggedIn = [
    { path: "/pro/activation/mes-documents", element: <DocumentsPage /> },
  ];

  const protectedRoutes = [
    { path: "/pro/tableau-de-bord", element: <Dashboard /> },
    {
      path: "/pro/widgets/classement-des-allergènes",
      element: <ChartWidget />,
    },
    {
      path: "/pro/widgets/reviews",
      element: <ReviewWidget />,
    },
    { path: "/pro/mes-menus", element: <Menus /> },
    {
      path: "/pro/ajouter-un-menu",
      element:
        restaurant &&
        restaurant.restaurantRecipe &&
        restaurant.restaurantRecipe.length !== 0 ? (
          <AddMenu />
        ) : (
          <Navigate to="/pro/mes-menus" replace />
        ),
    },
    { path: "/pro/modifier-un-menu", element: <EditMenu /> },
    { path: "/pro/mes-recettes", element: <Recipes /> },
    {
      path: "/pro/ajouter-une-recette",
      element: !isEmpty(restaurant) ? (
        <AddRecipe />
      ) : (
        <Navigate to="/pro/mes-recettes" replace />
      ),
    },
    { path: "/pro/modifier-une-recette", element: <EditRecipe /> },
    { path: "/pro/ajouter-un-restaurant", element: <AddRestaurantProfile /> },
    { path: "/pro/qr-code", element: <QRCode /> },
    { path: "/pro/mon-restaurant", element: <RestaurantProfile /> },
    { path: "/pro/mon-profil", element: <Profile /> },
    { path: "/deconnexion", element: <Dashboard /> },
  ];

  const adminProtectedRoutes = [
    {
      path: "/admin/tous-les-utilisateurs",
      element: <AdminUsersPage />,
    },
    {
      path: "/admin/tous-les-restaurants",
      element: <AdminRestaurantsPage />,
    },
    {
      path: "/admin/mon-profil",
      element: <AdminEditMyAccountPage />,
    },
    {
      path: "/admin/statistiques",
      element: <AdminRestaurantsPage />,
    },

    {
      path: "/admin/creer-un-utilisateur",
      element: <AdminCreateUserPage />,
    },
    {
      path: "/admin/modifier-un-utilisateur",
      element: <AdminEditUserPage />,
    },
    {
      path: "/admin/ajouter-un-restaurant",
      element: <AdminAddRestaurantPage />,
    },
    {
      path: "/admin/modifier-un-restaurant",
      element: <AdminEditRestaurantPage />,
    },
    {
      path: "/admin/ajouter-plusieurs-recettes",
      element: <AdminCreateRecipesPage />,
    },
    {
      path: "/admin/ajouter-une-recette",
      element: <AdminCreateRecipePage />,
    },
    {
      path: "/admin/modifier-une-recette",
      element: <AdminEditRecipePage />,
    },
    {
      path: "/admin/toutes-les-recettes",
      element: <AdminRecipesPage />,
    },
    {
      path: "/admin/ajouter-un-menu",
      element: <AdminAddMenuPage />,
    },
    {
      path: "/admin/modifier-un-menu",
      element: <AdminEditMenuPage />,
    },
    {
      path: "/admin/tous-les-menus",
      element: <AdminMenusPage />,
    },
    {
      path: "/admin/tous-les-documents",
      element: <AdminUsersDocuments />,
    },
  ];

  const publicRoutes = [
    { path: "/", element: <ChooseSidePage /> },
    { path: "/connexion", element: <Login /> },
    {
      path: "/verifier-mon-email/",
      element: <Login />,
    },
    {
      path: "/envoyer-une-demande-de-réinitialisation-de-mon-mot-de-passe",
      element: <AskEmail />,
    },
    {
      path: "/réinitialiser-mon-mot-de-passe",
      element: <Reset />,
    },
    { path: "/restaurants/:restaurantName", element: <PublicRestaurant /> },
    { path: "/restaurants", element: <RestaurantsPage /> },
    {
      path: "/conditions-generales-d-utilisation",
      element: <TermsOfService />,
    },
    { path: "/conditions-generales-de-vente", element: <TermsOfService /> },
    { path: "/politique-de-confidentialite", element: <PrivacyPolicy /> },
    { path: "/mentions-legales", element: <LegalNotices /> },
  ];

  return (
    <Router>
      <Routes>
        {publicRoutes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}
        {user.rolesId === Roles.PROFESSIONNAL &&
          !user.isActivated &&
          userDesactivateButLoggedIn.map(({ path, element }, index) => (
            <Route
              key={index}
              path={path}
              element={<ProtectedRoute>{element}</ProtectedRoute>}
            />
          ))}
        {user.rolesId === Roles.PROFESSIONNAL &&
          user.isActivated &&
          protectedRoutes.map(({ path, element }, index) => (
            <Route
              key={index}
              path={path}
              element={<ProtectedRoute>{element}</ProtectedRoute>}
            />
          ))}
        {user.rolesId === Roles.ADMIN &&
          adminProtectedRoutes.map(({ path, element }, index) => (
            <Route
              key={index}
              path={path}
              element={<ProtectedRoute>{element}</ProtectedRoute>}
            />
          ))}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default CustomRouter;
