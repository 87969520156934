import LayoutAdmin from "../../layout/LayoutAdmin";
import AdminDocuments from "../../components/Admin/Users/Documents/AdminDocuments";

const AdminUsersDocuments = () => {
  return (
    <LayoutAdmin subHeading="Les documents de l'utilisateur">
      <AdminDocuments />
    </LayoutAdmin>
  );
};

export default AdminUsersDocuments;
