type PathType = { path: string; title: string }[];

const navigationDrawerTop: PathType = [
  { path: "/admin/mon-profil", title: "Mon profil" },
  { path: "/connexion", title: "Se déconnecter" },
];

const pages = {
  primary: navigationDrawerTop,
};

export default pages;
