import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FaTag, FaPencilAlt, FaEuroSign } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../contexts/NotificationContext";
import { useToast } from "../../../contexts/Toast/ToastProvider";
import handleAsyncResponse from "../../../helpers/handleAsyncResponse";
import {
  IRestaurantProfileIdForRecipe,
  IRestaurantRecipe,
} from "../../../interface/IRestaurantRecipe";
import { AppDispatch } from "../../../interface/Types/AppDispatch";
import categoryOptionsModel from "../../../models/categoryOptionsModel";
import AllergensSelect from "../../Reusables/InputField/AllergensSelect";
import GenericSelect from "../../Reusables/InputField/GenericSelect";
import ImageInput from "../../Reusables/InputField/ImageInput";
import { GenericInput } from "../../Reusables/InputField/InputField";
import { selectAdminRestaurants } from "../../../store/Admin/Restaurants/AdminRestaurants";
import { addRecipeForAdmin } from "../../../store/Admin/Recipes/Requests";
import { RecipeValidationRules } from "../../../helpers/Validators/RecipeValidationRules";

const AdminCreateRecipe = () => {
  const methods = useForm<IRestaurantRecipe>();
  const validationRules = RecipeValidationRules(methods.getValues);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const restaurants = useSelector(selectAdminRestaurants);
  const restaurantOptions = restaurants.map((restaurant) => ({
    id: restaurant.id,
    name: restaurant.name,
  }));

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const restaurantIdWatcher = methods.watch("restaurantProfileId" as any);

  const description = methods.watch("description");
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    const words = description ? description.match(/\b\w+\b/g) : [];
    setWordCount(words ? words.length : 0);
  }, [description]);

  const onSubmit = async (data: IRestaurantProfileIdForRecipe) => {
    data.archived = true;

    if (!data.price) {
      data.price = null;
    }

    const formData = new FormData();

    if (data.restaurantProfileId)
      formData.append(
        "restaurantProfileId",
        data.restaurantProfileId.id.toString()
      );
    if (data.category) formData.append("category", data.category);
    if (data.name) formData.append("name", data.name);
    if (data.description) formData.append("description", data.description);
    if (data.allergens && data.allergens.length > 0)
      formData.append("allergens", JSON.stringify(data.allergens));
    if (data.price) formData.append("price", data.price);
    if (data.archived) formData.append("archived", String(data.archived));

    if (data.image) {
      formData.append("image", data.image);
    }

    handleAsyncResponse(
      dispatch,
      addRecipeForAdmin(formData),
      {
        successTitle: `Recette ajoutée`,
        successMessage: `La recette ${formData.get("name")} a été ajouté ! 🙌`,
        successDescription:
          "La recette a été ajouté au restaurant: " +
          data.restaurantProfileId.name +
          " 🙌",
        navigateTo: "/admin/toutes-les-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Les données de votre recette</h1>
          <p>Remplissez ces champs pour ajouter une nouvelle recette.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="restaurantProfileId">
                Le restaurant associé <span className="is-mandatory">*</span>
              </label>
              <GenericSelect
                options={restaurantOptions}
                category={"association à un restaurant"}
                name={"restaurantProfileId"}
                rules={validationRules.restaurantId}
                returnObject
                searchBar
              />
            </div>
            {restaurantIdWatcher ? (
              <>
                <div className="recipe-input-wrapper-container">
                  <label htmlFor="image">
                    L'image <span className="is-mandatory">*</span>
                  </label>
                  <ImageInput name="image" rules={validationRules.image} />
                </div>
                <div className="form-recipe-grid">
                  <div
                    className="recipe-input-wrapper-container"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <label htmlFor="name">
                      Le nom <span className="is-mandatory">*</span>
                    </label>
                    <GenericInput
                      name="name"
                      rules={validationRules.name}
                      placeholder="Ecrivez le nom de votre recette ici"
                      id="name"
                      icon={FaTag}
                    />
                  </div>
                  <div
                    className="recipe-input-wrapper-container"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <label htmlFor="name">
                      La catégorie <span className="is-mandatory">*</span>
                    </label>
                    <GenericSelect
                      name="category"
                      rules={validationRules.category}
                      options={categoryOptionsModel}
                      category={"catégorie"}
                      returnObject={false}
                    />
                  </div>
                </div>
                <div className="form-recipe-grid">
                  <div
                    className="recipe-input-wrapper-container"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <label htmlFor="description">
                      La description <span className="is-mandatory">*</span>
                    </label>

                    <GenericInput
                      name="description"
                      rules={validationRules.description}
                      placeholder="Ecrivez la description de votre recette ici"
                      id="name"
                      icon={FaPencilAlt}
                    />

                    <div className="recipe-form-input-words">
                      {wordCount} / 50 mots utilisés
                    </div>
                  </div>

                  <div
                    className="recipe-input-wrapper-container"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <label htmlFor="price">Le prix</label>

                    <GenericInput
                      type="number"
                      name="price"
                      rules={validationRules.price}
                      min={0}
                      step={0.01}
                      max={1000}
                      placeholder="Le prix de votre recette"
                      id="price"
                      icon={FaEuroSign}
                      isFlex1={false}
                    />
                  </div>
                </div>

                <div className="recipe-input-wrapper-container">
                  <label htmlFor="price">
                    Les allergènes <span className="is-mandatory">*</span>
                  </label>
                  <AllergensSelect
                    name="allergens"
                    rules={validationRules.allergens}
                  />
                </div>
              </>
            ) : (
              <div>Aucun restaurant sélectionné</div>
            )}
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left"></div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Ajouter la recette
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default AdminCreateRecipe;
