import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenu } from "../../../interface/Menus/IMenu";
import { ApiErrorResponse } from "../../../interface/RejectValue";
import {
  createMenuForAdmin,
  deleteMenuForAdmin,
  fetchAllMenusForAdmin,
  toggleArchivedMenuForAdmin,
  updateMenuForAdmin,
} from "./Requests";

export const menusSlice = createSlice({
  name: "adminMenus",
  initialState: {
    menus: <IMenu[]>[],
    menu: <IMenu>{},
    restaurantId: <number>null,
    done: false,
    loading: false,
    errors: {} as any,
  },
  reducers: {
    addMenuToStore(state: any, action) {
      state.menu = action.payload;
    },
    setRestaurantIdToStore(state, action) {
      state.restaurantId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMenuForAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createMenuForAdmin.fulfilled,
        (state, action: PayloadAction<IMenu>) => {
          state.loading = false;

          state.menus.push(action.payload);
        }
      )
      .addCase(createMenuForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllMenusForAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllMenusForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.menus = action.payload;
      })
      .addCase(fetchAllMenusForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMenuForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateMenuForAdmin.fulfilled,
        (state, action: PayloadAction<IMenu>) => {
          state.loading = false;

          const index = state.menus.findIndex(
            (menu) => menu.id === action.payload.id
          );
          if (index !== -1) {
            state.menus[index] = action.payload;
          }
        }
      )
      .addCase(updateMenuForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(toggleArchivedMenuForAdmin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(toggleArchivedMenuForAdmin.fulfilled, (state, action) => {
        state.loading = false;

        const menuIndex = state.menus.findIndex(
          (recipe: IMenu) => recipe.id === action.payload.id
        );
        state.menus[menuIndex].archived = action.payload.archived;
      })
      .addCase(toggleArchivedMenuForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteMenuForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMenuForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.menus = state.menus.filter(
          (menu) => menu.id !== action.payload.id
        );
      })
      .addCase(deleteMenuForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAdminMenus = (state: { adminMenus: { menus: IMenu[] } }) =>
  state.adminMenus.menus;

export const selectAdminMenu = (state: { adminMenus: { menu: IMenu } }) =>
  state.adminMenus.menu;

export const selectRestaurantId = (state: {
  adminDocuments: { restaurantId: number };
}) => state.adminDocuments.restaurantId;

export const { addMenuToStore, setRestaurantIdToStore } = menusSlice.actions;

export default menusSlice.reducer;
