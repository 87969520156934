import "./InputField.css";
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  useFormContext,
} from "react-hook-form";
import { FaEnvelope, FaLock } from "react-icons/fa";
import {
  emailRules,
  passwordRules,
} from "../../../helpers/Validators/AuthValidationRules";
import { IconType } from "react-icons/lib";
import { FC } from "react";

interface GenericInputProps {
  name: string;
  rules?: any;
  icon: IconType;
  placeholder: string;
  type?: string;
  id: string;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: string | number;
  isFlex1?: boolean;
}

export const EmailInput = ({ control, name, iconStyle = {} }) => (
  <Controller
    name={name}
    control={control}
    rules={emailRules}
    defaultValue={""}
    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
      <div className="input-container">
        {error && <p className="is-mandatory error-msg">{error.message}</p>}
        <div className="form-input-field-container" style={{ flex: 1 }}>
          <FaEnvelope className="input-icon" style={{ ...iconStyle }} />
          <input
            type="email"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            className="form-input-field"
            placeholder="Adresse email"
          />
        </div>
      </div>
    )}
  />
);

export const PasswordInput = ({ control, name, iconStyle = {} }) => (
  <Controller
    name={name}
    control={control}
    rules={passwordRules}
    defaultValue={""}
    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
      <div className="input-container">
        {error && <p className="is-mandatory error-msg">{error.message}</p>}
        <div className="form-input-field-container" style={{ flex: 1 }}>
          <FaLock className="input-icon" style={{ ...iconStyle }} />
          <input
            type="password"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            className="form-input-field"
            placeholder="Mot de passe"
          />
        </div>
      </div>
    )}
  />
);

export const GenericInput: FC<GenericInputProps> = ({
  name,
  rules,
  icon: Icon,
  placeholder,
  type = "text",
  id,
  min,
  max,
  step,
  defaultValue = "",
  isFlex1 = true,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={(defaultValue && defaultValue) || ""}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <div className="input-container" style={{ flex: isFlex1 && "1" }}>
          <div className="form-input-field-container" style={{ flex: 1 }}>
            {Icon && <Icon className="input-icon" />}
            <input
              type={type}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              id={id}
              placeholder={placeholder}
              className="form-input-field"
              {...(min !== undefined && { min })}
              {...(max !== undefined && { max })}
              {...(step !== undefined && { step })}
            />
          </div>
          {error && <p className="is-mandatory error-msg">{error.message}</p>}
        </div>
      )}
    />
  );
};
