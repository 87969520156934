import { FC, useState } from "react";
import "./AskEmailComponent.css";
import { Link, useNavigate } from "react-router-dom";
import handleAxiosError from "../../../../helpers/handleAxiosError";
import { useForm } from "react-hook-form";
import { IAsk } from "../../../../interface/IUser";
import { UserProfiles } from "../../../../api/api";
import Logo from "../../../../assets/img/safeeat-logo.png";
import { EmailInput } from "../../../Reusables/InputField/InputField";
import { FaSignInAlt } from "react-icons/fa";
import colors from "../../../constants/colors";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import BackgroundImage from "../../../../assets/img/background-login.png";

const AskEmailComponent: FC = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IAsk>();
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState<boolean>(false);
  const { addToast } = useToast();

  const onSubmit = async (data: IAsk) => {
    try {
      const response = await UserProfiles.askReset(data);
      if (response.message) {
        addToast("success", "Message de l'email", `${response.message} ! 🙌`);
        setIsSent(true);
      }
    } catch (error: any) {
      handleAxiosError(error);
      addToast(
        "error",
        "Erreur d'envoi du mail",
        `Erreur lors de l'envoi, veuillez contacter un administrateur si le problème persiste. 😓`
      );
    }
  };

  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="ask-reset-container" style={style}>
      <div className="ask-reset-logo-container">
        <img
          src={Logo}
          className="ask-reset-safeeat-logo pointer"
          alt="SafeEat Logo"
          onClick={() => navigate("/connexion")}
        />
      </div>
      <div className="ask-reset-box-container">
        <h2 className="ask-reset-title">
          Demande de réinitialisation du mot de passe
        </h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="ask-reset-form-container"
        >
          <EmailInput
            control={control}
            name="email"
            iconStyle={{ color: colors.brown }}
          />
          <div className="ask-reset-actions-container">
            <button
              type="submit"
              className={`ask-reset-btn ${isSent && "isSent"}`}
              disabled={isSent}
            >
              <FaSignInAlt className="ask-reset-btn-icon" />
              Envoyer la demande
            </button>
            <div className="ask-reset-login-page-container">
              <Link to="/connexion" className="ask-reset-redirect">
                Se connecter
              </Link>
            </div>
          </div>
        </form>
        <p className="ask-reset-copyright">
          © 2024 SafeEat. Tous droits réservés.
        </p>
      </div>
    </div>
  );
};

export default AskEmailComponent;
