import { HeaderType } from "../../../../interface/Types/HeaderType";

const ReviewHeader: HeaderType = [
  { title: "ID" },
  { title: "Note" },
  { title: "Description" },
  { title: "Allergènes " },
  { title: "Recommandation " },
  { title: "Modifié le" },
  { title: "Action" },
];

export { ReviewHeader };
