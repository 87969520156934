import { useNotification as useNotificationContext } from "./../contexts/NotificationContext";

export const useNotification = () => {
  const notificationContext = useNotificationContext();

  if (!notificationContext) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }

  return notificationContext;
};
