// RestaurantTabs.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Tabs.css";
import { IRestaurantProfile } from "../../interface/IRestaurantProfile";
import { IoIosArrowDown, IoMdAddCircleOutline } from "react-icons/io";
import colors from "../constants/colors";
import { useMediaQuery } from "react-responsive";

interface RestaurantTabsProps {
  restaurant: IRestaurantProfile;
  // restaurants: IRestaurantProfile[];
  // activeTab: number;
  // onTabClick: (index: number) => void;
  // canAdd: boolean;
  canAddInRestaurantPage: boolean;
}

const Tabs: React.FC<RestaurantTabsProps> = ({
  restaurant,
  // restaurants,
  // activeTab,
  // onTabClick,
  // canAdd,
  canAddInRestaurantPage = false,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="restaurant-tabs"
      style={{
        justifyContent: canAddInRestaurantPage ? "center" : "flex-start",
      }}
    >
      {restaurant && Object.keys(restaurant).length > 0 && (
        <div className="restaurant-tab active">
          <span>{restaurant.name}</span>
          <div className="restaurant-dash-tab active"></div>
        </div>
      )}
    </div>
  );
};

export default Tabs;
