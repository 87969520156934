import "./RecipesComponent.css";
import { FC, useState, useEffect } from "react";
import MenusAndRecipesLayout from "../../../../layout/MenusAndRecipesLayout/MenusAndRecipesLayout";
import useRestaurant from "../../../../hooks/useRestaurant";
import { MenuOrRecipe } from "../../../../layout/MenusAndRecipesLayout/MenusAndRecipes";
import { IRestaurantRecipe } from "../../../../interface/IRestaurantRecipe";
import RecipeCard from "./RecipesCard/RecipeCard";

const RecipesComponent: FC = () => {
  const { restaurant } = useRestaurant();
  const [filteredRecipes, setFilteredRecipes] = useState<MenuOrRecipe[]>([]);

  useEffect(() => {
    if (Array.isArray(restaurant?.restaurantRecipe)) {
      setFilteredRecipes(restaurant.restaurantRecipe as MenuOrRecipe[]);
    }
  }, [restaurant?.restaurantRecipe]);

  return (
    <MenusAndRecipesLayout
      items={restaurant?.restaurantRecipe || []}
      setFilteredItems={setFilteredRecipes}
      searchPlaceholder="Chercher des plats, desserts ..."
      pricePlaceholder="Prix de la recette"
      isRecipePage={true}
    >
      <div className="recipe-cards-container">
        {filteredRecipes.length > 0 ? (
          filteredRecipes.map((card: IRestaurantRecipe, idx: number) => (
            <RecipeCard {...card} key={card.id} idx={idx} />
          ))
        ) : (
          <div>Aucune recette n'a été créée.</div>
        )}
      </div>
    </MenusAndRecipesLayout>
  );
};

export default RecipesComponent;
