import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./MultiSelect.css";
import { useState, FC, useRef, useEffect, ChangeEvent } from "react";
import { RxCross2 } from "react-icons/rx";
import { ISelect, ISelectProps } from "../../interface/ISelect";
import colors from "../constants/colors";
import { IAllergens } from "../../interface/IAllergens";
import hexToRgb from "../../helpers/hexToRgb";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../interface/Types/AppDispatch";
import { selectAllAllergens } from "../../store/Restaurant";
import allergiesModel from "../../models/allergiesModel";
import { CiSearch } from "react-icons/ci";
import { FaExclamationTriangle } from "react-icons/fa";
import { fetchAllAllergens } from "../../store/Restaurant/Recipes/Requests";

const MutliSelect: FC<ISelectProps> = ({
  currentTags,
  addToCurrentTags,
  removeFromCurrentTags,
  border,
  publicTextColor,
  publicColor,
  iconColor,
}) => {
  const tagsContainer = useRef<HTMLDivElement>(null);
  const [toggleInput, setToggleInput] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const allergens = useSelector(selectAllAllergens);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAllergens, setFilteredAllergens] = useState([]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    const filtered = allergens.filter((tag) =>
      tag.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(searchTerm)
    );
    setFilteredAllergens(filtered);
  };

  const handleToggleInput = () => {
    setToggleInput(!toggleInput);
  };

  useEffect(() => {
    dispatch(fetchAllAllergens());
    if (allergens) {
      setFilteredAllergens(allergens);
    }
  }, [dispatch]);

  return (
    <div className="multiselect-container">
      {currentTags.length === 0 && (
        <div
          className="multiselect-title-container"
          style={{ border: `1px solid ${border}` }}
          onClick={() => setToggleInput(!toggleInput)}
        >
          <div className="multiselect-flex-center">
            <FaExclamationTriangle
              className="recipe-icon"
              color={iconColor ? iconColor : colors.green}
            />
            Sélectionner les allergènes
          </div>
          {!toggleInput ? (
            <IoIosArrowDown
              className="close-btn-allergens"
              color={iconColor ? iconColor : colors.green}
            />
          ) : (
            <IoIosArrowUp
              className="close-btn-allergens"
              color={iconColor ? iconColor : colors.green}
            />
          )}
        </div>
      )}
      {currentTags.length > 0 && (
        <div
          className="current-tags-container"
          style={{ border: `1px solid ${border}` }}
          onClick={handleToggleInput}
        >
          <div>
            {currentTags.map((tag, idx) => (
              <div
                className={`current-tag ${
                  publicColor ? "" : idx % 2 == 0 ? "even" : "odd"
                }`}
                style={{
                  color: publicTextColor ? publicTextColor : "#000000",
                  backgroundColor: publicColor
                    ? publicColor
                    : idx % 2 == 0
                    ? colors.brown
                    : colors.pink,
                }}
                key={idx}
                onClick={(e) => {
                  removeFromCurrentTags(tag, e);
                }}
              >
                <RxCross2 className={`${!publicColor && "close-icon"}`} />
                {tag.name[0].toUpperCase() + tag.name.substring(1)}
              </div>
            ))}
          </div>
          {!toggleInput ? (
            <IoIosArrowDown color={iconColor ? iconColor : colors.green} />
          ) : (
            <IoIosArrowUp color={iconColor ? iconColor : colors.green} />
          )}
        </div>
      )}

      {toggleInput && allergens.length > 0 && (
        <div className="list-container" ref={tagsContainer}>
          <div className="list-container-searchbar">
            <CiSearch className="search-bar-icon" />
            <input
              type="text"
              className="search-bar"
              placeholder="Chercher un allergène ..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e);
              }}
            />
            <RxCross2
              className="search-bar-icon"
              onClick={() => {
                setSearchTerm("");
                setFilteredAllergens(allergens);
              }}
            />
          </div>

          {filteredAllergens.map((tag: IAllergens, idx: number) => (
            <div
              key={idx}
              className="tag"
              style={{
                backgroundColor: currentTags.find(
                  (ctag, idx) => ctag.name === tag.name
                )
                  ? publicColor
                    ? `rgba(${hexToRgb(publicColor)}, 0.1)`
                    : "rgba(240, 178, 139, 0.1)"
                  : "#fff",
              }}
              onClick={() => addToCurrentTags(tag)}
            >
              {tag.name[0].toUpperCase() + tag.name.substring(1)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MutliSelect;
