import React, { useRef, useState } from "react";
import { MdMoreHoriz, MdOutlineModeEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { IRestaurantProfile } from "../../../../interface/IRestaurantProfile";
import "./AdminRestaurantTable.css";
import { formatDate } from "../../../../helpers/getDate";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdminUserToStore,
  selectAdminUsers,
} from "../../../../store/Admin/Users/AdminUsers";
import { IEditUser } from "../../../../interface/IUser";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { useNavigate } from "react-router-dom";
import { deleteRestaurantForAdmin } from "../../../../store/Admin/Restaurants/Requests";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { useNotification } from "../../../../hooks/useNotification";
import { addAdminRestaurantToStore } from "../../../../store/Admin/Restaurants/AdminRestaurants";
import usePagination from "../../../../hooks/usePagination";
import PaginationButtons from "../../Pagination/PaginationComponent";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";

interface RestaurantsProps {
  restaurants: IRestaurantProfile[];
}

const AdminRestaurantTable: React.FC<RestaurantsProps> = ({ restaurants }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const itemsPerPage = 5;
  const { currentPage, totalPages, next, prev } = usePagination(
    restaurants.length,
    itemsPerPage
  );
  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const [activePopupId, setActivePopupId] = useState<number | null>(null);
  const [activePreviewPopupId, setActivePreviewPopupId] = useState<
    number | null
  >(null);

  const popUpRef = useRef(null);
  const reviewPopUpRef = useRef(null);

  const users = useSelector(selectAdminUsers);

  const togglePopUp = (e, restaurantId: number) => {
    e.stopPropagation();
    setActivePopupId(activePopupId === restaurantId ? null : restaurantId);
  };

  const togglePreviewPopUp = (e, userId: number) => {
    e.stopPropagation();
    setActivePreviewPopupId(activePreviewPopupId === userId ? null : userId);
  };

  const handleEditRestaurant = (restaurant: IRestaurantProfile) => {
    setActivePopupId(null);
    dispatch(addAdminRestaurantToStore(restaurant));
    navigate("/admin/modifier-un-restaurant");
  };

  const handleDeleteRestaurant = (restaurant: IRestaurantProfile) => {
    handleAsyncResponse(
      dispatch,
      deleteRestaurantForAdmin(restaurant.id),
      {
        successTitle: "Restaurant supprimée",
        successMessage: `Restaurant ${restaurant.name} a été supprimé ! 🙌`,
        successDescription:
          "Vous avez supprimé le restaurant : " +
          restaurant.name +
          " de SafeEat",
        navigateTo: "/admin/tous-les-restaurants",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleEditUser = (user: IEditUser) => {
    dispatch(addAdminUserToStore(user));
    navigate("/admin/modifier-un-utilisateur");
  };

  useOutsideAlerter(popUpRef, () => setActivePopupId(null));
  useOutsideAlerter(reviewPopUpRef, () => setActivePreviewPopupId(null));

  const findUserById = (userId: number) => {
    return users.find((user) => user.id === Number(userId));
  };

  // Filter, sort, and then paginate the users
  const filteredAndSortedRestaurants = [...restaurants].sort(
    (a, b) => a.id - b.id
  );

  const paginatedRestaurants = filteredAndSortedRestaurants.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="admin-restaurant-table-container">
      <div className="admin-restaurant-table-content-container relative">
        <table>
          <thead>
            <tr>
              <th>Logo</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Téléphone</th>
              <th>Utilisateur</th>
              <th>Date d'inscription</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedRestaurants.map((restaurant) => {
              const user = findUserById(restaurant.userId);
              return (
                <tr key={restaurant.id}>
                  <td>
                    {restaurant.logo && restaurant.logo.breakpoints ? (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}uploads/${restaurant.logo.breakpoints.thumbnail.name}`}
                        alt={`${restaurant.name} logo`}
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : (
                      <div className="admin-restaurant-table-no-logo">
                        {restaurant.name[0].toUpperCase()}
                      </div>
                    )}
                  </td>
                  <td>{restaurant.name}</td>
                  <td>{restaurant.email}</td>
                  <td>{restaurant.tel || "N/A"}</td>
                  <td className="relative">
                    <button
                      className="admin-restaurant-table-preview-button"
                      onMouseDown={(e) =>
                        togglePreviewPopUp(e, restaurant.userId)
                      }
                    >
                      {user
                        ? user.firstName + " " + user.lastName
                        : "Aucun utilisateur"}
                    </button>
                    {activePreviewPopupId === restaurant.userId && (
                      <div
                        className="admin-restaurant-table-preview-popup-container"
                        ref={reviewPopUpRef}
                      >
                        {user ? (
                          <>
                            <div className="admin-restaurant-table-preview-text">
                              {user.firstName + " " + user.lastName}
                            </div>
                            <div className="admin-restaurant-table-preview-text">
                              <a href={`mailto:${user.email}`}>{user.email}</a>
                            </div>
                            <div className="admin-restaurant-table-preview-text">
                              <a href={`tel:${user.tel}`}>{user.tel}</a>
                            </div>
                            <button
                              className="admin-restaurant-table-preview-button"
                              onClick={() => handleEditUser(user)}
                            >
                              <MdOutlineModeEdit />
                              Modifier
                            </button>
                          </>
                        ) : (
                          <div className="admin-restaurant-table-preview-text">
                            Aucun utilisateur trouvé
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td>{formatDate(restaurant.createdAt)}</td>
                  <td className="relative">
                    <button
                      className="admin-restaurant-table-more-btn"
                      onMouseDown={(e) => togglePopUp(e, restaurant.id)}
                    >
                      <MdMoreHoriz size={22} />
                    </button>
                    {activePopupId === restaurant.id && (
                      <div
                        className="admin-restaurant-table-popup-container"
                        ref={popUpRef}
                      >
                        <div
                          className="admin-restaurant-table-popup-edit"
                          onClick={() => handleEditRestaurant(restaurant)}
                        >
                          <MdOutlineModeEdit className="admin-restaurant-table-popup-edit-icon" />
                          <span className="admin-restaurant-table-popup-edit-icon">
                            Modifier
                          </span>
                        </div>
                        <div className="admin-restaurants-table-separator-container">
                          <div className="admin-restaurants-table-separator-content"></div>
                        </div>
                        <div
                          className="admin-restaurant-table-popup-delete"
                          onClick={() => handleDeleteRestaurant(restaurant)}
                        >
                          <FaRegTrashAlt className="admin-restaurant-table-popup-delete-icon" />
                          <span className="admin-restaurant-table-popup-delete-icon">
                            Supprimer
                          </span>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="admin-restaurant-table-bottom">
        <div className="admin-restaurant-table-restaurants-number">
          Nombre total de restaurants sur la plateforme : {restaurants.length}
        </div>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={prev}
          onNext={next}
        />
      </div>
    </div>
  );
};

export default AdminRestaurantTable;
