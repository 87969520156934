import { FC, useEffect, useRef, useState } from "react";
import "./MenuCard.css";
import Switch from "react-switch";
import colors from "../../../../constants/colors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { AppDispatch } from "../../../../../interface/Types/AppDispatch";
import { FaEllipsisH, FaRegTrashAlt } from "react-icons/fa";
import useOutsideAlerter from "../../../../../hooks/useOutsideAlerter";
import { useNotification } from "../../../../../contexts/NotificationContext";
import { IMenu } from "../../../../../interface/Menus/IMenu";
import {
  IMenuTag,
  IRecipeInMenuCounts,
} from "../../../../../interface/Menus/IRecipeInMenuCounts";
import { formatDate } from "../../../../../helpers/getDate";
import { FaBookOpen } from "react-icons/fa";
import {
  deleteRestaurantMenu,
  toggleArchivedRestaurantMenu,
} from "../../../../../store/Menus/Requests";
import { addMenuToStore } from "../../../../../store/Menus/Menus";
import { useToast } from "../../../../../contexts/Toast/ToastProvider";
import handleAsyncResponse from "../../../../../helpers/handleAsyncResponse";

interface IMenuProps extends IMenu {
  idx: number;
}

const MenusCard: FC<IMenuProps> = ({
  id,
  name,
  recipes,
  price,
  note,
  availability_start_time,
  availability_end_time,
  is_child_menu,
  archived,
  updated_at,
  idx,
}) => {
  const { addToast } = useToast();
  const { addNotification } = useNotification();
  const [checked, setChecked] = useState<boolean>(archived);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [toggleMoreButton, setToggleMoreButton] = useState<boolean>(false);
  const menuCardMoreContainer = useRef<HTMLDivElement>(null);
  useOutsideAlerter(menuCardMoreContainer, () => setToggleMoreButton(false));

  const handleToggleMoreButton = (state) => {
    setToggleMoreButton(state);
  };

  const handleOutsideClick = (event) => {
    event.stopPropagation();
  };

  const handleDeleteMenu = () => {
    try {
      dispatch(deleteRestaurantMenu(id));

      navigate("/pro/mes-menus");
      addToast(
        "success",
        "Suppression du menu",
        `Votre menu ${name} a été supprimée ! 🙌`
      );
      addNotification({
        title: `${name} a été supprimée avec succès ! 🙌`,
        description:
          "Vous avez supprimé le menu : " + name + " de votre restaurant",
        originLink: "/pro/mes-menus",
        type: "menus",
      });
    } catch (error) {
      console.error("Erreur lors de la suppression du menu :", error);
      addToast(
        "error",
        "Erreur de suppression du menu",
        `Une erreur a été détecté lors de la suppression du menu. 😓`
      );
    }

    setToggleMoreButton(false);
  };

  const dispatchToStoreAndRedirect = () => {
    dispatch(
      addMenuToStore({
        id,
        name,
        recipes,
        price,
        note,
        availability_start_time,
        availability_end_time,
        is_child_menu,
        archived,
      })
    );
    navigate("/pro/modifier-un-menu");
  };

  const handleArchived = async () => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    handleAsyncResponse(
      dispatch,
      toggleArchivedRestaurantMenu({
        id,
        checked: newCheckedValue,
      }),
      {
        successTitle: `Archivage du menu modifié`,
        successMessage: `Votre menu ${name} ${
          newCheckedValue ? "n'est plus archivé" : "a été archivé"
        } ! 🙌`,
        successDescription: `Votre menu ${name} ${
          newCheckedValue ? "n'est plus archivé" : "a été archivé"
        } ! 🙌`,
        navigateTo: "/pro/mes-menus",
      },
      { addToast, addNotification, navigate }
    );
  };

  useEffect(() => {
    setChecked(archived);
  }, [recipes]);

  const getRecipeTags = (): IMenuTag[] => {
    const counts: IRecipeInMenuCounts = {
      entrée: recipes.starter?.length || 0,
      plat: recipes.dish?.length || 0,
      accompagnement: recipes.accompaniment?.length || 0,
      dessert: recipes.dessert?.length || 0,
    };

    return Object.entries(counts)
      .map(([key, count]) => ({
        tag: `${count} ${key}${count > 1 ? "s" : ""}`,
        count: count,
      }))
      .filter((item) => item.count > 0);
  };

  const recipeTags = getRecipeTags();
  const isMobile = useMediaQuery({ maxWidth: 580 });

  const colorClasses = ["green", "brown", "pink"].map((color) => ({
    light: `menu-card-container-l${color}`,
    normal: `menu-card-container-${color}`,
    color: `menu-card-container-color-${color}`,
    buttonHover: `menu-card-edit-btn-d-${color}`,
  }));

  const currentColor = colorClasses[idx % 3];

  const switchColor = [colors.green, colors.brown, colors.pink];

  return (
    <div className="relative menu-card-border">
      <div className={`menu-card-container ${currentColor.light}`}>
        <div className="menu-card-date-container">
          <span className={`menu-card-date ${currentColor.color}`}>
            {formatDate(updated_at)}
          </span>
          <div
            className="menu-card-more"
            onClick={() => setToggleMoreButton(!toggleMoreButton)}
          >
            <FaEllipsisH
              size={18}
              className={`menu-card-more-icon ${currentColor.color}`}
            />
          </div>
        </div>

        <div className="menu-card-middle">
          <div className="menu-card-name-container">
            <h3 className="menu-card-sub-info">Menu n°{id}</h3>
            <p className="menu-card-name">{name}</p>
          </div>
          <div className={`menu-card-image-container ${currentColor.normal}`}>
            <FaBookOpen size={35} />
          </div>
        </div>

        <div className="menu-card-tags-container">
          {recipeTags.map((tag, index) => (
            <span key={index} className={`menu-card-tag ${currentColor.color}`}>
              {tag.tag}
            </span>
          ))}
        </div>
      </div>

      <div className="menu-card-bottom">
        <div>
          <p className="menu-card-price">{price} €</p>
          <p className="menu-card-location">Menu</p>
        </div>
        <div className="menu-card-buttons-container">
          <Switch
            onChange={() => handleArchived()}
            onColor={switchColor[idx % 3]}
            checked={checked}
            className="react-switch"
          />
          <button
            className={`recipe-card-edit-btn ${currentColor.normal} ${currentColor.buttonHover}`}
            onClick={dispatchToStoreAndRedirect}
          >
            Modifier
          </button>
        </div>
      </div>
      {toggleMoreButton && (
        <div
          className={`menu-card-more-container`}
          ref={menuCardMoreContainer}
          onClick={handleOutsideClick}
        >
          <div className="menu-card-more-delete" onClick={handleDeleteMenu}>
            <FaRegTrashAlt className="menu-card-more-delete-icon" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MenusCard;
