import { FC } from "react";
import "./PublicMenusList.css";
import { IPublicMenusList } from "../../../../../interface/Public/IPublic";
import PublicMenuCard from "../PublicMenuCard/PublicMenuCard";

const PublicMenusList: FC<IPublicMenusList> = ({
  filteredMenus,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <div className="public-menus-list-container">
      {filteredMenus.length !== 0 ? (
        filteredMenus.map((menu) => (
          <PublicMenuCard
            key={menu.id}
            id={menu.id}
            name={menu.name}
            price={menu.price}
            is_child_menu={menu.is_child_menu}
            availability_start_time={menu.availability_start_time}
            availability_end_time={menu.availability_end_time}
            recipes={menu.recipes}
            archived={menu.archived}
            updated_at={menu.updated_at}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        ))
      ) : (
        <div>Aucun menu n'est disponible pour ce restaurant.</div>
      )}
    </div>
  );
};

export default PublicMenusList;
