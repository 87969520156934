import AdminRestaurants from "../../components/Admin/Restaurants/AdminRestaurants/AdminRestaurants";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminRestaurantsPage = () => {
  return (
    <LayoutAdmin subHeading="Tous les restaurants de SafeEat">
      <AdminRestaurants />
    </LayoutAdmin>
  );
};

export default AdminRestaurantsPage;
