import { AxiosError } from "axios";
import { ErrorResponse } from "../interface/IErrorResponse";

const handleAxiosError = (error: AxiosError<ErrorResponse>) => {
  if (error.response && error.response.status === 422) {
    const validationErrors = error.response.data.errors;
  } else {
    console.error("Erreur lors de la requête :", error);
  }
};

export default handleAxiosError;
