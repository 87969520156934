import { capitalize } from "./capitalize";
import { isEmpty } from "./isEmpty";

/**
 * Executes a Redux async action and handles success and error with toast notifications.
 *
 */

interface OptionalTexts {
  optionalTitle?: string;
  optionalText?: string;
}

export const handleAsyncGetResponse = (
  dispatch,
  asyncAction,
  addToast,
  type,
  callback = (payload) => {},
  optionalTexts: OptionalTexts = {}
) => {
  const internalErrorMessage =
    "Ereur interne, veuillez nous contacter si le problème persiste 😓";

  const checkType = (a: string, b: string) => {
    if (type === "restaurant") {
      return a;
    } else if (type === "user") {
      return a;
    } else if (type === "menu") {
      return a;
    } else if (type === "utilisateur") {
      return a;
    } else return b;
  };

  try {
    dispatch(asyncAction)
      .unwrap()
      .then((payload) => {
        if (type !== "restaurant" && payload.length === 0) {
          if (!isEmpty(optionalTexts)) {
            addToast(
              "info",
              `${optionalTexts.optionalTitle || ""}`,
              `${optionalTexts.optionalText || ""}`
            );
          } else {
            addToast(
              "info",
              `${checkType("Aucun", "Aucune")} ${type}`,
              `${checkType(
                "Aucun",
                "Aucune"
              )} ${type} n'existe pour ce restaurant 😓`
            );
          }
        } else {
          addToast(
            "success",
            `${capitalize(type)} ${checkType("récupéré", "récupérés")}`,
            `Les données ${checkType(
              "du",
              "des"
            )} ${type} ont été récupérées ! 🙌`
          );
          callback(payload);
        }
      })
      .catch((error) => {
        console.error(error);
        addToast(
          "error",
          `${capitalize(type)} ${checkType("introuvable", "introuvables")} !`,
          `${error.message} 😓`
        );
      });
  } catch (error) {
    console.error(error);
    addToast("error", "Erreur interne", internalErrorMessage);
  }
};
