import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserDocuments, UserProfiles } from "../../../api/api";
import { IEditUser, IUser } from "../../../interface/IUser";
import { ApiErrorResponse } from "../../../interface/RejectValue";
import { IDocument } from "../../../interface/Documents/IDocument";

export const addNewUser = createAsyncThunk<
  IUser, // Specify the expected type of the data returned by the API
  FormData, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>("admin/addNewUser", async (userData: FormData, { rejectWithValue }) => {
  try {
    const response = await UserProfiles.createUser(userData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data.errors);
  }
});

export const editUser = createAsyncThunk<
  IEditUser, // Specify the expected type of the data returned by the API
  FormData, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>("admin/editUser", async (userData: FormData, { rejectWithValue }) => {
  try {
    const response = await UserProfiles.updateUserForAdmin(userData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data.errors);
  }
});

export const deleteUserForAdmin = createAsyncThunk(
  "admin/deleteUserForAdmin",
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await UserProfiles.deleteUserForAdmin(userId);
      return { userId, ...response }; // Renvoie les données de réponse pour une utilisation dans le fulfilled reducer
    } catch (error: any) {
      return rejectWithValue(error.response.data); // Gère les erreurs en renvoyant les données d'erreur
    }
  }
);

export const fetchAllUsersForAdmin = createAsyncThunk(
  "admin/fetchAllUsersForAdmin",
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserProfiles.getAllUsers();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const toggleIsActivatedForAdmin = createAsyncThunk<
  { isActivated: boolean; id: number }, // Type de la réponse en cas de succès
  { id: number; isActivated: boolean }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "admin/toggleIsActivateForAdmin",
  async ({ id, isActivated }, { rejectWithValue }) => {
    try {
      const response = await UserProfiles.updateToggleIsActivatedForAdmin(
        { isActivated: isActivated },
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
