import { useSelector } from "react-redux";
import { selectUser } from "../store/User";
import { useState } from "react";
import { IEditUser } from "../interface/IUser";

export const useUser = () => {
  const imageUrl = process.env.REACT_APP_BASE_URL + "uploads/";
  const user = useSelector(selectUser);
  const [editUser, setEditUser] = useState<IEditUser>({
    avatar: user.avatar || "",
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    tel: user.tel,
  });

  const getFullName = (): string => {
    return `${user.firstName} ${user.lastName}`;
  };

  const getProfilePicture = (): string => {
    if (user.avatar && user.avatar.breakpoints) {
      return imageUrl + user.avatar.breakpoints.thumbnail.name;
    }
  };

  const updateUser = () => {};

  return {
    user,
    getFullName,
    editUser,
    setEditUser,
    updateUser,
    getProfilePicture,
  };
};
