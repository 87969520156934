import { FC } from "react";
import "../css-pages/dashboard.css";
import ProDashboard from "../../components/Pro/Dashboard/ProDashboard";
import LayoutPro from "../../layout/LayoutPro";

const SignUp: FC = () => {
  return (
    <LayoutPro subHeading="Bienvenue sur votre tableau de bord !">
      <ProDashboard />
    </LayoutPro>
  );
};

export default SignUp;
