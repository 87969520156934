type PathType = { path: string; title: string }[];

const navigationDrawerTop: PathType = [
  { path: "/admin/tous-les-utilisateurs", title: "Les utilisateurs" },
  { path: "/admin/tous-les-restaurants", title: "Les restaurants" },
  { path: "/admin/tous-les-documents", title: "Les documents" },
  { path: "/admin/toutes-les-recettes", title: "Les recettes" },
  { path: "/admin/tous-les-menus", title: "Les menus" },
  { path: "/admin/statistiques", title: "Les statistiques" },
];

const navigationDrawerBottom: PathType = [
  { path: "/admin/creer-un-utilisateur", title: "Créer un utilisateur" },
  { path: "/admin/ajouter-un-restaurant", title: "Ajouter un restaurant" },
  {
    path: "/admin/ajouter-plusieurs-recettes",
    title: "Ajouter plusieurs recettes",
  },
  {
    path: "/admin/ajouter-une-recette",
    title: "Ajouter une recette",
  },
  {
    path: "/admin/ajouter-un-menu",
    title: "Ajouter un menu",
  },
];

const pages = {
  primary: navigationDrawerTop,
  secondary: navigationDrawerBottom,
};

export default pages;
