import { FC, useEffect } from "react";
import "./Header.css";
import { IPublicHeader } from "../../../../interface/Public/IPublic";
import { GrRestaurant } from "react-icons/gr";
const Header: FC<IPublicHeader> = ({
  name,
  logoUrl,
  address,
  primaryColor,
}) => {
  useEffect(() => {
    console.log(logoUrl);
  }, []);
  return (
    <div className="public-header-container">
      <div className="public-header-restaurant-container">
        <div className="public-restaurant-logo">
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={`Logo de l'entreprise partenaire ${name}`}
            />
          ) : (
            <GrRestaurant color={primaryColor} />
          )}
        </div>
        <div className="public-restaurant-informations">
          <h1>{name}</h1>
          <div className="public-restaurant-address">{address}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
