import { FC } from "react";
import "./../css-pages/Profile.css";
import LayoutPro from "../../layout/LayoutPro";
import EditProfileComponent from "../../components/Pro/Profile/Edit/EditProfileComponent";

const Profile: FC = () => {
  return (
    <LayoutPro subHeading="Mettez à jour votre profil !">
      <EditProfileComponent />
    </LayoutPro>
  );
};

export default Profile;
