import { createAsyncThunk } from "@reduxjs/toolkit";
import { restaurantProfile } from "../../../api/api";
import { IRestaurantProfile } from "../../../interface/IRestaurantProfile";
import { ApiErrorResponse } from "../../../interface/RejectValue";

export const fetchAllRestaurantsForAdmin = createAsyncThunk(
  "restaurants/fetchAllRestaurantsForAdmin",
  async (_, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.getAllRestaurantsForAdmin();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRestaurantForAdmin = createAsyncThunk(
  "restaurants/fetchRestaurantForAdmin",
  async (restaurantId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.getRestaurantForAdmin(
        restaurantId
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addRestaurantForAdmin = createAsyncThunk<
  IRestaurantProfile, // Specify the expected type of the data returned by the API
  FormData, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "admin/addRestaurantForAdmin",
  async (restaurantData: FormData, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.createRestaurantForAdmin(
        restaurantData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const editRestaurantForAdmin = createAsyncThunk<
  IRestaurantProfile, // Type de la réponse en cas de succès
  { id: number; restaurantData: FormData }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "admin/editRestaurant",
  async ({ id, restaurantData }, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.updateRestaurantForAdmin(
        restaurantData,
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteRestaurantForAdmin = createAsyncThunk(
  "admin/deleteRestaurantForAdmin",
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.deleteRestaurantForAdmin(userId);
      return { userId, ...response }; // Renvoie les données de réponse pour une utilisation dans le fulfilled reducer
    } catch (error: any) {
      return rejectWithValue(error.response.data); // Gère les erreurs en renvoyant les données d'erreur
    }
  }
);
