import AdminCreateRecipes from "../../components/Admin/Recipes/Add/AdminCreateRecipes";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminCreateRecipesPage = () => {
  return (
    <LayoutAdmin subHeading="Ajouter massivement des recettes au restaurant">
      <AdminCreateRecipes />
    </LayoutAdmin>
  );
};

export default AdminCreateRecipesPage;
