import { IoAddCircle } from "react-icons/io5";
import { isEmpty } from "../../../helpers/isEmpty";
import useRestaurant from "../../../hooks/useRestaurant";
import colors from "../../constants/colors";
import "./RestaurantComponent.css";
import { useNavigate } from "react-router-dom";
import EditRestaurantComponent from "./Edit/EditRestaurantComponent";

const RestaurantComponent = () => {
  const { restaurant } = useRestaurant();

  const navigate = useNavigate();

  if (isEmpty(restaurant)) {
    return (
      <div
        className="restaurant-empty-container"
        onClick={() => navigate("/pro/ajouter-un-restaurant")}
      >
        <div className="restaurant-empty-add-container">
          <IoAddCircle
            className="restaurant-add-icon"
            size={129}
            color={colors.green}
          />
          <div className="restaurant-add-text">Ajouter votre restaurant</div>
        </div>
      </div>
    );
  } else {
    return <EditRestaurantComponent />;
  }
};

export default RestaurantComponent;
