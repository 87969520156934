import AdminAddRestaurant from "../../components/Admin/Restaurants/AddRestaurant/AdminAddRestaurant";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminAddRestaurantPage = () => {
  return (
    <LayoutAdmin subHeading="Ajouter un restaurant sur la plateforme SafeEat">
      <AdminAddRestaurant />
    </LayoutAdmin>
  );
};

export default AdminAddRestaurantPage;
