import "./NavbarAdmin.css";
import "./NavbarAdmin.css";
import { CSSProperties, FC, useEffect, useState } from "react";
import path from "./path";
import { IoPersonCircleSharp, IoPersonCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { resetStore } from "../../../helpers/resetStore";
import { useUser } from "../../../hooks/useUser";
import { NavbarProps } from "../../../interface/NavbarProps";
import { AppDispatch } from "../../../interface/Types/AppDispatch";
import NotificationBell from "../../Notification/NotificationBell";
import colors from "../../constants/colors";

const NavbarAdmin: FC<{
  subHeading: string;
  showNavigationDrawer: boolean;
}> = ({ subHeading, showNavigationDrawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { primary } = path;
  let top = primary;

  const [toggleSettings, setToggleSettings] = useState(false);
  const { getFullName } = useUser();

  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isTablet = useMediaQuery({ maxWidth: 1075 });

  useEffect(() => {
    handlePosition();
  }, [isMobile, isTablet]);

  const handleLogout = (path: { path: string }) => {
    if (path.path === "/connexion") {
      resetStore();
      navigate("/connexion");
    } else {
      navigate(path.path);
    }
  };

  const handlePosition = () => {
    if (!showNavigationDrawer) {
      if (!isTablet && !isMobile) {
        const styles: CSSProperties = {
          left: "0rem",
          width: "100vw",
        };
        return styles;
      } else if (!isMobile) {
        const styles: CSSProperties = {
          left: "0rem",
          width: "100vw",
        };
        return styles;
      } else {
        const styles: CSSProperties = {
          left: "0rem",
          width: "100vw",
        };
        return styles;
      }
    } else {
    }
  };

  return (
    <nav className="_navbar" style={handlePosition()}>
      <div className="hello-container">
        <div className="navbar-profile-name">Bonjour, {getFullName()}👋</div>
        <div className="subheading">{subHeading}</div>
      </div>
      <div className="profile-settings">
        <NotificationBell />
        {toggleSettings ? (
          <IoPersonCircleSharp
            className="drawer-item-icon"
            size={35}
            style={{ color: colors.brown }}
            onClick={() => setToggleSettings(!toggleSettings)}
          />
        ) : (
          <IoPersonCircleOutline
            className="drawer-item-icon"
            size={35}
            style={{ color: colors.brown }}
            onClick={() => setToggleSettings(!toggleSettings)}
          />
        )}
      </div>
      <div
        className={
          toggleSettings
            ? "navbar-settings"
            : "navbar-settings hide-navbar-settings"
        }
      >
        {top.map((path: NavbarProps, idx: number) => {
          return (
            <p
              key={idx}
              className="navbar-settings-item"
              onClick={() => handleLogout(path)}
            >
              {path.title}
            </p>
          );
        })}
      </div>
    </nav>
  );
};

export default NavbarAdmin;
