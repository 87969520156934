import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericSelectWithoutRHF from "../../../Reusables/GenericSelectWithoutRHF";
import {
  addRestaurantIdToStore,
  selectAdminRestaurantId,
  selectAdminRestaurants,
} from "../../../../store/Admin/Restaurants/AdminRestaurants";
import SearchComponent from "../../../../layout/MenusAndRecipesLayout/Search/SearchComponent";
import AdminMenusTable from "./AdminMenusTable";
import { selectAdminMenus } from "../../../../store/Admin/Menus/Menus";
import { fetchAllMenusForAdmin } from "../../../../store/Admin/Menus/Requests";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";

const AdminMenus = () => {
  const dispatch = useDispatch<AppDispatch>();
  const adminRestaurants = useSelector(selectAdminRestaurants);
  const menus = useSelector(selectAdminMenus);
  const adminRestaurantId = useSelector(selectAdminRestaurantId);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (adminRestaurantId) {
        setIsLoading(true);
        dispatch(fetchAllMenusForAdmin(adminRestaurantId)).then(() => {
          setIsLoading(false);
        });
      }
    }
  }, [adminRestaurantId, dispatch]);

  useEffect(() => {
    setSelectedRestaurant(
      adminRestaurants.find((r) => r.id === adminRestaurantId) || null
    );
    setFilteredMenus(menus);
  }, [adminRestaurantId, adminRestaurants, menus]);

  const handleRestaurantChange = async (selectedOption) => {
    if (selectedOption.id !== adminRestaurantId) {
      setIsLoading(true);
      await dispatch(fetchAllMenusForAdmin(selectedOption.id));
      setSelectedRestaurant(selectedOption);
      await dispatch(addRestaurantIdToStore(selectedOption.id));
      setIsLoading(false);
    }
  };

  const restaurantOptions = adminRestaurants.map((restaurant) => ({
    name: restaurant.name,
    id: restaurant.id,
  }));

  return (
    <div className="admin-restaurants-container">
      <h2 className="admin-restaurants-title">Tous les menus du restaurant</h2>
      <div className="admin-restaurants-content-container">
        <GenericSelectWithoutRHF
          options={restaurantOptions}
          category="restaurant"
          onChange={handleRestaurantChange}
          returnObject
          searchBar
          value={selectedRestaurant ? selectedRestaurant.id : ""}
        />
        <SearchComponent
          items={filteredMenus}
          setFilteredItems={setFilteredMenus}
          placeholder="Rechercher un menu ..."
        />
        {isLoading ? (
          <div>Chargement des menus...</div>
        ) : selectedRestaurant ? (
          filteredMenus.length > 0 ? (
            <>
              <AdminMenusTable
                menus={filteredMenus}
                restaurant={selectedRestaurant}
              />
            </>
          ) : (
            <div>
              Il n'y a aucun menu disponible pour ce restaurant pour le moment.
            </div>
          )
        ) : (
          <div>
            Pour voir les menus d'un restaurant, veuillez choisir un restaurant
            dans le sélecteur.
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminMenus;
