export const emailRules = {
  required: "L'email est requis",
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "L'email doit être une adresse email valide",
  },
};

export const passwordRules = {
  required: "Le mot de passe est requis",
  minLength: {
    value: 8,
    message: "Le mot de passe doit contenir au moins 8 caractères",
  },
  maxLength: {
    value: 180,
    message: "Le mot de passe ne peut pas dépasser 180 caractères",
  },
};
