import {
  ChangeEvent,
  FC,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Control,
  Controller,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";
import { FaExclamationTriangle } from "react-icons/fa";
import "./AllergensSelect.css";
import colors from "../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import { selectAllAllergens } from "../../../store/Restaurant";
import { capitalize } from "../../../helpers/capitalize";
import { IAllergens } from "../../../interface/IAllergens";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { fetchAllAllergens } from "../../../store/Restaurant/Recipes/Requests";
import { AppDispatch } from "../../../interface/Types/AppDispatch";

interface IAllergensSelectProps {
  name: string;
  rules: any;
  defaultValue?: IAllergens[] | null;
}

const AllergensSelect: FC<IAllergensSelectProps> = ({
  name,
  rules,
  defaultValue,
}) => {
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch<AppDispatch>();
  const searchRef = useRef(null);

  const allergens = useSelector(selectAllAllergens);
  const [isOpen, setIsOpen] = useState(false);
  const [currentTags, setCurrentTags] = useState<IAllergens[]>(
    defaultValue || []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAllergens, setFilteredAllergens] = useState(allergens);
  const containerRef = useRef(null);

  useOutsideAlerter(containerRef, () => setIsOpen(false));

  useEffect(() => {
    dispatch(fetchAllAllergens());
    if (allergens.length !== 0) {
      setFilteredAllergens(allergens);
    }
  }, [dispatch]);

  useEffect(() => {
    setValue(name, defaultValue || []);
  }, [setValue, name, defaultValue]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setTimeout(() => {
        if (containerRef.current) {
          const scrollHeight = containerRef.current.scrollHeight;
          window.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  };

  const addAllergensToCurrentTags = (allergen: IAllergens, field) => {
    if (!currentTags.some((tag) => tag.name === allergen.name)) {
      const newTags = [...currentTags, allergen];
      setCurrentTags(newTags);
      field.onChange(newTags); // Update form value
      setSearchTerm("");
      searchRef.current.focus();
    }
  };

  // Update removeTag similarly
  const removeAllergensFromCurrentTags = (allergen: IAllergens, e, field) => {
    e.stopPropagation();
    const newTags = currentTags.filter((tag) => tag.name !== allergen.name);
    setCurrentTags(newTags);
    field.onChange(newTags); // Update form value
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    const searchTerm = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    const filtered = allergens.filter((tag) =>
      tag.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(searchTerm)
    );
    setFilteredAllergens(filtered);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div className="input-container" ref={containerRef}>
          <div
            className="allergens-select-container pointer"
            onClick={handleSetIsOpen}
          >
            <div className="allergens-select-names-container">
              <FaExclamationTriangle className="allergens-select-icon" />
              <div className="tags-or-placeholder">
                {currentTags.length !== 0 ? (
                  currentTags.map((tag, idx) => (
                    <div
                      key={idx}
                      className="allergens-current-tag"
                      onClick={(e) =>
                        removeAllergensFromCurrentTags(tag, e, field)
                      }
                    >
                      <RxCross2 className="close-icon" />
                      {capitalize(tag.name)}
                    </div>
                  ))
                ) : (
                  <div className="allergens-select-placeholder">
                    Sélectionner vos allergènes
                  </div>
                )}
              </div>
            </div>
            {isOpen ? (
              <IoIosArrowUp color={colors.green} />
            ) : (
              <IoIosArrowDown color={colors.green} />
            )}
          </div>
          {isOpen && (
            <div className="allergens-select-search-container">
              <div className="allergens-select-search-left">
                <CiSearch
                  style={{ marginInline: "0.75rem" }}
                  color={colors.green}
                />
                <input
                  ref={searchRef}
                  type="text"
                  className="search-bar"
                  placeholder="Chercher un allergène ..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <RxCross2
                className="pointer"
                color={colors.green}
                onClick={() => setSearchTerm("")}
              />
            </div>
          )}
          {searchTerm && isOpen && (
            <ul className="allergens-select-list">
              {filteredAllergens.map((tag, index) => (
                <li
                  key={index}
                  className={`allergens-select-item ${
                    currentTags.some((t) => t.name === tag.name)
                      ? "allergens-select-item-red"
                      : ""
                  }`}
                  onClick={() => {
                    if (!currentTags.some((t) => t.name === tag.name)) {
                      addAllergensToCurrentTags(tag, field);
                    }
                  }}
                >
                  {capitalize(tag.name)}
                </li>
              ))}
            </ul>
          )}
          {error && <p className="is-mandatory error-msg">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default AllergensSelect;
