import React, { FC, useEffect, useRef, useState } from "react";
import "./FilterByCategory.css";
import { useSelector } from "react-redux";
import {
  selectPublicMenus,
  selectPublicRestaurantRecipes,
} from "../../../../../store/PublicRestaurant/PublicRestaurant";
import { getTextColor } from "../../../../../helpers/color";
import { IPublicCategoryFilter } from "../../../../../interface/Public/IPublic";

const FilterByCategory: FC<IPublicCategoryFilter> = ({
  primaryColor,
  secondaryColor,
  category,
  setCategory,
}) => {
  const [activeTab, setActiveTab] = useState<string>("menu");
  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const tabs = [
    { id: "menu", label: "Menus" },
    { id: "tout", label: "Tout" },
    { id: "entrée", label: "Entrées" },
    { id: "plat", label: "Plats" },
    { id: "accompagnement", label: "Accompagnements" },
    { id: "dessert", label: "Desserts" },
  ];

  useEffect(() => {
    const categoryRef = categoryRefs.current[activeTab];
    if (categoryRef) {
      categoryRef.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeTab]);

  const handleClick = (tabId: string) => {
    setActiveTab(tabId);
    setCategory(tabId);
  };

  return (
    <div
      className="public-category-filter-container"
      style={{ display: "flex", overflowX: "auto" }}
    >
      {tabs.map((tab) => (
        <div
          key={tab.id}
          ref={(el) => (categoryRefs.current[tab.id] = el)}
          className="public-category-container"
          style={{
            backgroundColor: activeTab === tab.id ? primaryColor : "white",
            color: activeTab === tab.id ? getTextColor(primaryColor) : "black",
          }}
          onClick={() => handleClick(tab.id)}
        >
          <span className="public-category">{tab.label}</span>
          <div
            className="public-category-active-dash"
            style={{
              backgroundColor:
                activeTab === tab.id ? primaryColor : "transparent",
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default FilterByCategory;
