import AdminCreateRecipe from "../../components/Admin/Recipe/AdminCreateRecipe";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminCreateRecipePage = () => {
  return (
    <LayoutAdmin subHeading="Ajouter une recette au restaurant">
      <AdminCreateRecipe />
    </LayoutAdmin>
  );
};

export default AdminCreateRecipePage;
