import React, { useState } from "react";
import LogoGreen from "./../../../../assets/img/Logo-Green.svg"; // Path to the empty logo SVG
import LogoFilledDarkerGreen from "./../../../../assets/img/Logo-Filled-DarkerGreen.svg"; // Path to the filled logo SVG
import "./RatingStars.css";

const RatingStars = ({ totalStars = 5, onChange }) => {
  const [hoverRating, setHoverRating] = useState(0);
  const [selectedRating, setSelectedRating] = useState(0);

  const handleMouseOver = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    setSelectedRating(index);
    if (onChange) {
      onChange(index);
    }
  };

  const determineIcon = (index) => {
    if (hoverRating >= index) {
      return (
        <img src={LogoFilledDarkerGreen} alt="Filled Logo" width={"40px"} />
      );
    } else if (selectedRating >= index) {
      return (
        <img src={LogoFilledDarkerGreen} alt="Filled Logo" width={"40px"} />
      );
    } else {
      return <img src={LogoGreen} alt="Empty Logo" width={"40px"} />;
    }
  };

  return (
    <>
      <div className="form-field-title">Votre note sur 5</div>
      <div className="nuts-rates">
        {Array.from({ length: totalStars }, (_, index) => (
          <div
            key={index}
            onMouseOver={() => handleMouseOver(index + 1)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(index + 1)}
            style={{ cursor: "pointer", marginRight: "5px" }}
          >
            {determineIcon(index + 1)}
          </div>
        ))}
      </div>
    </>
  );
};

export default RatingStars;
