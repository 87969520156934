import { createAsyncThunk } from "@reduxjs/toolkit";
import { restaurantMenu } from "../../../api/api";
import { IMenu, ICreateMenu } from "../../../interface/Menus/IMenu";
import { ApiErrorResponse } from "../../../interface/RejectValue";

interface MenuResponse {
  id: number;
  response: any;
}

interface RejectValue {
  error: string;
}

export const createMenuForAdmin = createAsyncThunk<
  IMenu, // Specify the expected type of the data returned by the API
  { restaurantProfileId: number; menuData: ICreateMenu }, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "admin/createMenuForAdmin",
  async ({ restaurantProfileId, menuData }, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.createMenuForAdmin(
        restaurantProfileId,
        menuData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const updateMenuForAdmin = createAsyncThunk<
  IMenu, // Specify the expected type of the data returned by the API
  { menuId: number; menuData: ICreateMenu }, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "admin/updateMenuForAdmin",
  async ({ menuId, menuData }, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.updateMenuForAdmin(
        menuData,
        menuId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const toggleArchivedMenuForAdmin = createAsyncThunk<
  { archived: boolean; id: number }, // Type de la réponse en cas de succès
  { id: number; checked: boolean }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "admin/toggleArchivedMenuForAdmin",
  async ({ id, checked }, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.updateToggleMenuForAdmin(
        { archived: checked },
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteMenuForAdmin = createAsyncThunk<
  MenuResponse,
  number,
  {
    rejectValue: RejectValue;
  }
>("admin/deleteMenuForAdmin", async (id, { rejectWithValue }) => {
  try {
    const response = await restaurantMenu.deleteMenuForAdmin(id);
    // Properly structure your response or adapt this line based on your actual API response structure
    return { id, response: response };
  } catch (error: any) {
    // Use rejectWithValue to handle errors properly
    return rejectWithValue({ error: error.message || "Unknown error" });
  }
});

export const fetchAllMenusForAdmin = createAsyncThunk(
  "admin/fetchAllMenusForAdmin",
  async (restaurantProfileId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.getAllMenusForAdmin(
        restaurantProfileId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
