import "./MenusByDateChart.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { useNavigate } from "react-router-dom";
import { IoArrowForwardCircle } from "react-icons/io5";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend
);

const MenusByDateChart = ({ menus }) => {
  const navigate = useNavigate();

  if (!menus || !Array.isArray(menus)) {
    return <div>Aucune menu disponible.</div>;
  }

  let minDate = new Date(
    Math.min(...menus.map((r) => new Date(r.created_at).getTime()))
  );
  menus.forEach((m) => {
    if (m.modifications) {
      m.modifications.forEach((m) => {
        const modTime = new Date(m.modified_at).getTime();
        if (modTime < minDate.getTime()) minDate = new Date(modTime);
      });
    }
  });

  let maxDate = new Date(
    Math.max(...menus.map((m) => new Date(m.created_at).getTime()))
  );
  menus.forEach((m) => {
    if (m.modifications) {
      m.modifications.forEach((m) => {
        const modTime = new Date(m.modified_at).getTime();
        if (modTime > maxDate.getTime()) maxDate = new Date(modTime);
      });
    }
  });

  const datasets = menus.map((menu, index) => {
    const dataPoints = [
      {
        x: new Date(menu.created_at),
        y: index + 1, // Each recipe gets a unique y-value
      },
    ];

    if (menu.modifications && Array.isArray(menu.modifications)) {
      menu.modifications.forEach((mod) => {
        dataPoints.push({
          x: new Date(mod.modified_at),
          y: index + 1, // Keep modifications at the same y-level
        });
      });
    }

    return {
      label: `${menu.name}`,
      data: dataPoints,
      borderColor: `hsl(${(index * 360) / menus.length}, 100%, 70%)`,
      backgroundColor: `hsl(${(index * 360) / menus.length}, 100%, 70%)`,
      fill: false,
      showLine: true,
      pointRadius: 5,
    };
  });

  const options = {
    scales: {
      x: {
        type: "time",
        min: new Date(minDate.getTime() - 86400000),
        max: new Date(maxDate.getTime() + 86400000),
        time: {
          unit: "day",
          tooltipFormat: "PP",
          displayFormats: {
            day: "dd/MM/yyyy",
          },
        },
        title: {
          display: false,
          text: "Historique de modification des menus",
        },
      },
      y: {
        display: true,
        title: {
          display: false,
          text: "Menus Index",
        },
        ticks: {
          stepSize: 1,
          beginAtZero: false,
          callback: function (value, index, values) {
            return `Menu n°${value}`;
          },
          count: menus.length,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Historique de modification des menus",
      },
    },
    maintainAspectRatio: true,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    responsive: true,
  };

  return (
    <div className="menus-by-date-chart-container">
      <div className="menus-by-date-header-container">
        <div className="menus-by-date-title">
          Historique de modification des menus
        </div>
        <div
          className="menus-by-date-goto-view-container"
          onClick={() => navigate("/pro/mes-menus")}
        >
          <IoArrowForwardCircle className="menus-by-date-view-icon" size={35} />
        </div>
      </div>
      <Line data={{ datasets }} options={options} />
    </div>
  );
};

export default MenusByDateChart;
