import React, { useRef, useState } from "react";
import { MdMoreHoriz, MdOutlineModeEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { IRestaurantProfile } from "../../../../interface/IRestaurantProfile";
import { formatDate } from "../../../../helpers/getDate";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { useNotification } from "../../../../hooks/useNotification";
import { addAdminRestaurantToStore } from "../../../../store/Admin/Restaurants/AdminRestaurants";
import { IRestaurantRecipe } from "../../../../interface/IRestaurantRecipe";
import { deleteRecipeForAdmin } from "../../../../store/Admin/Recipes/Requests";
import { addRestaurantToStore } from "../../../../store/Restaurant";
import usePagination from "../../../../hooks/usePagination";
import PaginationButtons from "../../Pagination/PaginationComponent";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import { addRecipeToStore } from "../../../../store/Admin/Recipes/Recipes";

interface RecipesProps {
  recipes: IRestaurantRecipe[];
  restaurant: IRestaurantProfile;
}

const AdminRecipesTable: React.FC<RecipesProps> = ({ recipes, restaurant }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const itemsPerPage = 5;
  const { currentPage, totalPages, next, prev } = usePagination(
    recipes.length,
    itemsPerPage
  );
  const { addToast, clearToasts } = useToast();
  const { addNotification } = useNotification();

  const [activePopupId, setActivePopupId] = useState<number | null>(null);
  const [activePreviewPopupId, setActivePreviewPopupId] = useState<
    number | null
  >(null);

  const popUpRef = useRef(null);
  const reviewPopUpRef = useRef(null);

  const togglePopUp = (e, recipeId: number) => {
    e.stopPropagation();
    setActivePopupId(activePopupId === recipeId ? null : recipeId);
  };

  const togglePreviewPopUp = (e, recipeId: number) => {
    e.stopPropagation();
    setActivePreviewPopupId(
      activePreviewPopupId === recipeId ? null : recipeId
    );
  };

  const handleEditRestaurant = (restaurant: IRestaurantProfile) => {
    setActivePopupId(null);
    dispatch(addAdminRestaurantToStore(restaurant));
    navigate("/admin/modifier-un-restaurant");
  };

  const handleDeleteRecipe = (recipe: IRestaurantRecipe) => {
    clearToasts();
    handleAsyncResponse(
      dispatch,
      deleteRecipeForAdmin(recipe.id),
      {
        successTitle: "Recette supprimée",
        successMessage: `Recette ${recipe.name} a été supprimée ! 🙌`,
        successDescription:
          "Vous avez supprimé la recette : " +
          recipe.name +
          " de votre restaurant " +
          restaurant.name,
        navigateTo: "/admin/toutes-les-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleEditRecipe = (recipe: IRestaurantRecipe) => {
    dispatch(addRecipeToStore(recipe));
    dispatch(addRestaurantToStore(restaurant));
    navigate("/admin/modifier-une-recette");
  };

  useOutsideAlerter(popUpRef, () => setActivePopupId(null));
  useOutsideAlerter(reviewPopUpRef, () => setActivePreviewPopupId(null));

  const mapOverAddress = (address: string) => {
    return address
      .split(" ")
      .map((a) => a + "+")
      .join("")
      .slice(0, -1);
  };

  // Filter, sort, and then paginate the recipes
  const filteredAndSortedRecipes = [...recipes].sort((a, b) => a.id - b.id);

  const paginatedRecipes = filteredAndSortedRecipes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="admin-restaurant-table-container">
      <div className="admin-restaurant-table-content-container">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Recette</th>
              <th>Catégorie</th>
              <th>Restaurant</th>
              <th>Date de modification</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedRecipes &&
              paginatedRecipes.map((recipe) => (
                <tr key={recipe.id}>
                  <td>
                    {recipe.image && recipe.image.breakpoints ? (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}uploads/${recipe.image.breakpoints.thumbnail.name}`}
                        alt={`${recipe.name} logo`}
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : (
                      <div className="admin-restaurant-table-no-logo">
                        {recipe.name[0].toUpperCase()}
                      </div>
                    )}
                  </td>
                  <td>{recipe.name}</td>
                  <td>{recipe.category}</td>
                  <td className="relative">
                    <button
                      className="admin-restaurant-table-preview-button"
                      onMouseDown={(e) => togglePreviewPopUp(e, recipe.id)}
                    >
                      {restaurant ? restaurant.name : "Aucun restaurant"}
                    </button>
                    {activePreviewPopupId === recipe.id && (
                      <div
                        className="admin-restaurant-table-preview-popup-container"
                        ref={reviewPopUpRef}
                      >
                        <div className="admin-restaurant-table-preview-text">
                          {restaurant.name}
                        </div>
                        <div className="admin-restaurant-table-preview-text">
                          <a
                            href={`https://www.google.fr/maps/place/${mapOverAddress(
                              restaurant.address
                            )}`}
                            target="_blank"
                          >
                            {restaurant.address}
                          </a>
                        </div>
                        <div className="admin-restaurant-table-preview-text">
                          <a href={`mailto:${restaurant.email}`}>
                            {restaurant.email}
                          </a>
                        </div>
                        <div className="admin-restaurant-table-preview-text">
                          <a href={`tel:${restaurant.tel}`}>{restaurant.tel}</a>
                        </div>
                        <button
                          className="admin-restaurant-table-preview-button"
                          onMouseDown={() => handleEditRestaurant(restaurant)}
                        >
                          <MdOutlineModeEdit />
                          Modifier
                        </button>
                      </div>
                    )}
                  </td>
                  <td>{formatDate(recipe.updated_at)}</td>
                  <td className="relative">
                    <button
                      className="admin-restaurant-table-more-btn"
                      onClick={(e) => togglePopUp(e, recipe.id)}
                    >
                      <MdMoreHoriz size={22} />
                    </button>
                    {activePopupId === recipe.id && (
                      <div
                        className="admin-restaurant-table-popup-container"
                        ref={popUpRef}
                      >
                        <div
                          className="admin-restaurant-table-popup-edit"
                          onClick={() => handleEditRecipe(recipe)}
                        >
                          <MdOutlineModeEdit className="admin-restaurant-table-popup-edit-icon" />
                          <span className="admin-restaurant-table-popup-edit-icon">
                            Modifier
                          </span>
                        </div>
                        <div className="admin-restaurants-table-separator-container">
                          <div className="admin-restaurants-table-separator-content"></div>
                        </div>
                        <div
                          className="admin-restaurant-table-popup-delete"
                          onClick={() => handleDeleteRecipe(recipe)}
                        >
                          <FaRegTrashAlt className="admin-restaurant-table-popup-delete-icon" />
                          <span className="admin-restaurant-table-popup-delete-icon">
                            Supprimer
                          </span>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="admin-restaurant-table-bottom">
        <div className="admin-restaurant-table-restaurants-number">
          Nombre total de recettes sur la plateforme : {recipes.length}
        </div>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={prev}
          onNext={next}
        />
      </div>
    </div>
  );
};

export default AdminRecipesTable;
