import "./VisualizeDocument.css";
import { FC, useRef, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { IDocument } from "../../../../../interface/Documents/IDocument";
import useOutsideAlerter from "../../../../../hooks/useOutsideAlerter";

interface VisualizeDocumentProps {
  document: IDocument;
  setActive: (isActive: boolean) => void;
}

const VisualizeDocument: FC<VisualizeDocumentProps> = ({
  document,
  setActive,
}) => {
  const [currentPage, setCurrentPage] = useState(0); // Start from 0
  const [totalPages, setTotalPages] = useState(0);

  const visualizeDocumentRef = useRef(null);

  const handleDocumentLoad = (e: any) => {
    setTotalPages(e.doc.numPages);
  };

  const handlePageChange = (e: any) => {
    setCurrentPage(e.currentPage);
  };

  const fileUrl = process.env.REACT_APP_BASE_URL + document.file_path;

  const closeDocument = () => {
    setActive(false);
  };

  useOutsideAlerter(visualizeDocumentRef, () => closeDocument());

  return (
    <div className="visualize-document-overlay">
      <div
        className="visualize-document-container"
        ref={visualizeDocumentRef}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl}
            onDocumentLoad={handleDocumentLoad}
            onPageChange={handlePageChange}
          />
          <div>
            Page {currentPage + 1} / {totalPages}
          </div>
        </Worker>
      </div>
    </div>
  );
};

export default VisualizeDocument;
