import AdminEditRestaurant from "../../components/Admin/Restaurants/EditRestaurant/AdminEditRestaurant";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminEditRestaurantPage = () => {
  return (
    <LayoutAdmin subHeading="Modifier le restaurant">
      <AdminEditRestaurant />
    </LayoutAdmin>
  );
};

export default AdminEditRestaurantPage;
