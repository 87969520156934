import "./PriceSlider.css";
import React, { useState, useEffect } from "react";
import { PriceSliderProps } from "../MenusAndRecipes";

const PriceSlider: React.FC<PriceSliderProps> = ({
  min,
  max,
  items = [],
  setItems,
  pricePlaceholder,
}) => {
  const [value, setValue] = useState(500);
  const [price, setPrice] = useState({ min: 0, max: 500 });

  useEffect(() => {
    filterAndSortItems();
  }, [items, price]);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
    setPrice({ min: 0, max: newValue }); // Notifier le parent des nouvelles valeurs
    filterAndSortItems();
  };

  const filterAndSortItems = () => {
    if (!items || items.length === 0) {
      return; // Sortir de la fonction si items est indéfini ou null
    }

    const filteredItems = items
      .filter(
        (item) =>
          item.price && item.price >= price.min && item.price <= price.max
      )
      .sort((a, b) => a.price - b.price);

    setItems(filteredItems);
  };

  return (
    <div className="m-e-r-l-price-slider-container">
      <div className="m-e-r-l-price-slider-text">
        <span className="m-e-r-l-text">{pricePlaceholder} :&nbsp;</span>
        <span className="m-e-r-l-text">{`${
          price.min + " - " + price.max
        } €`}</span>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleSliderChange}
        className="m-e-r-l-input-slider"
        step="1"
      />
    </div>
  );
};

export default PriceSlider;
