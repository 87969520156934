import LayoutAdmin from "../../layout/LayoutAdmin";
import AdminEditMenu from "../../components/Admin/Menus/Edit/AdminEditMenu";

const AdminEditMenuPage = () => {
  return (
    <LayoutAdmin subHeading="Modifier le menu du restaurant">
      <AdminEditMenu />
    </LayoutAdmin>
  );
};

export default AdminEditMenuPage;
