import "./AdminCreateUser.css";

import { FormProvider, useForm } from "react-hook-form";
import { IEditUser, IUser } from "../../../../interface/IUser";
import handleAxiosError from "../../../../helpers/handleAxiosError";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import { AuthUpdateUserValidationRules } from "../../../../helpers/Validators/AuthUpdateValidationRules";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import { FaEnvelope, FaLock, FaUser } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { addNewUser } from "../../../../store/Admin/Users/Requests";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import { AdminCreateUserValidationRules } from "../../../../helpers/Validators/AdminCreateUserValidationRules";

const AdminCreateUser = () => {
  const methods = useForm<IUser>();
  const validationRules = AdminCreateUserValidationRules(methods.getValues);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const onSubmit = async (data: IUser) => {
    const userData = new FormData();
    data.rolesId = 2;

    if (data.password !== data.confirmPassword) {
      return;
    }

    if (data.avatar) userData.append("avatar", data.avatar);
    if (data.firstName) userData.append("firstName", data.firstName);
    if (data.lastName) userData.append("lastName", data.lastName);
    if (data.email) userData.append("email", data.email);
    if (data.tel) userData.append("tel", data.tel);
    if (data.password) userData.append("password", data.password);
    if (data.confirmPassword)
      userData.append("confirmPassword", data.confirmPassword);
    userData.append("rolesId", data.rolesId.toString());

    handleAsyncResponse(
      dispatch,
      addNewUser(userData),
      {
        successTitle: `Utilisateur créé`,
        successMessage: `L'utilisateur a été créé ! 🙌`,
        successDescription: `Vous avez créé un compte professionnel SafeEat ! 🙌`,
        navigateTo: "/admin/tous-les-utilisateurs",
      },
      { addToast, addNotification, navigate }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Créer un utilisateur</h1>
          <p>Remplissez ces champs pour créer un utilisateur.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="logo">
                La photo <span className="is-mandatory">*</span>
              </label>
              <ImageInput name="avatar" rules={validationRules.avatar} />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="firstName">
                Le prénom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="firstName"
                rules={validationRules.firstName}
                placeholder="Exemple : Patrick"
                id="firstName"
                icon={FaUser}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="lastName">
                Le nom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="lastName"
                rules={validationRules.lastName}
                placeholder="Exemple : Dupont"
                id="lastName"
                icon={FaUser}
              />
            </div>
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="email">
                L'email <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                type="email"
                name="email"
                rules={validationRules.email}
                placeholder="Exemple : patrick@safeeat.fr"
                id="email"
                icon={FaEnvelope}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="tel">
                Le téléphone mobile <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="tel"
                rules={validationRules.tel}
                step={0.01}
                placeholder="Exemple : 07 08 09 04 05"
                id="tel"
                icon={BsFillTelephoneFill}
                isFlex1={false}
              />
            </div>
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="password">
                Mot de passe <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                type="password"
                name="password"
                rules={validationRules.password}
                placeholder="Minimum 8 caractères"
                id="password"
                icon={FaLock}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="confirmPassord">
                Confirmer le mot de passe{" "}
                <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                type="password"
                name="confirmPassword"
                rules={validationRules.confirmPassword}
                placeholder="Réécrivez le mot de passe"
                id="confirmPassord"
                icon={FaLock}
              />
            </div>
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left"></div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Créer l'utilisateur
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default AdminCreateUser;
