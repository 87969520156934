import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IRestaurantProfile,
  RestaurantState,
} from "../interface/IRestaurantProfile";
import { IAllergens } from "../interface/IAllergens";
import {
  IEditRestaurantRecipe,
  IRestaurantRecipe,
} from "../interface/IRestaurantRecipe";
import { ApiErrorResponse } from "../interface/RejectValue";
import {
  addNewRestaurantRecipe,
  deleteRestaurantRecipe,
  fetchAllAllergens,
  toggleArchivedRestaurantRecipe,
  updateRestaurantRecipe,
} from "./Restaurant/Recipes/Requests";
import {
  addNewRestaurantProfile,
  deleteRestaurantProfile,
  fetchUserRestaurantsProfile,
  updateRestaurantProfile,
} from "./Restaurant/Profile/Requests";

export const restaurantSlice = createSlice({
  name: "restaurants",
  initialState: {
    restaurant: <IRestaurantProfile>{},
    recipe: <IRestaurantRecipe>{},
    allergens: <IAllergens[]>[],
    activeTabIndex: 0,
    done: false,
    loading: false,
    errors: <ApiErrorResponse[]>[],
  },
  reducers: {
    addRestaurantToStore(state: any, action) {
      state.restaurant = action.payload;
    },
    addAllRestaurantsToStore(state: any, action) {
      state.allRestaurants = action.payload;
    },
    addRecipeToStore(state: any, action) {
      state.recipe = action.payload;
    },
    setActiveTabIndex(state, action: PayloadAction<number>) {
      state.activeTabIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAllergens.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(
        fetchAllAllergens.fulfilled,
        (state, action: PayloadAction<IAllergens[]>) => {
          state.allergens = action.payload.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          state.isLoading = false;
          state.done = true;
        }
      )
      .addCase(fetchAllAllergens.rejected, (state, action) => {
        state.errors = action.payload as string;
        state.isLoading = false;
      })
      .addCase(fetchUserRestaurantsProfile.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(
        fetchUserRestaurantsProfile.fulfilled,
        (state, action: PayloadAction<IRestaurantProfile>) => {
          state.restaurant = action.payload;
          state.isLoading = false;
          state.done = true;
        }
      )
      .addCase(fetchUserRestaurantsProfile.rejected, (state, action) => {
        state.errors = action.payload as string;
        state.isLoading = false;
      })
      .addCase(addNewRestaurantProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addNewRestaurantProfile.fulfilled,
        (state, action: PayloadAction<IRestaurantProfile>) => {
          state.restaurant = action.payload;

          state.loading = false;
          state.errors = [];
        }
      )
      .addCase(addNewRestaurantProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateRestaurantProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRestaurantProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurant = action.payload;
        state.errors = [];
      })
      .addCase(updateRestaurantProfile.rejected, (state, action) => {
        state.errors = action.error.message;
        state.loading = false;
      })
      .addCase(addNewRestaurantRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewRestaurantRecipe.fulfilled, (state, action) => {
        state.loading = false;
        if (!state.restaurant.restaurantRecipe) {
          state.restaurant.restaurantRecipe = [];
        }

        state.restaurant.restaurantRecipe.push(action.payload);
        state.errors = [];
      })
      .addCase(addNewRestaurantRecipe.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload || {
          message: "Une erreur inconnue est survenue",
        };
      })
      .addCase(updateRestaurantRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRestaurantRecipe.fulfilled, (state, action) => {
        state.loading = false;
        state.recipe = action.payload;

        const updatedRecipeIndex = state.restaurant.restaurantRecipe.findIndex(
          (recipe: IEditRestaurantRecipe) => recipe.id === action.payload.id
        );

        state.restaurant.restaurantRecipe[updatedRecipeIndex] = action.payload;
        state.errors = [];
      })
      .addCase(updateRestaurantRecipe.rejected, (state, action) => {
        state.errors = action.error.message;
        state.loading = false;
      })
      .addCase(toggleArchivedRestaurantRecipe.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(toggleArchivedRestaurantRecipe.fulfilled, (state, action) => {
        state.loading = false;

        const recipeIndex = state.restaurant.restaurantRecipe.findIndex(
          (recipe: IRestaurantRecipe) => recipe.id === action.payload.id
        );
        state.restaurant.restaurantRecipe[recipeIndex].archived =
          action.payload.archived;
        state.errors = action.payload;
      })
      .addCase(toggleArchivedRestaurantRecipe.rejected, (state, action) => {
        state.loading = false;
        state.errors = false;
      })
      .addCase(deleteRestaurantProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRestaurantProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurant = {};
        state.errors = [];
      })
      .addCase(deleteRestaurantProfile.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.payload || "Échec de la suppression du profil du restaurant.";
      })
      .addCase(deleteRestaurantRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRestaurantRecipe.fulfilled, (state, action) => {
        state.loading = false;
        // Supprimez la recette de l'état en utilisant l'ID retourné
        state.restaurant.restaurantRecipe =
          state.restaurant.restaurantRecipe.filter(
            (recipe) => recipe.id !== action.payload.recipeId
          );
        state.errors = [];
      })
      .addCase(deleteRestaurantRecipe.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export const selectRestaurant = (state: { restaurants: RestaurantState }) =>
  state.restaurants.restaurant;
export const selectRecipe = (state: { restaurants: RestaurantState }) =>
  state.restaurants.recipe;
export const selectAllAllergens = (state: { restaurants: RestaurantState }) =>
  state.restaurants.allergens;

// STATE
export const selectActiveTabIndex = (state: { restaurants: RestaurantState }) =>
  state.restaurants.activeTabIndex;
export const selectIsLoading = (state: { restaurants: RestaurantState }) =>
  state.restaurants.isLoading;
export const selectErrors = (state: { restaurants: RestaurantState }) =>
  state.restaurants.errors;
export const selectIsDone = (state: { restaurants: RestaurantState }) =>
  state.restaurants.done;

export const {
  addRestaurantToStore,
  addAllRestaurantsToStore,
  addRecipeToStore,
  setActiveTabIndex,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;
