import { useDispatch, useSelector } from "react-redux";
import "./AdminUsers.css";
import { useEffect, useRef, useState } from "react";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { fetchAllUsersForAdmin } from "../../../../store/Admin/Users/Requests";
import { selectAdminUsers } from "../../../../store/Admin/Users/AdminUsers";
import SearchComponent from "../../../../layout/MenusAndRecipesLayout/Search/SearchComponent";
import { IUser } from "../../../../interface/IUser";
import { handleAsyncGetResponse } from "../../../../helpers/handleAsyncGetResponse";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { fetchAllRestaurantsForAdmin } from "../../../../store/Admin/Restaurants/Requests";
import AdminUsersTable from "./AdminUsersTable";

const AdminUsers = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { addToast, clearToasts } = useToast();

  const adminUsers = useSelector(selectAdminUsers);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);

  const dataLoadedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!dataLoadedRef.current) {
      handleAsyncGetResponse(
        dispatch,
        fetchAllUsersForAdmin(),
        addToast,
        "utilisateur",
        () => {
          handleAsyncGetResponse(
            dispatch,
            fetchAllRestaurantsForAdmin(),
            addToast,
            "restaurant"
          );
        }
      );
      setFilteredUsers(adminUsers);
      dataLoadedRef.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    setFilteredUsers(adminUsers);
  }, [adminUsers]);

  // Nettoyer les toasts au démontage
  useEffect(() => {
    return () => clearToasts();
  }, [clearToasts]);

  return (
    <div className="admin-restaurants-container">
      <h2 className="admin-restaurants-title">
        Tous les utilisateurs inscrits sur SafeEat
      </h2>
      <div className="admin-restaurants-content-container relative">
        <SearchComponent
          items={adminUsers}
          setFilteredItems={setFilteredUsers}
          placeholder="Rechercher un utilisateur ..."
        />
        {filteredUsers && filteredUsers.length !== 0 ? (
          <AdminUsersTable users={filteredUsers} />
        ) : (
          <div>
            Il n'y aucun utilisateur de disponible sur la plateforme pour le
            moment.
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
