import "./ChooseSide.css";
import ChefImg from "./../../../assets/img/im-chef.png";
import CustomerImg from "./../../../assets/img/im-customer.png";
import SafeEatLogo from "../../../assets/img/safeeat-logo.png";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoDocuments } from "react-icons/io5";
import colors from "../../constants/colors";
import { MdDescription, MdGavel, MdSecurity } from "react-icons/md";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const ChooseSide = () => {
  const navigate = useNavigate();

  const [animationClassLeft, setAnimationClassLeft] = useState("");
  const [animationClassRight, setAnimationClassRight] = useState("");
  const [animationClassItemsTop, setAnimationClassItemsTop] = useState("");

  const [rgpd, setRgpd] = useState<boolean>(false);
  const rgpdRef = useRef(null);

  const handleGoToRestaurantPage = () => {
    setAnimationClassLeft("side-c-animation-left");
    setAnimationClassRight("side-c-animation-right");
    setAnimationClassItemsTop("side-items-animation-top");

    setTimeout(() => {
      setAnimationClassLeft("");
      setAnimationClassRight("");
      setAnimationClassItemsTop("");
      navigate("/connexion");
    }, 1250);
  };

  const handleGoToCustomerPage = () => {
    setAnimationClassLeft("side-c-animation-left");
    setAnimationClassRight("side-c-animation-right");
    setAnimationClassItemsTop("side-items-animation-top");

    setTimeout(() => {
      setAnimationClassLeft("");
      setAnimationClassRight("");
      setAnimationClassItemsTop("");
      navigate("/restaurants");
    }, 1250);
  };

  const handleRgpd = (event) => {
    event.stopPropagation();
    setRgpd((prev) => !prev);
  };

  const handleOutsideClick = () => {
    setRgpd(false);
  };

  useOutsideAlerter(rgpdRef, handleOutsideClick);

  return (
    <div className="relative choose-side-container">
      <div
        className={`side-container side-container-open-left ${animationClassLeft}`}
      >
        <div className="side-content-container restaurant-color">
          <div className="side-img-container">
            <img src={ChefImg} alt="Image représentant un restaurateur" />
          </div>
          <button
            className="side-button-container"
            onClick={handleGoToRestaurantPage}
          >
            <div className="side-button-content-container restaurant-color">
              Restaurateur 👨‍🍳
            </div>
          </button>
        </div>
      </div>
      <div
        className={`side-container side-container-open-right ${animationClassRight}`}
      >
        <div className="side-content-container customer-color">
          <div className="side-img-container">
            <img
              src={CustomerImg}
              alt="Image représentant un client du restaurant"
            />
          </div>
          <button
            className="side-button-container"
            onClick={handleGoToCustomerPage}
          >
            <div className="side-button-content-container customer-color">
              Client 🍽️
            </div>
          </button>
        </div>
      </div>
      <div
        className={`choose-side-item-container choose-side-infos-container choose-side-item-hover choose-side-items-open-top ${animationClassItemsTop}`}
        onMouseDown={handleRgpd}
      >
        <IoDocuments color={colors.blue} />
      </div>
      <div
        className={`choose-side-question-container choose-side-items-open-top ${animationClassItemsTop}`}
      >
        <div className="choose-side-question-content-container">
          Continuer en tant que ...
        </div>
      </div>
      <div
        className={`choose-side-item-container choose-side-logo-container choose-side-items-open-top ${animationClassItemsTop}`}
      >
        <img src={SafeEatLogo} alt="Logo de la startup HelthTech SafeEat" />
      </div>
      {rgpd && (
        <div className="choose-side-rgpd-popup-container" ref={rgpdRef}>
          <div
            className="choose-side-rgpd-popup-blue"
            onClick={() => navigate("/conditions-generales-d-utilisation")}
          >
            <MdDescription className="choose-side-rgpd-popup-blue-icon" />
            <span className="choose-side-rgpd-popup-blue-icon">CGU</span>
          </div>

          <div
            className="choose-side-rgpd-popup-blue"
            onClick={() => navigate("/politique-de-confidentialite")}
          >
            <MdSecurity className="choose-side-rgpd-popup-blue-icon" />
            <span className="choose-side-rgpd-popup-blue-icon">
              Politique de confidentialtié
            </span>
          </div>

          <div
            className="choose-side-rgpd-popup-blue"
            onClick={() => navigate("/mentions-legales")}
          >
            <MdGavel className="choose-side-rgpd-popup-blue-icon" />
            <span className="choose-side-rgpd-popup-blue-icon">
              Mentions légales
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseSide;
