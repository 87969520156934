export const menuValidationRules = {
  name: {
    required: "Le nom de la recette est requis",
    maxLength: {
      value: 100,
      message: "Le nom du menu ne doit pas dépasser 100 caractères",
    },
  },
  price: {
    required: "Le prix de la recette est requis",
    min: {
      value: 0,
      message: "Le prix ne peut pas être négatif",
    },
    max: {
      value: 1000,
      message: "Le prix ne peut pas dépasser 1000€",
    },
  },
};

export default menuValidationRules;
