import { createSlice } from "@reduxjs/toolkit";
import {
  deleteDocumentForAdmin,
  fetchAllDocumentsForAdmin,
  refuseDocumentForAdmin,
  reviewDocumentForAdmin,
  validateDocumentForAdmin,
} from "./Requests";
import { IDocument } from "../../../interface/Documents/IDocument";

export const adminDocuments = createSlice({
  name: "adminDocuments",
  initialState: {
    documents: <IDocument[]>[],
    userId: <number>null,
    done: false,
    loading: false,
    errors: {} as any,
  },
  reducers: {
    removeDocumentsFromStore(state) {
      state.documents = [];
    },
    setUserIdToSore(state, action) {
      state.userId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDocumentsForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDocumentsForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload;
      })
      .addCase(fetchAllDocumentsForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(reviewDocumentForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(reviewDocumentForAdmin.fulfilled, (state, action) => {
        state.loading = false;

        if (!state.documents) {
          state.documents = [];
        }

        const docIndex = state.documents.findIndex(
          (doc) => doc.id === action.payload.id
        );
        if (docIndex !== -1) {
          state.documents[docIndex] = action.payload;
        } else {
          state.documents.push(action.payload);
        }
      })
      .addCase(reviewDocumentForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = false;
      })
      .addCase(validateDocumentForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateDocumentForAdmin.fulfilled, (state, action) => {
        state.loading = false;

        if (!state.documents) {
          state.documents = [];
        }

        const docIndex = state.documents.findIndex(
          (doc) => doc.id === action.payload.id
        );
        if (docIndex !== -1) {
          state.documents[docIndex] = action.payload;
        } else {
          state.documents.push(action.payload);
        }
      })
      .addCase(validateDocumentForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(refuseDocumentForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(refuseDocumentForAdmin.fulfilled, (state, action) => {
        state.loading = false;

        if (!state.documents) {
          state.documents = [];
        }

        const docIndex = state.documents.findIndex(
          (doc) => doc.id === action.payload.id
        );
        if (docIndex !== -1) {
          state.documents[docIndex] = action.payload;
        } else {
          state.documents.push(action.payload);
        }
      })
      .addCase(refuseDocumentForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = false;
      })
      .addCase(deleteDocumentForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(deleteDocumentForAdmin.fulfilled, (state, action) => {
        state.loading = false;

        state.documents = state.documents.filter(
          (doc: IDocument) => doc.id !== action.payload.documentId
        );

        state.errors = {};
      })
      .addCase(deleteDocumentForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      });
  },
});

export const selectAdminDocuments = (state: {
  adminDocuments: { documents: IDocument[] };
}) => state.adminDocuments.documents;

export const selectLoading = (state: {
  adminDocuments: { loading: boolean };
}) => state.adminDocuments.loading;

export const selectUserId = (state: { adminDocuments: { userId: number } }) =>
  state.adminDocuments.userId;

export const selectErrors = (state: { adminDocuments: { errors: any } }) =>
  state.adminDocuments.errors;

export const { setUserIdToSore, removeDocumentsFromStore } =
  adminDocuments.actions;

export default adminDocuments.reducer;
