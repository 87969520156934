import { IoClose } from "react-icons/io5";
import { NotificationItemProps } from "../../interface/INotification";
import { useNotification } from "../../hooks/useNotification";
import "./NotificationItem.css";
import { useNavigate } from "react-router";
import colors from "../constants/colors";

const NotificationItem: React.FC<NotificationItemProps> = ({
  id,
  title,
  description,
  originLink,
  type,
  onClose,
}) => {
  const { removeNotification, markAsRead } = useNotification();
  const navigate = useNavigate();

  const handleClose = () => {
    removeNotification(onClose);
  };

  const displayCorrectType = (type: string) => {
    switch (type) {
      case "restaurant":
        return "Voir le restaurant";
      case "recipes":
        return "Voir la recette";
      case "userProfile":
        return "Voir votre profil utilisateur";
      case "qr-code":
        return "Voir le QR-Code";
      default:
        break;
    }
  };

  const handleNotificationClick = () => {
    markAsRead(id);
    navigate(originLink);
  };

  return (
    <div className="notification-item">
      <div className="notification-content" onClick={handleNotificationClick}>
        <h3>{title}</h3>
        <p>{description}</p>
        <div
          style={{ textDecoration: "underline" }}
          onClick={() => navigate(originLink)}
        >
          {displayCorrectType(type)}
        </div>
      </div>
      <IoClose
        cursor={"pointer"}
        onClick={handleClose}
        color={colors.pink}
        size={25}
      />
    </div>
  );
};

export default NotificationItem;
