import { FC, useState } from "react";
import "./PublicRestaurantCard.css";
import Header from "./Header/Header";
import SelectAllergens from "./Filters/SelectAllergens/SelectAllergens";
import { useSelector } from "react-redux";
import {
  selectPublicMenus,
  selectPublicRestaurant,
} from "../../../store/PublicRestaurant/PublicRestaurant";
import FilterByCategory from "./Filters/Category/FilterByCategory";
import useFilteredItems from "../../../hooks/Public/useFilteredItems";
import { capitalize } from "../../../helpers/capitalize";
import { IoIosArrowDown, IoIosArrowUp, IoIosOptions } from "react-icons/io";
import { IPublicMenus } from "../../../interface/Public/IPublic";
import PublicRecipesList from "./PublicRecipes/PublicRecipesList/PublicRecipesList";
import { IRestaurantRecipe } from "../../../interface/IRestaurantRecipe";
import PublicMenusList from "./PublicMenus/PublicMenusList/PublicMenusList";
import ReviewPopUp from "../Review/ReviewPopUp";
import PublicRecipeBasketModal from "./PublicRecipes/PublicRecipeCard/PublicRecipeBasketModal/PublicRecipeBasketModal";
import { getTextColor } from "../../../helpers/color";

const PublicRestaurantCard: FC = () => {
  const restaurantPublic = useSelector(selectPublicRestaurant);
  const publicMenus = useSelector(selectPublicMenus);
  const [category, setCategory] = useState<string>("menu");
  const [allergenFilterIds, setAllergenFilterIds] = useState<string[]>([]);
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [toggleReviewPopUp, setToggleReviewPopUp] = useState<boolean>(false);

  const filteredItems = useFilteredItems(
    publicMenus,
    restaurantPublic.restaurantRecipe,
    allergenFilterIds,
    category
  );

  const categoryHeader =
    category !== "tout"
      ? `Les ${capitalize(category) + "s"}`
      : "Toutes les recettes";

  const handleOthersFilters = () => {
    setToggleFilters(!toggleFilters);
    alert("Fonctionnalité bientôt disponible ! 🙌");
  };

  const openReviewPopUp = () => {
    setToggleReviewPopUp(!toggleReviewPopUp);
  };

  return (
    <div className="public-showing-page-container">
      <div className="feedback-container" onClick={() => openReviewPopUp()}>
        <h3>Noter le restaurant</h3>
        {toggleReviewPopUp ? (
          <IoIosArrowUp className="toggleReviewPopUpIcon" />
        ) : (
          <IoIosArrowDown className="toggleReviewPopUpIcon" />
        )}
      </div>

      <ReviewPopUp
        setToggleReviewPopUp={setToggleReviewPopUp}
        toggleReviewPopUp={toggleReviewPopUp}
      />
      <div style={{ marginTop: "85px" }}>
        <Header
          name={restaurantPublic.name}
          logoUrl={
            restaurantPublic.logo
              ? `${process.env.REACT_APP_BASE_URL}uploads/${restaurantPublic.logo.breakpoints.thumbnail.name}`
              : null
          }
          address={restaurantPublic.address}
          primaryColor={
            restaurantPublic.options && restaurantPublic.options.primaryColor
          }
        />
        <div className="public-filters-container">
          <SelectAllergens
            setAllergenFilters={setAllergenFilterIds}
            primaryColor={
              restaurantPublic.options && restaurantPublic.options.primaryColor
            }
            secondaryColor={
              restaurantPublic.options &&
              restaurantPublic.options.secondaryColor
            }
          />
          <FilterByCategory
            primaryColor={
              restaurantPublic.options && restaurantPublic.options.primaryColor
            }
            secondaryColor={
              restaurantPublic.options &&
              restaurantPublic.options.secondaryColor
            }
            category={category}
            setCategory={setCategory}
          />
        </div>
        <div className="public-filters-header-container">
          <div
            className="public-filters-header"
            style={{
              color:
                restaurantPublic.options &&
                restaurantPublic.options.secondaryColor
                  ? getTextColor(restaurantPublic.options.secondaryColor)
                  : "black", // Par défaut, le texte est noir si aucune couleur secondaire n'est définie
            }}
          >
            {categoryHeader}
          </div>
          <div
            className="public-filter-icon-container"
            style={{
              border: `1px solid ${
                restaurantPublic.options &&
                restaurantPublic.options.secondaryColor
              }`,
              cursor: "not-allowed",
            }}
            onClick={handleOthersFilters}
          >
            <IoIosOptions
              color={
                restaurantPublic.options &&
                restaurantPublic.options.secondaryColor
              }
              size={24}
            />
          </div>
        </div>
        <div className="public-items">
          {category === "menu" ? (
            <PublicMenusList
              filteredMenus={filteredItems as IPublicMenus[]}
              primaryColor={
                restaurantPublic.options &&
                restaurantPublic.options.primaryColor
              }
              secondaryColor={
                restaurantPublic.options &&
                restaurantPublic.options.secondaryColor
              }
            />
          ) : (
            <PublicRecipesList
              recipes={filteredItems as IRestaurantRecipe[]}
              primaryColor={
                restaurantPublic.options &&
                restaurantPublic.options.primaryColor
              }
              secondaryColor={
                restaurantPublic.options &&
                restaurantPublic.options.secondaryColor
              }
              category={category}
            />
          )}
        </div>
      </div>
      <PublicRecipeBasketModal
        primaryColor={
          restaurantPublic.options && restaurantPublic.options.primaryColor
        }
        secondaryColor={
          restaurantPublic.options && restaurantPublic.options.secondaryColor
        }
      />
    </div>
  );
};

export default PublicRestaurantCard;
