import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../interface/IUser";
import {
  addNewUser,
  deleteUserForAdmin,
  editUser,
  fetchAllUsersForAdmin,
  toggleIsActivatedForAdmin,
} from "./Requests";

export const adminUsers = createSlice({
  name: "adminUsers",
  initialState: {
    users: <IUser[]>[],
    user: <IUser>{},
    done: false,
    loading: false,
    errors: {} as any,
  },
  reducers: {
    addAdminUserToStore(state: any, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewUser.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
        state.errors = {};
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      })
      .addCase(fetchAllUsersForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(fetchAllUsersForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = {};
        state.users = action.payload;
      })
      .addCase(fetchAllUsersForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      })
      .addCase(editUser.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = {};
        state.user = action.payload;
        const userIndex = state.users.findIndex(
          (user: IUser) => user.id === action.payload.id
        );
        state.users[userIndex] = action.payload;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      })
      .addCase(deleteUserForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(deleteUserForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        const remaniningUsers = state.users.filter(
          (user: IUser) => user.id !== action.payload.userId
        );
        state.users = remaniningUsers;

        state.errors = {};
      })
      .addCase(deleteUserForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      })
      .addCase(toggleIsActivatedForAdmin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(toggleIsActivatedForAdmin.fulfilled, (state, action) => {
        state.loading = false;

        const userIndex = state.users.findIndex(
          (user: IUser) => user.id === action.payload.id
        );

        state.users[userIndex].isActivated = action.payload.isActivated;
        state.errors = action.payload;
      })
      .addCase(toggleIsActivatedForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = false;
      });
  },
});

export const selectAdminUser = (state: { adminUsers: { user: IUser } }) =>
  state.adminUsers.user;

export const selectAdminUsers = (state: { adminUsers: { users: IUser[] } }) =>
  state.adminUsers.users;

export const selectLoading = (state: { adminUsers: { loading: boolean } }) =>
  state.adminUsers.loading;
export const selectErrors = (state: { adminUsers: { errors: any } }) =>
  state.adminUsers.errors;

export const { addAdminUserToStore } = adminUsers.actions;

export default adminUsers.reducer;
