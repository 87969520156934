import { FC } from "react";
import "./../css-pages/QRCode.css";
import LayoutPro from "../../layout/LayoutPro";
import QRCodeComponent from "../../components/Pro/QRCode/QRCodeComponent";

const QRCode: FC = () => {
  return (
    <LayoutPro subHeading="Voici la liste de vos QR-Code !">
      <main className="QRCode">
        <QRCodeComponent />
      </main>
    </LayoutPro>
  );
};

export default QRCode;
