const PaginationButtons = ({ currentPage, totalPages, onPrev, onNext }) => {
  return (
    <div className="admin-restaurant-table-buttons">
      <button
        onClick={onPrev}
        disabled={currentPage === 1}
        className="admin-restaurant-table-button"
      >
        Précédent
      </button>
      <button
        onClick={onNext}
        disabled={currentPage === totalPages}
        className="admin-restaurant-table-button"
      >
        Suivant
      </button>
    </div>
  );
};

export default PaginationButtons;
