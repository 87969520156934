import { FC, useEffect, useRef, useState } from "react";
import "./RecipeCard.css";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEllipsisH, FaRegTrashAlt } from "react-icons/fa";
import { IRestaurantRecipe } from "../../../../../interface/IRestaurantRecipe";
import { useNotification } from "../../../../../hooks/useNotification";
import { AppDispatch } from "../../../../../interface/Types/AppDispatch";
import { addRecipeToStore } from "../../../../../store/Restaurant";
import colors from "../../../../constants/colors";
import { formatDate } from "../../../../../helpers/getDate";
import { useToast } from "../../../../../contexts/Toast/ToastProvider";
import {
  deleteRestaurantRecipe,
  toggleArchivedRestaurantRecipe,
} from "../../../../../store/Restaurant/Recipes/Requests";
import handleAsyncResponse from "../../../../../helpers/handleAsyncResponse";

interface IMenuProps extends IRestaurantRecipe {
  idx: number;
}

const RecipeCard: FC<IMenuProps> = ({
  id,
  category,
  name,
  description,
  image,
  allergens,
  price,
  archived,
  updated_at,
  idx,
}) => {
  const { addNotification } = useNotification();
  const { addToast } = useToast();

  const [checked, setChecked] = useState<boolean>(true);

  const imagePath = process.env.REACT_APP_BASE_URL + "uploads/";

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [toggleMoreButton, setToggleMoreButton] = useState<boolean>(false);
  const recipeCardMoreContainer = useRef(null);

  const handleToggleMoreButton = (state) => {
    setToggleMoreButton(state);
  };

  // useOutsideAlerter(menuCardMoreContainer, () => handleToggleMoreButton(false));

  const handleOutsideClick = (event) => {
    event.stopPropagation();
  };

  const handleDeleteRecipe = () => {
    try {
      dispatch(deleteRestaurantRecipe(id));

      addToast(
        "success",
        "Recette supprimée",
        `Votre recette ${name} a été supprimé ! 🙌`
      );
      addNotification({
        title: `${name} a été supprimée avec succès ! 🙌`,
        description:
          "Vous avez supprimé la recette : " + name + " de votre restaurant",
        originLink: "/pro/mes-recettes",
        type: "recipe",
      });

      navigate("/pro/mes-recettes");
    } catch (error) {
      console.error("Erreur lors de la suppression de la recette :", error);
      addToast(
        "error",
        "Erreur de suppression de la recette",
        `Une erreur a été détecté lors de la suppression de la recette. 😓`
      );
    }

    setToggleMoreButton(false);
  };

  const dispatchToStoreAndRedirect = () => {
    dispatch(
      addRecipeToStore({
        id,
        category,
        name,
        description,
        image,
        allergens,
        price,
        archived,
      })
    );
    navigate("/pro/modifier-une-recette");
  };

  const handleArchived = async () => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    handleAsyncResponse(
      dispatch,
      toggleArchivedRestaurantRecipe({
        id,
        checked: newCheckedValue,
      }),
      {
        successTitle: `Archivage de la recette modifiée`,
        successMessage: `Votre recette ${name} ${
          newCheckedValue ? "n'est plus archivée" : "a été archivée"
        } ! 🙌`,
        successDescription: `Votre recette ${name} ${
          newCheckedValue ? "n'est plus archivée" : "a été archivée"
        } ! 🙌`,
        navigateTo: "/pro/mes-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  useEffect(() => {
    setChecked(archived);
  }, []);

  const isMaxLength = (text: string | undefined, size: number): string => {
    return text !== undefined
      ? text.length > size
        ? text.substring(0, size) + " ..."
        : text
      : "";
  };

  const colorClasses = ["green", "brown", "pink"].map((color) => ({
    light: `menu-card-container-l${color}`,
    normal: `menu-card-container-${color}`,
    color: `menu-card-container-color-${color}`,
    buttonHover: `menu-card-edit-btn-d-${color}`,
  }));

  const currentColor = colorClasses[idx % 3];

  const switchColor = [colors.green, colors.brown, colors.pink];

  return (
    <div className="relative recipe-card-border">
      <div className={`recipe-card-container ${currentColor.light}`}>
        <div className="recipe-card-date-container">
          <span className={`recipe-card-date ${currentColor.color}`}>
            {formatDate(updated_at)}
          </span>
          <div
            className="recipe-card-more"
            onClick={() => handleToggleMoreButton(!toggleMoreButton)}
          >
            <FaEllipsisH
              size={18}
              className={`recipe-card-more-icon ${currentColor.color}`}
            />
          </div>
          {/* <FaUserCircle className="text-gray-400" size={24} /> */}
        </div>

        <div className="recipe-card-middle">
          <div className="recipe-card-name-container">
            <p className="recipe-card-name">{name}</p>
            <h3 className="recipe-card-description">
              {isMaxLength(description, 100)}
            </h3>
          </div>
          <div className="recipe-card-image-container">
            {image && image.breakpoints && image.breakpoints.thumbnail ? (
              <img
                src={imagePath + image.breakpoints.thumbnail.name}
                alt="Image de la recette"
              />
            ) : (
              <div
                className="recipe-card-image-no-preview"
                style={{ borderColor: currentColor.buttonHover }}
              ></div>
            )}
          </div>
        </div>

        <div className="recipe-card-tags-container">
          {allergens && allergens.length > 0 ? (
            <>
              {allergens.slice(0, 4).map((allergen, index) => (
                <span
                  key={index}
                  className={`recipe-card-tag ${currentColor.color}`}
                >
                  {allergen.name[0].toUpperCase() + allergen.name.substring(1)}
                </span>
              ))}
              {allergens.length > 4 && (
                <span className={`recipe-card-tag ${currentColor.color}`}>
                  ...
                </span>
              )}
            </>
          ) : (
            <span className="recipe-card-tag-none recipe-card-tag-more">
              Il n'y aucun allergènes.
            </span>
          )}
        </div>
      </div>

      <div className="recipe-card-bottom">
        <div>
          <p className="recipe-card-price">{price} €</p>
          <p className="recipe-card-category">{category}</p>
        </div>
        <div className="recipe-card-buttons-container">
          <div className="recipe-card-switch-btn-container">
            <Switch
              onChange={() => handleArchived()}
              onColor={switchColor[idx % 3]}
              checked={checked}
              className="react-switch"
            />
          </div>
          <button
            className={`recipe-card-edit-btn ${currentColor.normal} ${currentColor.buttonHover}`}
            onClick={dispatchToStoreAndRedirect}
          >
            Modifier
          </button>
        </div>
      </div>
      {toggleMoreButton && (
        <div
          className={`recipe-card-more-container`}
          ref={recipeCardMoreContainer}
          onClick={handleOutsideClick}
        >
          <div className="recipe-card-more-delete" onClick={handleDeleteRecipe}>
            <FaRegTrashAlt className="recipe-card-more-delete-icon" />
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipeCard;
