type PathType = { path: string; title: string }[];

const navigationDrawerTop: PathType = [
  { path: "/pro/mon-profil", title: "Mon profil" },
  { path: "/mes-documents", title: "Mes documents" },
  { path: "/connexion", title: "Se déconnecter" },
];

const pages = {
  primary: navigationDrawerTop,
};

export default pages;
