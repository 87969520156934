import { FC } from "react";
import LayoutPro from "../../layout/LayoutPro";
import MenusComponent from "../../components/Pro/Menu/View/MenusComponent";

const Menus: FC = () => {
  return (
    <LayoutPro subHeading="La carte de tous vos menus !">
      <MenusComponent />
    </LayoutPro>
  );
};

export default Menus;
