export const AdminCreateUserValidationRules = (getValues = undefined) => ({
  avatar: {
    validate: {
      isImage: (file) => {
        if (!file) return true;
        const supportedFormats = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
        ];
        return supportedFormats.includes(file.type || file.mimeType)
          ? true
          : "Veuillez sélectionner une image valide (JPEG, JPG, PNG, WEBP).";
      },
      isUnderSizeLimit: (file) => {
        if (!file) return true;
        const maxSizeInBytes = 2097152; // 2MB
        return file.size <= maxSizeInBytes
          ? true
          : "L'image doit être moins de 2MB.";
      },
    },
  },
  firstName: {
    required: "Le prénom est requis",
    pattern: {
      value: /^[A-Za-z\s\-]+$/,
      message:
        "Le champ prénom ne doit contenir que des lettres, des espaces ou des tirets.",
    },
  },
  lastName: {
    required: "Le nom de famille est requis",
    pattern: {
      value: /^[A-Za-z\s\-]+$/,
      message:
        "Le champ nom ne doit contenir que des lettres, des espaces ou des tirets.",
    },
  },
  email: {
    required: "L'adresse email est requis",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Le champ email doit être une adresse email valide.",
    },
  },
  tel: {
    required: "Le numéro de téléphone mobile est requis",
    pattern: {
      value: /^(\+33|0)[67]\d{8}$/,
      message:
        "Le numéro de téléphone mobile doit être un numéro valide en France (+33 ou 06/07 suivi de 8 chiffres).",
    },
  },
  password: {
    required: "Le mot de passe est requis",
    minLength: {
      value: 8,
      message: "Le mot de passe doit contenir au moins 8 caractères",
    },
    maxLength: {
      value: 180,
      message: "Le mot de passe ne peut pas dépasser 180 caractères",
    },
  },
  confirmPassword: {
    required: "La confirmation du mot de passe est requise",
    minLength: {
      value: 8,
      message: "Le mot de passe doit contenir au moins 8 caractères",
    },
    maxLength: {
      value: 180,
      message: "Le mot de passe ne peut pas dépasser 180 caractères",
    },
    validate: {
      matchesPreviousPassword: (value) =>
        getValues("password") === value ||
        "Les mots de passe doivent être identiques.",
    },
  },
});
