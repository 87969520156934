import AdminEditMyAccount from "../../components/Admin/MyAccount/Edit/AdminEditMyAccount";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminEditMyAccountPage = () => {
  return (
    <LayoutAdmin subHeading="Modifier le menu du restaurant">
      <AdminEditMyAccount />
    </LayoutAdmin>
  );
};

export default AdminEditMyAccountPage;
