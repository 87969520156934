import React, { createContext, useContext, useState, useEffect } from "react";
import {
  NotificationContextProps,
  Notification,
} from "../interface/INotification";

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showNotificationsAnimation, setShowNotificationsAnimation] =
    useState<boolean>(false);

  const addNotification = (
    notification: Omit<Notification, "id" | "isRead">
  ) => {
    const newNotification: Notification = {
      id: notifications.length + 1,
      isRead: false,
      ...notification,
    };
    setNotifications([...notifications, newNotification]);
  };

  const removeNotification = (notification: Notification) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notif) => notif !== notification)
    );
  };

  const markAsRead = (id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id
          ? { ...notification, isRead: true }
          : notification
      )
    );
  };

  const markAllAsRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({
        ...notification,
        isRead: true,
      }))
    );
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        showNotifications,
        showNotificationsAnimation,
        setShowNotifications,
        setShowNotificationsAnimation,
        addNotification,
        removeNotification,
        markAsRead,
        markAllAsRead,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
