import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { FieldValue } from "react-hook-form";
import { IAsk, IEditUser, IReset, IUser, IUserAuth } from "../interface/IUser";
import { getTokenFromLocalStorage } from "../helpers/localStorage";
import { IRestaurantProfile } from "../interface/IRestaurantProfile";
import { IEditRestaurantProfile } from "../interface/IEditRestaurantProfile";
import { IAllergens } from "../interface/IAllergens";
import { Review } from "../interface/Review";
import {
  ICreateRecipesWithFile,
  IRestaurantRecipe,
} from "../interface/IRestaurantRecipe";
import { ICreateMenu, IMenu } from "../interface/Menus/IMenu";
import { IPublicMenus } from "../interface/Public/IPublic";
import { IRestaurantRecipeArray } from "../store/Admin/Recipes/Requests";
import { IDocument, IDocumentToSend } from "../interface/Documents/IDocument";

const createInstance = (
  withToken: boolean = false,
  isMultiPart: boolean = false
): AxiosInstance => {
  const instanceConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 15000,
    headers: {
      // Conditionnellement définir le 'Content-Type'
      "ngrok-skip-browser-warning": "true",
      ...(isMultiPart ? {} : { "Content-Type": "application/json" }),
    },
  };

  const instance = axios.create(instanceConfig);

  // Ajout d'un intercepteur de requête pour injecter le token
  if (withToken) {
    instance.interceptors.request.use(
      (config) => {
        const token = getTokenFromLocalStorage();
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  return instance;
};

const instance = createInstance();
const instanceProtected = createInstance(true, false);
const instanceMultiPartProtected = createInstance(true, true);

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: <T>(url: string, data: T) =>
    instance.post(url, data).then(responseBody),
  put: (url: string, data: object) =>
    instance.put(url, data).then(responseBody),
  patch: (url: string, data: object) =>
    instance.patch(url, data).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};

const requestsProtected = {
  get: (url: string) => instanceProtected.get(url).then(responseBody),
  post: <T>(url: string, data: T) =>
    instanceProtected.post(url, data).then(responseBody),
  postWithArray: (url: string, data: Array<any>) =>
    instanceProtected.post(url, data).then(responseBody),
  put: <T>(url: string, data: T) =>
    instanceProtected.put(url, data).then(responseBody),
  patch: <T>(url: string, data?: T) =>
    instanceProtected.patch(url, data).then(responseBody),
  delete: (url: string) => instanceProtected.delete(url).then(responseBody),
};

const requestMultiPartProtected = {
  post: <T>(url: string, data: T) =>
    instanceMultiPartProtected.post(url, data).then(responseBody),
  get: (url: string) => instanceMultiPartProtected.get(url).then(responseBody),
  postWithArray: (url: string, data: Array<any>) =>
    instanceMultiPartProtected.post(url, data).then(responseBody),
  put: <T>(url: string, data: T) =>
    instanceMultiPartProtected.put(url, data).then(responseBody),
  patch: <T>(url: string, data: T) =>
    instanceMultiPartProtected.patch(url, data).then(responseBody),
  delete: (url: string) =>
    instanceMultiPartProtected.delete(url).then(responseBody),
};

export const UserProfiles = {
  verifyEmail: (token: string): Promise<{ message: string }> =>
    requests.get("user/verify-email/" + token),
  validateToken: (): Promise<{ valid: boolean }> =>
    requestsProtected.get("validate-token"),
  askReset: (email: FieldValue<IAsk>): Promise<{ message: string }> =>
    requests.post("forgot-password", email),
  resetPassword: (
    passwords: FieldValue<IReset>
  ): Promise<{ message: string }> => requests.post("reset-password", passwords),
  logout: (): Promise<void> => requestsProtected.get("logout"),
  authUser: (userCredentials: FieldValue<IUserAuth>): Promise<IUser> =>
    requests.post("login", userCredentials),
  updateUser: (userData: FieldValue<IEditUser>): Promise<IEditUser> =>
    requestMultiPartProtected.put("users", userData),

  // ADMIN
  createUser: (userData: FieldValue<IUser>): Promise<IUser> =>
    requestMultiPartProtected.post("admin/register", userData),
  getAllUsers: (): Promise<IUser[]> =>
    requestsProtected.get("/admin/all-users"),
  updateUserForAdmin: (userData: FieldValue<IEditUser>): Promise<IEditUser> =>
    requestMultiPartProtected.patch("/admin/edit-user", userData),
  updateMyAccountForAdmin: (
    userData: FieldValue<IEditUser>
  ): Promise<IEditUser> =>
    requestMultiPartProtected.patch("/admin/edit-my-account", userData),
  deleteUserForAdmin: (userId: number): Promise<any> =>
    requestsProtected.delete("admin/delete-user/" + userId),
  updateToggleIsActivatedForAdmin: (
    userData: FieldValue<{ isActivated: boolean }>,
    userId: number
  ): Promise<{ isActivated: boolean; id: number }> =>
    requestsProtected.patch(
      "admin/user/toggle-is-activated/" + userId,
      userData
    ),
};

export const restaurantProfile = {
  createRestaurantProfile: (
    restaurantData: FieldValue<IRestaurantProfile>
  ): Promise<IRestaurantProfile> =>
    requestMultiPartProtected.post("user/restaurants-profile", restaurantData),
  updateRestaurantProfile: (
    restaurantData: FieldValue<IRestaurantProfile>,
    profileId: number
  ): Promise<IRestaurantProfile> =>
    requestMultiPartProtected.put(
      "user/restaurant-profile/" + profileId,
      restaurantData
    ),
  getUserRestaurantsProfile: (): Promise<IRestaurantProfile> =>
    requestsProtected.get("user/restaurants-profile"),
  getUserRestaurantProfileByRestaurantName: (
    restaurantName: string
  ): Promise<IRestaurantProfile> =>
    requests.get("user/restaurant-profile/" + restaurantName),
  deleteRestaurantProfile: (restaurantId: number): Promise<any> =>
    requestsProtected.delete("user/restaurant-profile/" + restaurantId),

  // REVIEW

  addReviewToRestaurantProfile: (
    reviewData: FieldValue<Review>
  ): Promise<Review> =>
    requests.post("restaurant-profiles/reviews", reviewData),
  getAllReviews: (restaurantId: number): Promise<Review[]> =>
    requestsProtected.get("/restaurant-profiles/reviews/" + restaurantId),

  // ADMIN

  getAllRestaurantsForAdmin: (): Promise<IRestaurantProfile[]> =>
    requestsProtected.get("/all-restaurants-profiles"),
  getRestaurantForAdmin: (restaurantId: number): Promise<IRestaurantProfile> =>
    requestsProtected.get("/admin/restaurant-profile" + restaurantId),
  createRestaurantForAdmin: (
    restaurantData: FieldValue<IRestaurantProfile>
  ): Promise<IRestaurantProfile> =>
    requestMultiPartProtected.post("admin/restaurants", restaurantData),
  updateRestaurantForAdmin: (
    restaurantData: FieldValue<IRestaurantProfile>,
    restaurantId: number
  ): Promise<IRestaurantProfile> =>
    requestMultiPartProtected.patch(
      "/admin/edit-restaurant/" + restaurantId,
      restaurantData
    ),
  deleteRestaurantForAdmin: (restaurantId: number): Promise<any> =>
    requestsProtected.delete("admin/delete-restaurant/" + restaurantId),
};

export const restaurantRecipe = {
  createRestaurantRecipe: (
    recipeData: FieldValue<IRestaurantRecipe>
  ): Promise<IRestaurantRecipe> =>
    requestMultiPartProtected.post("restaurant/restaurant-recipes", recipeData),
  updateRestaurantRecipe: (
    recipeData: FieldValue<IRestaurantRecipe>,
    recipeId: number
  ): Promise<IRestaurantRecipe> =>
    requestMultiPartProtected.put(
      "restaurant/restaurant-recipes/" + recipeId,
      recipeData
    ),
  updateToggleRestaurantRecipe: (
    recipeData: FieldValue<{ archived: boolean }>,
    recipeId: number
  ): Promise<{ archived: boolean; id: number }> =>
    requestsProtected.patch(
      "restaurant/recipes/change-archived/" + recipeId,
      recipeData
    ),
  getAllRecipesByRestaurantProfileId: (
    restaurantId: number
  ): Promise<IRestaurantRecipe[]> =>
    requestsProtected.get(
      "restaurant/getAllRecipesByRestaurantId/" + restaurantId
    ),
  deleteRestaurantRecipe: (recipeId: number): Promise<IRestaurantRecipe> =>
    requestsProtected.delete("restaurant/restaurant-recipes/" + recipeId),
  getAllAllergens: (): Promise<IAllergens[]> => requests.get("normalAllergies"),

  // ADMIN

  addMultipleRecipes: (
    fileData: FieldValue<ICreateRecipesWithFile>
  ): Promise<IRestaurantRecipeArray> =>
    requestMultiPartProtected.post("admin/recipes/import-recipes", fileData),
  createRecipeForAdmin: (
    recipeData: FieldValue<IRestaurantRecipe>
  ): Promise<IRestaurantRecipe> =>
    requestMultiPartProtected.post("admin/recipes", recipeData),
  updateRecipeForAdmin: (
    recipeData: FieldValue<IRestaurantRecipe>,
    recipeId: number
  ): Promise<IRestaurantRecipe> =>
    requestMultiPartProtected.patch("admin/recipes/" + recipeId, recipeData),
  updateToggleRecipeForAdmin: (
    recipeData: FieldValue<{ archived: boolean }>,
    recipeId: number
  ): Promise<{ archived: boolean; id: number }> =>
    requestsProtected.patch(
      "admin/recipes/change-archived/" + recipeId,
      recipeData
    ),
  deleteRecipeForAdmin: (recipeId: number): Promise<IRestaurantRecipe> =>
    requestsProtected.delete("admin/recipes/" + recipeId),
  getAllRecipesForAdmin: (
    restaurantProfileId: number
  ): Promise<IRestaurantRecipe[]> =>
    requestsProtected.get("admin/recipes/restaurant/" + restaurantProfileId),
};

export const restaurantMenu = {
  getRestaurantMenuByRestaurantName: (
    restaurantName: string
  ): Promise<IPublicMenus> =>
    requests.get("restaurant/menus/restaurant-name/" + restaurantName),
  getAllMenus: (restaurantProfileId: number): Promise<IMenu[]> =>
    requests.get("restaurant/menus/" + restaurantProfileId),
  createMenu: (
    restaurantProfileId: number,
    menuData: FieldValue<ICreateMenu>
  ): Promise<IMenu> =>
    requestsProtected.post(
      "restaurant/create-menu/" + restaurantProfileId,
      menuData
    ),
  updateRestaurantMenu: (
    menuData: FieldValue<IMenu>,
    menuId: number
  ): Promise<IMenu> =>
    requestsProtected.patch("restaurant/menus/" + menuId, menuData),
  updateToggleRestaurantMenu: (
    menuData: FieldValue<{ archived: boolean }>,
    menuId: number
  ): Promise<{ archived: boolean; id: number }> =>
    requestsProtected.patch(
      "restaurant/menus/change-archived/" + menuId,
      menuData
    ),
  deleteRestaurantMenu: (menuId: number): Promise<IMenu> =>
    requestsProtected.delete("restaurant/restaurant-menus/" + menuId),

  // ADMIN

  getAllMenusForAdmin: (restaurantProfileId: number): Promise<IMenu[]> =>
    requestsProtected.get("admin/menus/restaurant/" + restaurantProfileId),
  createMenuForAdmin: (
    restaurantProfileId: number,
    menuData: FieldValue<ICreateMenu>
  ): Promise<IMenu> =>
    requestsProtected.post(
      "admin/create-menu/" + restaurantProfileId,
      menuData
    ),
  updateMenuForAdmin: (
    menuData: FieldValue<IMenu>,
    menuId: number
  ): Promise<IMenu> =>
    requestsProtected.patch("admin/menus/" + menuId, menuData),
  updateToggleMenuForAdmin: (
    menuData: FieldValue<{ archived: boolean }>,
    menuId: number
  ): Promise<{ archived: boolean; id: number }> =>
    requestsProtected.patch("admin/menus/change-archived/" + menuId, menuData),
  deleteMenuForAdmin: (menuId: number): Promise<IMenu> =>
    requestsProtected.delete("admin/menus/" + menuId),
};

export const UserDocuments = {
  getMyDocuments: (): Promise<IDocument[]> =>
    requestsProtected.get("user/documents/my-documents"),
  getDocuments: (userId: number): Promise<IDocument[]> =>
    requestsProtected.get("admin/documents/" + userId),
  addDocument: (documentData: FormData): Promise<IDocumentToSend> =>
    requestMultiPartProtected.post("user/documents/add", documentData),
  deleteDocument: (documentId: number): Promise<{ message: string }> =>
    requestsProtected.delete("user/documents/" + documentId),
  // ADMIN

  reviewDocumentForAdmin: (documentId: number): Promise<IDocument> =>
    requestsProtected.patch("admin/documents/" + documentId + "/review"),
  validateDocumentForAdmin: (documentId: number): Promise<IDocument> =>
    requestsProtected.patch("admin/documents/" + documentId + "/validate"),
  refuseDocumentForAdmin: (documentId: number): Promise<IDocument> =>
    requestsProtected.patch("admin/documents/" + documentId + "/refuse"),
  deleteDocumentForAdmin: (
    documentId: number
  ): Promise<{ userId: number; message: string }> =>
    requestsProtected.delete("admin/documents/" + documentId),
};
