import "./RecipesByDateChart.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { useNavigate } from "react-router-dom";
import { IoArrowForwardCircle } from "react-icons/io5";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend
);

const RecipesByDateChart = ({ recipes }) => {
  const navigate = useNavigate();

  let minDate = new Date(
    Math.min(...recipes.map((r) => new Date(r.created_at).getTime()))
  );
  recipes.forEach((r) => {
    if (r.modifications) {
      r.modifications.forEach((m) => {
        const modTime = new Date(m.modified_at).getTime();
        if (modTime < minDate.getTime()) minDate = new Date(modTime);
      });
    }
  });

  let maxDate = new Date(
    Math.max(...recipes.map((r) => new Date(r.created_at).getTime()))
  );
  recipes.forEach((r) => {
    if (r.modifications) {
      r.modifications.forEach((m) => {
        const modTime = new Date(m.modified_at).getTime();
        if (modTime > maxDate.getTime()) maxDate = new Date(modTime);
      });
    }
  });

  const datasets = recipes.map((recipe, index) => {
    const dataPoints = [
      {
        x: new Date(recipe.created_at),
        y: index + 1, // Each recipe gets a unique y-value
      },
    ];

    if (recipe.modifications && Array.isArray(recipe.modifications)) {
      recipe.modifications.forEach((mod) => {
        dataPoints.push({
          x: new Date(mod.modified_at),
          y: index + 1, // Keep modifications at the same y-level
        });
      });
    }

    return {
      label: `${recipe.name}`,
      data: dataPoints,
      borderColor: `hsl(${(index * 360) / recipes.length}, 100%, 70%)`,
      backgroundColor: `hsl(${(index * 360) / recipes.length}, 100%, 70%)`,
      fill: false,
      showLine: true,
      pointRadius: 5,
    };
  });

  const options = {
    scales: {
      x: {
        type: "time",
        min: new Date(minDate.getTime() - 86400000),
        max: new Date(maxDate.getTime() + 86400000),
        time: {
          unit: "day",
          tooltipFormat: "PP",
          displayFormats: {
            day: "dd/MM/yyyy",
          },
        },
        title: {
          display: false,
          text: "Historique de modification des recettes",
        },
      },
      y: {
        display: true,
        title: {
          display: false,
          text: "Recipe Index",
        },
        ticks: {
          stepSize: 1,
          beginAtZero: false,
          callback: function (value, index, values) {
            return `Recette n°${value}`;
          },
          count: recipes.length,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Historique de modification des recettes",
      },
    },
    maintainAspectRatio: true,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    responsive: true,
  };

  return (
    <div className="recipes-by-date-chart-container">
      <div className="recipes-by-date-header-container">
        <div className="recipes-by-date-title">
          Historique de modification des recettes
        </div>
        <div
          className="recipes-by-date-goto-view-container"
          onClick={() => navigate("/pro/mes-recettes")}
        >
          <IoArrowForwardCircle
            className="recipes-by-date-view-icon"
            size={35}
          />
        </div>
      </div>
      <Line data={{ datasets }} options={options} />
    </div>
  );
};

export default RecipesByDateChart;
