import React, { useEffect, useRef, useState } from "react";
import "./CustomMenuMultiSelect.css";
import { FaTag, FaCheck, FaTimes } from "react-icons/fa";
import { Controller, useFormContext } from "react-hook-form";
import colors from "../../../constants/colors";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";

const CustomMenuMultiSelect = ({
  name,
  options,
  defaultValue = [],
  category,
  icon: Icon = FaTag,
  isFlex1 = true,
}) => {
  const { control, setValue } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);
  const [recipes, setRecipes] = useState([]);

  const multiselectRef = useRef(null);

  const { addToast } = useToast();

  useOutsideAlerter(multiselectRef, () => setIsOpen(false));

  useEffect(() => {
    if (defaultValue.length === 0) {
      setRecipes(options);
    } else {
      const defaultIds = new Set(defaultValue.map((item) => item.id));
      const availableOptions = options.filter(
        (option) => !defaultIds.has(option.id)
      );
      setRecipes([...defaultValue, ...availableOptions]);
    }
  }, [defaultValue, options]);

  useEffect(() => {
    setValue(name, selectedOptions);
  }, [selectedOptions, setValue, name]);

  const handleSetIsOpen = () => {
    if (recipes.length !== 0) {
      setIsOpen(!isOpen);
    } else {
      addToast(
        "info",
        "Recettes manquantes",
        "Il n'y a plus de nouvelles recettes disponibles ! 😓"
      );
    }
  };

  const toggleOption = (option, e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((o) => o !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <div
          className="input-container"
          style={{ flex: isFlex1 && "1" }}
          ref={multiselectRef}
        >
          <div
            className="relative menu-form-input-field-container pointer"
            style={{ flex: isFlex1 && "1" }}
            onClick={handleSetIsOpen}
          >
            <div className="custom-menu-multiselect-recipe-names-container">
              <Icon className="menu-icon" />

              {selectedOptions.length !== 0 ? (
                <div>
                  {selectedOptions.map((option, idx) => (
                    <div key={idx}>{option.name}</div>
                  ))}
                </div>
              ) : (
                <div className="color-placeholder">
                  Aucun {category} sélectionné.
                </div>
              )}
            </div>
            {isOpen ? (
              <IoIosArrowUp color={colors.green} />
            ) : (
              <IoIosArrowDown color={colors.green} />
            )}
            {isOpen && (
              <ul className="custom-menu-multiselect-list">
                {recipes.map((option, index) => (
                  <li
                    key={index}
                    className={`custom-menu-multiselect-item ${
                      selectedOptions.includes(option) &&
                      "custom-menu-multiselect-item-remove"
                    }`}
                    onClick={(e) => toggleOption(option, e)}
                  >
                    {option.name}
                    {selectedOptions.includes(option) ? (
                      <FaTimes
                        className="menu-icon"
                        style={{ color: colors.red }}
                      />
                    ) : (
                      <FaCheck className="menu-icon" />
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {error && <p className="is-mandatory error-msg">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default CustomMenuMultiSelect;
