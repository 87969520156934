import { FC } from "react";
import AddRecipeComponent from "../../components/Pro/Recipe/Add/AddRecipeComponent";
import LayoutPro from "../../layout/LayoutPro";

const AddRecipe: FC = () => {
  return (
    <LayoutPro subHeading="Ajouter une recette à votre carte !">
      <AddRecipeComponent />
    </LayoutPro>
  );
};

export default AddRecipe;
