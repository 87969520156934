import { persistStore } from "redux-persist";
import store from "../store/Store";
import { UserProfiles } from "../api/api";
import { removeTokenFromLocalStorage } from "./localStorage";
import { useNavigate } from "react-router-dom";

export const resetStore = async () => {
  let persistor = persistStore(store);
  await persistor.purge(); // Nettoie le stockage persistant
  await UserProfiles.logout();
  removeTokenFromLocalStorage();
};
