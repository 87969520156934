export default {
  green: "#B7DCB2",
  pink: "#FFB7B7",
  brown: "#F0B28B",
  blue: "#8aa7ef",
  red: "#d51515",
  grey: "rgb(156, 163, 175)",
  darkerGreen: "#90E186",
  darkerPink: "#FF6F6F",
  darkerBrown: "#F19154",
  lightBlue: "#c5d5fe",
  lightGreen: "#f3fdf2",
  lightBrown: "#fff6f0",
  lightPink: "#fff3f3",
};
