import { FC, useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import NotificationList from "./NotificationList";
import "./NotificationBell.css";
import { IoIosNotificationsOutline, IoMdNotifications } from "react-icons/io";
import { useNotification } from "../../hooks/useNotification";

const NotificationBell: FC = () => {
  const {
    notifications,
    showNotifications,
    setShowNotifications,
    setShowNotificationsAnimation,
  } = useNotification();

  const toggleNotification = () => {
    if (showNotifications) {
      setShowNotificationsAnimation(false);
      setTimeout(() => {
        setShowNotifications(false);
      }, 300);
    } else {
      setShowNotifications(true);
    }
  };

  const hasUnreadNotifications = notifications.some(
    (notification) => !notification.isRead
  );

  useEffect(() => {
    notifications.length === 0 && setShowNotifications(false);
  }, [notifications, setShowNotifications]);

  return (
    <div>
      <div
        className={`notification-bell ${
          hasUnreadNotifications ? "has-notifications" : ""
        }`}
      >
        {showNotifications ? (
          <IoMdNotifications
            size={35}
            onClick={toggleNotification}
            className="notification-item-icon"
          />
        ) : (
          <IoIosNotificationsOutline
            size={35}
            onClick={toggleNotification}
            className="notification-item-icon"
          />
        )}
      </div>
      {showNotifications && <NotificationList />}
    </div>
  );
};

export default NotificationBell;
