import React, { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useToast } from "../../../contexts/Toast/ToastProvider";

interface FileInputProps {
  name: string;
  rules: any;
  defaultValue?: any;
}

const FileInput: FC<FileInputProps> = ({ name, rules, defaultValue = "" }) => {
  const { control, setValue } = useFormContext();

  const { addToast } = useToast();

  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    setValue(name, defaultValue || null);
    if (defaultValue) {
      setFileName(defaultValue.name || "");
    }
  }, [setValue, name, defaultValue]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const validFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
      "application/pdf",
    ];

    if (!validFileTypes.includes(file.type)) {
      addToast(
        "error",
        "Extension du fichier",
        "Veuillez sélectionner un fichier valide (Excel, CSV ou PDF)."
      );
      return;
    }

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      addToast(
        "error",
        "Taille du fichier",
        "La taille du fichier doit être inférieure à 5MB."
      );
      return;
    }

    setFileName(file.name);
    setValue(name, file);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, ref }, fieldState: { error } }) => (
        <div className="input-container">
          <div className="form-recipe-file-container">
            <label htmlFor={name} className="form-recipe-form-label">
              <AiOutlineCloudUpload size={30} className="upload-icon" />
              <span>
                Importer un fichier {name === "document" ? "PDF" : "Excel/CSV"}
              </span>
            </label>
            <input
              id={name}
              type="file"
              accept=".csv, .xlsx, .xls, .pdf"
              hidden
              onChange={(e) => {
                handleFileChange(e);
                onChange(e.target.files[0]);
              }}
              onBlur={onBlur}
              ref={ref}
            />
            {fileName && (
              <div className="file-preview">
                <p>Fichier chargé : {fileName}</p>
              </div>
            )}
          </div>
          {error && <p className="is-mandatory error-msg">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default FileInput;
