import { FC, useState } from "react";
import "../Reusables/FormMenuComponent.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  FaCarrot,
  FaDrumstickBite,
  FaEuroSign,
  FaIceCream,
  FaInfoCircle,
  FaRegClock,
  FaSeedling,
  FaTag,
} from "react-icons/fa";
import { MdChildCare } from "react-icons/md";
import useRestaurant from "../../../../hooks/useRestaurant";
import CustomMenuMultiSelect from "../Reusables/CustomMenuMultiSelect";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { createMenu } from "../../../../store/Menus/Requests";
import { ICreateMenu, IMenu } from "../../../../interface/Menus/IMenu";
import TimePicker from "../Reusables/TimePicker";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { useNotification } from "../../../../hooks/useNotification";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import menuValidationRules from "../../../../helpers/Validators/menuValidationRules";

const AddMenuComponent: FC = () => {
  const methods = useForm<IMenu>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { restaurant } = useRestaurant();

  const { addNotification } = useNotification();
  const { addToast } = useToast();

  const [errorMsg, setErrorMsg] = useState<string>("");

  const onSubmit = async (data: IMenu) => {
    const startersIds = data.recipes.starter?.map((recipe) => recipe.id) ?? [];
    const dishesIds = data.recipes.dish?.map((recipe) => recipe.id) ?? [];
    const accompanimentsIds =
      data.recipes.accompaniment?.map((recipe) => recipe.id) ?? [];
    const dessertsIds = data.recipes.dessert?.map((recipe) => recipe.id) ?? [];

    // Regrouper tous les IDs dans un seul tableau
    const allRecipeIds = [
      ...startersIds,
      ...dishesIds,
      ...accompanimentsIds,
      ...dessertsIds,
    ];

    // Compter les catégories avec au moins une recette
    const categoriesWithRecipes = [
      startersIds.length > 0,
      dishesIds.length > 0,
      accompanimentsIds.length > 0,
      dessertsIds.length > 0,
    ].filter((isFilled) => isFilled).length;

    // Vérifier qu'au moins deux catégories contiennent des recettes
    if (categoriesWithRecipes < 2) {
      addToast(
        "error",
        "Erreur de Validation",
        "Veuillez choisir une recette dans au moins deux catégories différentes."
      );
      setErrorMsg(
        "Veuillez choisir une recette dans au moins deux catégories différentes."
      );
      return; // Arrêter l'exécution si la condition n'est pas remplie
    }

    const menuData: ICreateMenu = {
      name: data.name,
      recipesIds: allRecipeIds,
      price: data.price,
      note: data.note,
      availability_start_time:
        data.availability_start_time &&
        data.availability_end_time &&
        data.availability_start_time,
      availability_end_time:
        data.availability_start_time &&
        data.availability_end_time &&
        data.availability_end_time,
      is_child_menu: data.is_child_menu,
    };

    handleAsyncResponse(
      dispatch,
      createMenu({ restaurantProfileId: restaurant.id, menuData }),
      {
        successTitle: `Menu ajouté`,
        successMessage: `Votre menu ${menuData.name} a été ajouté ! 🙌`,
        successDescription:
          "Vous avez ajouté le menu à votre restaurant: " +
          restaurant.name +
          " 🙌",
        navigateTo: "/pro/mes-menus",
      },
      { addToast, addNotification, navigate }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="menu-padding">
        <form
          className="menu-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Ajouter vos recettes à un menu</h1>
          <p>Remplissez ces champs pour ajouter une recette à un menu.</p>
          <div>
            <div className="menu-input-wrapper-container">
              <label htmlFor="name">
                Le menu <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="name"
                rules={menuValidationRules.name}
                placeholder="Ecrivez le nom du menu ici"
                id="name"
                icon={FaTag}
              />
            </div>
            {errorMsg && (
              <div className="is-mandatory" style={{ marginBottom: "10px" }}>
                {errorMsg}
              </div>
            )}
            <div className="form-recipe-grid">
              <div
                className="menu-input-wrapper-container"
                style={{ justifyContent: "flex-end" }}
              >
                <label htmlFor="starter">
                  L'entrée <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.starter"
                  options={restaurant.restaurantRecipe.filter(
                    (recipe) => recipe.category.toLowerCase() === "entrée"
                  )}
                  category="entrée"
                  icon={FaSeedling}
                />
              </div>
              <div
                className="menu-input-wrapper-container"
                style={{ justifyContent: "flex-end" }}
              >
                <label htmlFor="plat">
                  Le plat <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.dish"
                  options={restaurant.restaurantRecipe.filter(
                    (recipe) => recipe.category.toLowerCase() === "plat"
                  )}
                  category={"plat"}
                  icon={FaDrumstickBite}
                />
              </div>
            </div>
            <div className="form-recipe-grid">
              <div
                className="menu-input-wrapper-container"
                style={{ justifyContent: "flex-start" }}
              >
                <label htmlFor="accompaniment">
                  L'accompagnement <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.accompaniment"
                  options={restaurant.restaurantRecipe.filter(
                    (recipe) =>
                      recipe.category.toLowerCase() === "accompagnement"
                  )}
                  category={"accompagnement"}
                  icon={FaCarrot}
                />
              </div>
              <div
                className="menu-input-wrapper-container"
                style={{ justifyContent: "flex-start" }}
              >
                <label htmlFor="dessert">
                  Le dessert <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.dessert"
                  options={restaurant.restaurantRecipe.filter(
                    (recipe) => recipe.category.toLowerCase() === "dessert"
                  )}
                  category={"dessert"}
                  icon={FaIceCream}
                />
              </div>
              <div className="menu-input-wrapper-container">
                <label htmlFor="price">
                  Le prix <span className="is-mandatory">*</span>
                </label>
                <GenericInput
                  type="number"
                  name="price"
                  rules={menuValidationRules.price}
                  min={0}
                  step={0.01}
                  max={1000}
                  placeholder="Le prix de votre menu"
                  id="price"
                  icon={FaEuroSign}
                />
              </div>
              <div className="menu-input-wrapper-container">
                <label htmlFor="note">Ajouter une note</label>
                <GenericInput
                  name="note"
                  placeholder="Ajouter une information sur votre menu"
                  id="note"
                  icon={FaInfoCircle}
                  isFlex1={false}
                />
              </div>
              <div className="menu-input-wrapper-container menu-input-wrapper-container-mobile">
                <label htmlFor="price">Disponibilité</label>
                <div className="menu-form-input-field-container">
                  <FaRegClock className="menu-icon" />
                  <div className="menu-form-input-field-sentence">
                    <div>Ce menu est disponible entre&nbsp;</div>
                    <TimePicker
                      control={methods.control}
                      name="availability_start_time"
                    />
                    &nbsp;et&nbsp;
                    <TimePicker
                      control={methods.control}
                      name="availability_end_time"
                    />
                  </div>
                </div>
              </div>
              <div className="menu-input-wrapper-container">
                <label htmlFor="isChildMenu">Menu enfant</label>
                <div className="menu-form-input-field-container">
                  <MdChildCare className="menu-icon" />
                  <div className="menu-form-input-field-sentence">
                    <div>Ce menu est-il un menu enfant ?&nbsp;</div>
                    <Controller
                      name="is_child_menu"
                      control={methods.control}
                      defaultValue={false} // Default to unchecked
                      render={({ field: { onChange, value, ref } }) => (
                        <input
                          type="checkbox"
                          id="isChildMenu"
                          ref={ref}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          className="menu-form-input-checkbox"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-form-buttons-container">
            <div className="menu-form-actions-left"></div>
            <div className="menu-form-actions-right">
              <button
                type="button"
                className="menu-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="menu-form-next-button">
                Ajouter le menu
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
export default AddMenuComponent;
