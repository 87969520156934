import { useEffect, useState } from "react";
import "./../FormRecipeComponent.css";
import { IRestaurantRecipe } from "../../../../interface/IRestaurantRecipe";
import { FormProvider, useForm } from "react-hook-form";
import { FaTag, FaEuroSign, FaPencilAlt } from "react-icons/fa";
import useRestaurant from "../../../../hooks/useRestaurant";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { useNotification } from "../../../../hooks/useNotification";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import { addNewRestaurantRecipe } from "../../../../store/Restaurant/Recipes/Requests";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import GenericSelect from "../../../Reusables/InputField/GenericSelect";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import AllergensSelect from "../../../Reusables/InputField/AllergensSelect";
import categoryOptionsModel from "../../../../models/categoryOptionsModel";
import { RecipeValidationRules } from "../../../../helpers/Validators/RecipeValidationRules";

const AddRecipeComponent = () => {
  const methods = useForm<IRestaurantRecipe>();
  const validationRules = RecipeValidationRules(methods.getValues);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { restaurant } = useRestaurant();

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const description = methods.watch("description");

  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    const words = description ? description.match(/\b\w+\b/g) : [];
    setWordCount(words ? words.length : 0);
  }, [description]);

  const onSubmit = async (data: IRestaurantRecipe) => {
    data.archived = true;

    if (!data.price) {
      data.price = null;
    }

    const formData = new FormData();

    if (restaurant.id)
      formData.append("restaurantProfileId", restaurant.id.toString());
    if (data.category) formData.append("category", data.category);
    if (data.name) formData.append("name", data.name);
    if (data.description) formData.append("description", data.description);
    if (data.allergens && data.allergens.length > 0)
      formData.append("allergens", JSON.stringify(data.allergens));
    if (data.price) formData.append("price", data.price);
    if (data.archived) formData.append("archived", String(data.archived));

    if (data.image) {
      formData.append("image", data.image);
    }

    handleAsyncResponse(
      dispatch,
      addNewRestaurantRecipe(formData),
      {
        successTitle: `Recette ajoutée`,
        successMessage: `Votre recette ${formData.get(
          "name"
        )} a été ajouté ! 🙌`,
        successDescription:
          "Vous avez ajouté la recette à votre restaurant: " +
          restaurant.name +
          " 🙌",
        navigateTo: "/pro/mes-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Les données de votre recette</h1>
          <p>Remplissez ces champs pour ajouter une nouvelle recette.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="image">L'image</label>
              <ImageInput name="image" rules={validationRules.image} />
            </div>
            <div className="form-recipe-grid">
              <div
                className="recipe-input-wrapper-container"
                style={{ justifyContent: "flex-end" }}
              >
                <label htmlFor="name">
                  Le nom <span className="is-mandatory">*</span>
                </label>
                <GenericInput
                  name="name"
                  rules={validationRules.name}
                  placeholder="Ecrivez le nom de votre recette ici"
                  id="name"
                  icon={FaTag}
                />
              </div>
              <div
                className="recipe-input-wrapper-container"
                style={{ justifyContent: "flex-end" }}
              >
                <label htmlFor="name">
                  La catégorie <span className="is-mandatory">*</span>
                </label>
                <GenericSelect
                  name="category"
                  rules={validationRules.category}
                  options={categoryOptionsModel}
                  category={"catégorie"}
                />
              </div>
            </div>
            <div className="form-recipe-grid">
              <div
                className="recipe-input-wrapper-container"
                style={{ justifyContent: "flex-start" }}
              >
                <label htmlFor="description">
                  La description <span className="is-mandatory">*</span>
                </label>

                <GenericInput
                  name="description"
                  rules={validationRules.description}
                  placeholder="Ecrivez la description de votre recette ici"
                  id="name"
                  icon={FaPencilAlt}
                />

                <div className="recipe-form-input-words">
                  {wordCount} / 50 mots utilisés
                </div>
              </div>

              <div
                className="recipe-input-wrapper-container"
                style={{ justifyContent: "flex-start" }}
              >
                <label htmlFor="price">Le prix</label>

                <GenericInput
                  name="price"
                  rules={validationRules.price}
                  min={0}
                  step={0.01}
                  max={1000}
                  placeholder="Le prix de votre recette"
                  id="price"
                  icon={FaEuroSign}
                  isFlex1={false}
                />
              </div>
            </div>

            <div className="recipe-input-wrapper-container">
              <label htmlFor="price">
                Les allergènes <span className="is-mandatory">*</span>
              </label>
              <AllergensSelect
                name="allergens"
                rules={validationRules.allergens}
              />
            </div>
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left"></div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Ajouter la recette
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default AddRecipeComponent;
