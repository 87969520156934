export const restaurantValidationRules = {
  image: {
    validate: {
      isImage: (file) => {
        if (!file) return true;
        const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
        return supportedFormats.includes(file.type || file.mimeType)
          ? true
          : "Veuillez sélectionner une image valide (JPEG, JPG, PNG).";
      },
      isUnderSizeLimit: (file) => {
        if (!file) return true;
        const maxSizeInBytes = 2097152; // 2MB
        return file.size <= maxSizeInBytes
          ? true
          : "L'image doit être moins de 2MB.";
      },
    },
  },
  name: {
    required: "Le nom du restaurant est requis",
    pattern: {
      value: /^[a-zA-Z0-9\s'.,-éèêëàâäîïôöûüùçÉÈÊËÀÂÄÎÏÔÖÛÜÙÇ]*$/,
      message:
        "Le nom du restaurant ne peut contenir que des lettres (y compris accentuées), des chiffres, et certains caractères spéciaux (espaces, apostrophes, points, virgules, et tirets).",
    },
  },
  email: {
    required: "L'email est requis",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "L'email doit être une adresse email valide",
    },
  },
  address: {
    required: "L'adresse du restaurant est requise",
    pattern: {
      value: /^[a-zA-Z0-9\s'.,-éèêëàâäîïôöûüùçÉÈÊËÀÂÄÎÏÔÖÛÜÙÇ]*$/,
      message:
        "L'adresse du restaurant ne peut contenir que des lettres (y compris accentuées), des chiffres, et certains caractères spéciaux (espaces, apostrophes, points, virgules, et tirets).",
    },
  },
  tel: {
    required: "Le numéro de téléphone fixe est requis",
    pattern: {
      value: /^(\+33|0)[1-5]\d{8}$/,
      message:
        "Le numéro de téléphone fixe doit être un numéro valide en France (+33 ou 0 suivi de l'indicatif régional 1 à 5 et de 8 chiffres).",
    },
  },
  restaurantType: {},
  restaurationType: {
    validate: (value: string) =>
      typeof value === "string" ||
      "Le type de restauration doit être une chaîne de caractères",
  },
  description: {
    validate: (value: string) =>
      typeof value === "string" ||
      "La description doit être une chaîne de caractères",
    maxLength: {
      value: 500,
      message: "La description ne doit pas dépasser 500 caractères",
    },
  },
};

export default restaurantValidationRules;
