import { FC, useEffect, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import {
  FaTag,
  FaSeedling,
  FaDrumstickBite,
  FaCarrot,
  FaIceCream,
  FaEuroSign,
  FaInfoCircle,
  FaRegClock,
} from "react-icons/fa";
import { MdChildCare } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { parseTime } from "../../../../helpers/getDate";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import { IRestaurantRecipe } from "../../../../interface/IRestaurantRecipe";
import {
  IMenu,
  IAvailabilityTime,
  ICreateMenu,
} from "../../../../interface/Menus/IMenu";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import CustomMenuMultiSelect from "../../../Pro/Menu/Reusables/CustomMenuMultiSelect";
import TimePicker from "../../../Pro/Menu/Reusables/TimePicker";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import { selectAdminMenu } from "../../../../store/Admin/Menus/Menus";
import { selectAdminRestaurant } from "../../../../store/Admin/Restaurants/AdminRestaurants";
import { updateMenuForAdmin } from "../../../../store/Admin/Menus/Requests";
import { selectAdminRecipes } from "../../../../store/Admin/Recipes/Recipes";
import { menuValidationRules } from "../../../../helpers/Validators/menuValidationRules";

const AdminEditMenu: FC = () => {
  const methods = useForm<IMenu>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const restaurant = useSelector(selectAdminRestaurant);
  const menu = useSelector(selectAdminMenu);
  const recipes = useSelector(selectAdminRecipes);

  const { addNotification } = useNotification();
  const { addToast } = useToast();

  const emptyTimeValue = { hour: "", minute: "" };

  const [startTime, setStartTime] = useState<IAvailabilityTime>(emptyTimeValue);
  const [endTime, setEndTime] = useState<IAvailabilityTime>(emptyTimeValue);

  const [availableRecipes, setAvailableRecipes] = useState<IRestaurantRecipe[]>(
    []
  );

  const [errorMsg, setErrorMsg] = useState<string>("");

  const areTimesDifferent = (
    time1: IAvailabilityTime,
    time2: IAvailabilityTime
  ) => {
    return time1.hour !== time2.hour || time1.minute !== time2.minute;
  };

  useEffect(() => {
    const existingIds = new Set();
    Object.values(menu.recipes).forEach((category) => {
      category?.forEach((recipe) => {
        existingIds.add(recipe.id);
      });
    });

    const filteredRecipes = recipes.filter(
      (recipe) => !existingIds.has(recipe.id)
    );
    setAvailableRecipes(filteredRecipes);
  }, [menu, recipes]);

  useEffect(() => {
    const fetchAvailabilityTimes = () => {
      const newStartTime = parseTime(menu.availability_start_time as string);
      const newEndTime = parseTime(menu.availability_end_time as string);

      if (areTimesDifferent(newStartTime, startTime))
        setStartTime(newStartTime);
      if (areTimesDifferent(newEndTime, endTime)) setEndTime(newEndTime);
    };

    if (menu) {
      fetchAvailabilityTimes();
    }
  }, [dispatch, menu, restaurant.id]);

  const onSubmit = async (data: IMenu) => {
    const menuChanges: Partial<ICreateMenu> = {};

    // Ajouter des conditions pour s'assurer que tous les champs nécessaires sont présents
    if (menu.name !== data.name) menuChanges.name = data.name;
    if (menu.note !== data.note) menuChanges.note = data.note;
    if (menu.price !== data.price) menuChanges.price = data.price;
    if (menu.is_child_menu !== data.is_child_menu)
      menuChanges.is_child_menu = data.is_child_menu;

    // Gestion du temps de disponibilité
    const newStartTime = JSON.stringify(data.availability_start_time);
    const oldStartTime = JSON.stringify(menu.availability_start_time);
    if (newStartTime !== oldStartTime)
      menuChanges.availability_start_time = newStartTime;

    const newEndTime = JSON.stringify(data.availability_end_time);
    const oldEndTime = JSON.stringify(menu.availability_end_time);
    if (newEndTime !== oldEndTime)
      menuChanges.availability_end_time = newEndTime;

    // Gestion des recettes
    const newRecipeIds = [
      ...(data.recipes.starter?.map((recipe) => recipe.id) ?? []),
      ...(data.recipes.dish?.map((recipe) => recipe.id) ?? []),
      ...(data.recipes.accompaniment?.map((recipe) => recipe.id) ?? []),
      ...(data.recipes.dessert?.map((recipe) => recipe.id) ?? []),
    ];

    const oldRecipeIds = [
      ...(menu.recipes.starter?.map((recipe) => recipe.id) ?? []),
      ...(menu.recipes.dish?.map((recipe) => recipe.id) ?? []),
      ...(menu.recipes.accompaniment?.map((recipe) => recipe.id) ?? []),
      ...(menu.recipes.dessert?.map((recipe) => recipe.id) ?? []),
    ];

    if (
      JSON.stringify(newRecipeIds.sort()) !==
      JSON.stringify(oldRecipeIds.sort())
    ) {
      menuChanges.recipesIds = newRecipeIds;
    }

    if (Object.keys(menuChanges).length > 0) {
      const menuData: ICreateMenu = {
        name: menuChanges.name || menu.name, // Assurer que les champs obligatoires sont toujours présents
        recipesIds: menuChanges.recipesIds || oldRecipeIds,
        price: menuChanges.price ?? menu.price,
        note: menuChanges.note ?? menu.note,
        availability_start_time:
          menuChanges.availability_start_time || oldStartTime,
        availability_end_time: menuChanges.availability_end_time || oldEndTime,
        is_child_menu: menuChanges.is_child_menu ?? menu.is_child_menu,
      };

      handleAsyncResponse(
        dispatch,
        updateMenuForAdmin({
          menuId: menu.id,
          menuData,
        }),
        {
          successTitle: `Menu modifié`,
          successMessage: `Le menu ${menuData.name} a été modifié ! 🙌`,
          successDescription:
            "Le menu du restaurant: " + restaurant.name + " a été modifié 🙌",
          navigateTo: "/admin/tous-les-menus",
        },
        { addToast, addNotification, navigate }
      );
    } else {
      addToast(
        "info",
        "Aucun changement détecté",
        "Aucune modification à mettre à jour."
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="menu-padding">
        <form
          className="menu-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Modifier les recettes du menu</h1>
          <p>Remplissez ces champs pour modifier un menu à la carte.</p>
          <div>
            <div className="menu-input-wrapper-container">
              <label htmlFor="starter">
                Le menu <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="name"
                rules={menuValidationRules.name}
                placeholder="Ecrivez le nom du menu ici"
                id="name"
                defaultValue={menu.name}
                icon={FaTag}
              />
            </div>
            {errorMsg && (
              <div className="is-mandatory" style={{ marginBottom: "10px" }}>
                {errorMsg}
              </div>
            )}

            <div className="form-recipe-grid">
              <div className="menu-input-wrapper-container">
                <label htmlFor="starter">
                  L'entrée <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.starter"
                  defaultValue={menu.recipes.starter}
                  options={availableRecipes.filter(
                    (recipe) => recipe.category === "Entrée"
                  )}
                  category="entrée"
                  icon={FaSeedling}
                />
              </div>
              <div className="menu-input-wrapper-container">
                <label htmlFor="plat">
                  Le plat <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.dish"
                  defaultValue={menu.recipes.dish}
                  options={availableRecipes.filter(
                    (recipe) => recipe.category === "Plat"
                  )}
                  category={"plat"}
                  icon={FaDrumstickBite}
                />
              </div>
            </div>
            <div className="form-recipe-grid">
              <div className="menu-input-wrapper-container">
                <label htmlFor="accompaniment">
                  L'accompagnement <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.accompaniment"
                  defaultValue={menu.recipes.accompaniment}
                  options={availableRecipes.filter(
                    (recipe) => recipe.category === "Accompagnements"
                  )}
                  category={"accompagnement"}
                  icon={FaCarrot}
                />
              </div>
              <div className="menu-input-wrapper-container">
                <label htmlFor="dessert">
                  Le dessert <span className="is-mandatory">*</span>
                </label>
                <CustomMenuMultiSelect
                  name="recipes.dessert"
                  defaultValue={menu.recipes.dessert}
                  options={availableRecipes.filter(
                    (recipe) => recipe.category === "Dessert"
                  )}
                  category={"dessert"}
                  icon={FaIceCream}
                />
              </div>
              <div className="menu-input-wrapper-container">
                <label htmlFor="price">
                  Le prix <span className="is-mandatory">*</span>
                </label>
                <GenericInput
                  type="number"
                  name="price"
                  rules={menuValidationRules.price}
                  min={0}
                  step={0.01}
                  max={1000}
                  placeholder="Le prix de votre menu"
                  defaultValue={menu.price}
                  id="price"
                  icon={FaEuroSign}
                />
              </div>
              <div className="menu-input-wrapper-container">
                <label htmlFor="note">Ajouter une note</label>
                <GenericInput
                  name="note"
                  placeholder="Ajouter une information sur votre menu"
                  id="note"
                  defaultValue={menu.note}
                  icon={FaInfoCircle}
                />
              </div>
              <div className="menu-input-wrapper-container menu-input-wrapper-container-mobile">
                <label htmlFor="price">Disponibilité</label>
                <div className="menu-form-input-field-container">
                  <FaRegClock className="menu-icon" />
                  <div className="menu-form-input-field-sentence">
                    <div>Ce menu est disponible entre&nbsp;</div>
                    <TimePicker
                      control={methods.control}
                      setValue={methods.setValue}
                      name="availability_start_time"
                      defaultValue={startTime}
                    />
                    &nbsp;et&nbsp;
                    <TimePicker
                      control={methods.control}
                      name="availability_end_time"
                      setValue={methods.setValue}
                      defaultValue={endTime}
                    />
                  </div>
                </div>
              </div>
              <div className="menu-input-wrapper-container menu-input-wrapper-container-mobile">
                <label htmlFor="price">Menu enfant</label>
                <div className="menu-form-input-field-container">
                  <MdChildCare className="menu-icon" />
                  <div className="menu-form-input-field-sentence">
                    <div>Ce menu est-il un menu enfant ?&nbsp;</div>
                    <Controller
                      name="is_child_menu"
                      control={methods.control}
                      defaultValue={menu.is_child_menu}
                      render={({ field: { onChange, value, ref } }) => (
                        <input
                          type="checkbox"
                          id="isChildMenu"
                          ref={ref}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          className="menu-form-input-checkbox"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-form-buttons-container">
            <div className="menu-form-actions-left"></div>
            <div className="menu-form-actions-right">
              <button
                type="button"
                className="menu-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="menu-form-next-button">
                Modifier le menu
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
export default AdminEditMenu;
