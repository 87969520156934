import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import useRestaurant from "../../../../hooks/useRestaurant";
import "./AllergensChartWidget.css";
import Tabs from "../../../Tabs/Tabs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AllergensChartWidget = () => {
  const { restaurant } = useRestaurant();
  const [chartData, setChartData] = useState<any>({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (restaurant && restaurant.restaurantRecipe) {
      // Préparer les données pour le graphique
      const allergenCounts = restaurant.restaurantRecipe.reduce(
        (acc, recipe) => {
          recipe.allergens.forEach((allergen) => {
            const allergenName = capitalizeFirstLetter(allergen.name); // Mettre en majuscule
            acc[allergenName] = (acc[allergenName] || 0) + 1;
          });
          return acc;
        },
        {}
      );

      // Configuration du graphique
      const data = {
        labels: Object.keys(allergenCounts),
        datasets: [
          {
            label: "Nombre d'occurrences d'allergènes",
            data: Object.values(allergenCounts),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      };

      setChartData(data);
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [restaurant]); // Dépendances du useEffect

  if (!chartData.labels) {
    return <div>Chargement du graphique...</div>;
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  return (
    <div className="chart-container">
      <Tabs restaurant={restaurant} canAddInRestaurantPage={false} />
      <div className="chart-content-container">
        <div className="chart-title-container">
          <div className="chart-title">
            Classement des allergènes de {restaurant.name}&nbsp; toutes recettes
            mélangées.
          </div>
        </div>
        <Bar
          data={chartData}
          options={options}
          style={{ paddingBlock: "2rem" }}
        />
      </div>
    </div>
  );
};

export default AllergensChartWidget;
