import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IRestaurantProfile,
  RestaurantState,
} from "../../../interface/IRestaurantProfile";
import {
  addRestaurantForAdmin,
  deleteRestaurantForAdmin,
  editRestaurantForAdmin,
  fetchAllRestaurantsForAdmin,
} from "./Requests";
import { deleteUserForAdmin } from "../Users/Requests";
import { IUser } from "../../../interface/IUser";

export const adminRestaurants = createSlice({
  name: "adminRestaurants",
  initialState: {
    adminRestaurants: <IRestaurantProfile[]>[],
    adminRestaurant: <IRestaurantProfile>{},
    restaurantId: <number>null,
    done: false,
    loading: false,
    errors: {} as any,
  },
  reducers: {
    addAdminRestaurantToStore(state: any, action) {
      state.adminRestaurant = action.payload;
    },
    addRestaurantIdToStore(state, action) {
      state.restaurantId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRestaurantsForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(fetchAllRestaurantsForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.adminRestaurants = action.payload;
        state.errors = {};
      })
      .addCase(fetchAllRestaurantsForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      })

      .addCase(addRestaurantForAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addRestaurantForAdmin.fulfilled,
        (state, action: PayloadAction<IRestaurantProfile>) => {
          state.adminRestaurant = action.payload;
          state.adminRestaurants.push(action.payload);
          state.loading = false;
          state.errors = [];
        }
      )
      .addCase(addRestaurantForAdmin.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(editRestaurantForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(editRestaurantForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = {};
        state.adminRestaurant = action.payload;
        const restaurantIndex = state.adminRestaurants.findIndex(
          (restaurant) => restaurant.id === action.payload.id
        );
        state.adminRestaurants[restaurantIndex] = action.payload;
      })
      .addCase(editRestaurantForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      })

      .addCase(deleteRestaurantForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(deleteRestaurantForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = {};
        state.adminRestaurants = state.adminRestaurants.filter(
          (restaurant) => restaurant.id !== action.payload.id
        );
      })
      .addCase(deleteRestaurantForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      })
      .addCase(deleteUserForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(deleteUserForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        const remainingRestaurants = state.adminRestaurants.filter(
          (restaurant: IRestaurantProfile) =>
            restaurant.userId !== action.payload.userId
        );
        state.adminRestaurants = remainingRestaurants;

        state.restaurantId = null;

        state.errors = {};
      })
      .addCase(deleteUserForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      });
  },
});

export const selectAdminRestaurants = (state: {
  adminRestaurants: { adminRestaurants: IRestaurantProfile[] };
}) => state.adminRestaurants.adminRestaurants;

export const selectAdminRestaurant = (state: {
  adminRestaurants: { adminRestaurant: IRestaurantProfile };
}) => state.adminRestaurants.adminRestaurant;

export const selectAdminRestaurantId = (state) =>
  state.adminRestaurants.restaurantId;

export const selectLoading = (state: {
  adminRestaurants: { loading: boolean };
}) => state.adminRestaurants.loading;
export const selectErrors = (state: { adminRestaurants: { errors: any } }) =>
  state.adminRestaurants.errors;

export const { addAdminRestaurantToStore, addRestaurantIdToStore } =
  adminRestaurants.actions;

export default adminRestaurants.reducer;
