import "./AdminUsersTable.css";
import { useEffect, useRef, useState } from "react";
import {
  MdAutorenew,
  MdMoreHoriz,
  MdOutlineModeEdit,
  MdRemove,
} from "react-icons/md";
import {
  FaCheck,
  FaFilePdf,
  FaRegTrashAlt,
  FaToggleOff,
  FaToggleOn,
} from "react-icons/fa";
import { formatDate } from "../../../../helpers/getDate";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { IUser } from "../../../../interface/IUser";
import { IRestaurantProfile } from "../../../../interface/IRestaurantProfile";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { useNavigate } from "react-router-dom";
import { addAdminUserToStore } from "../../../../store/Admin/Users/AdminUsers";
import {
  deleteUserForAdmin,
  toggleIsActivatedForAdmin,
} from "../../../../store/Admin/Users/Requests";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { useNotification } from "../../../../hooks/useNotification";
import { addAdminRestaurantToStore } from "../../../../store/Admin/Restaurants/AdminRestaurants";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import usePagination from "../../../../hooks/usePagination";
import PaginationButtons from "../../Pagination/PaginationComponent";
import { fetchAllDocumentsForAdmin } from "../../../../store/Admin/Documents/Requests";
import { setUserIdToSore } from "../../../../store/Admin/Documents/Documents";

interface IUserForAdmin extends IUser {
  restaurantProfile?: IRestaurantProfile;
}

interface UsersProps {
  users: IUserForAdmin[];
}

const AdminUsersTable: React.FC<UsersProps> = ({ users = [] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const itemsPerPage = 5; // Adjust as needed for testing
  const { currentPage, totalPages, next, prev } = usePagination(
    users.filter((user) => user.isProfessionnal).length,
    itemsPerPage
  );
  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const [activePopupId, setActivePopupId] = useState<number | null>(null);
  const [activePreviewPopupId, setActivePreviewPopupId] = useState<
    number | null
  >(null);
  const [checked, setChecked] = useState<boolean>(false);

  const popUpRef = useRef(null);
  const reviewPopUpRef = useRef(null);

  const togglePopUp = (e, user: IUser) => {
    e.stopPropagation();
    const newActiveId = activePopupId === user.id ? null : user.id;
    setActivePopupId(newActiveId);

    if (newActiveId !== null && user.isActivated !== checked) {
      setChecked(user.isActivated);
    }
  };

  const togglePreviewPopUp = (e, userId: number) => {
    e.stopPropagation();
    setActivePreviewPopupId(activePreviewPopupId === userId ? null : userId);
  };

  const handleEditUser = (user: IUserForAdmin) => {
    const { restaurantProfile, ...restOfUser } = user;
    dispatch(addAdminUserToStore(restOfUser));
    setActivePopupId(null);
    navigate("/admin/modifier-un-utilisateur");
  };

  const handleDeleteUser = (user: IUserForAdmin) => {
    handleAsyncResponse(
      dispatch,
      deleteUserForAdmin(user.id),
      {
        successTitle: "Utilisateur supprimé",
        successMessage: `L'utilisateur ${
          user.firstName + " " + user.lastName
        } a été supprimé ! 🙌`,
        successDescription:
          "Vous avez supprimé l'utilisateur : " +
          user.firstName +
          " " +
          user.lastName +
          " de SafeEat",
        navigateTo: "/admin/tous-les-utilisateurs",
      },
      { addToast, addNotification, navigate }
    );
    setActivePopupId(null);
  };

  const handleViewDocuments = (user: IUser) => {
    dispatch(fetchAllDocumentsForAdmin(user.id));
    dispatch(setUserIdToSore(user.id));
    setActivePopupId(null);
    navigate("/admin/tous-les-documents");
  };

  const handleEditRestaurant = (restaurant: IRestaurantProfile) => {
    setActivePopupId(null);
    dispatch(addAdminRestaurantToStore(restaurant));
    navigate("/admin/modifier-un-restaurant");
  };

  const handleToggleActivateAccount = async (user: IUser) => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    handleAsyncResponse(
      dispatch,
      toggleIsActivatedForAdmin({
        id: user.id,
        isActivated: newCheckedValue,
      }),
      {
        successTitle: `Compte ${
          newCheckedValue ? "activé" : "désactivé"
        } de la plateforme SafeEat`,
        successMessage: `Le compte ${user.firstName + " " + user.lastName} ${
          newCheckedValue ? "a été activé" : "a été désactivé"
        } ! 🙌`,
        successDescription: `Le compte ${
          user.firstName + " " + user.lastName
        } ${newCheckedValue ? "a été activé" : "a été désactivé"} ! 🙌`,
        navigateTo: "/admin/tous-les-utilisateurs",
      },
      { addToast, addNotification, navigate }
    );
  };

  useOutsideAlerter(popUpRef, () => setActivePopupId(null));
  useOutsideAlerter(reviewPopUpRef, () => setActivePreviewPopupId(null));

  const mapOverAddress = (address: string) => {
    return address
      .split(" ")
      .map((a) => a + "+")
      .join("")
      .slice(0, -1);
  };

  useEffect(() => {
    if (activePopupId === null) {
      setChecked(false);
    }
  }, [activePopupId]);

  // Filter, sort, and then paginate the users
  const filteredAndSortedUsers = users
    .filter((user) => user.isProfessionnal)
    .sort((a, b) => a.id - b.id);

  const paginatedUsers = filteredAndSortedUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getStatusAndIcon = (documents) => {
    if (!documents || documents.length === 0) {
      return { icon: <MdRemove size={25} color="#757575" />, className: "" };
    }

    const status = documents.reduce((acc, document) => {
      if (acc === "Validé" && document.status !== "Validé") {
        return "En cours";
      }
      return acc;
    }, "Validé");

    switch (status) {
      case "En cours":
        return {
          icon: <MdAutorenew size={25} color="#F5A623" />,
          className: "inprogress",
        };
      case "Validé":
        return {
          icon: <FaCheck size={25} color="#4CAF50" />,
          className: "validate",
        };
      default:
        return { icon: null, className: "" };
    }
  };

  return (
    <div className="admin-restaurant-table-container">
      <div className="admin-restaurant-table-content-container">
        <table>
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Nom et prénom</th>
              <th>Email</th>
              <th>Téléphone</th>
              <th>Restaurant</th>
              <th>Documents</th>
              <th>Date d'inscription</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedUsers.length > 0 ? (
              paginatedUsers.map((user) => {
                const { icon, className } = getStatusAndIcon(user.documents);
                return (
                  <tr key={user.id}>
                    <td>
                      {user.avatar && user.avatar.breakpoints ? (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}uploads/${user.avatar.breakpoints.thumbnail.name}`}
                          alt={`${user.firstName} et ${user.lastName} logo`}
                          style={{ width: "50px", height: "50px" }}
                        />
                      ) : (
                        <div className="admin-restaurant-table-no-logo">
                          {user.firstName[0].toUpperCase() +
                            " " +
                            user.lastName[0].toUpperCase()}
                        </div>
                      )}
                    </td>
                    <td>{user.firstName + " " + user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.tel || "N/A"}</td>
                    <td className="relative">
                      <button
                        className="admin-restaurant-table-preview-button"
                        style={{
                          borderColor: !user.restaurantProfile && "#bac1cc",
                          color: !user.restaurantProfile && "#bac1cc",
                          cursor: !user.restaurantProfile && "not-allowed",
                        }}
                        disabled={!user.restaurantProfile}
                        onMouseDown={(e) => togglePreviewPopUp(e, user.id)}
                      >
                        {user.restaurantProfile
                          ? user.restaurantProfile.name
                          : "Aucun restaurant"}
                      </button>
                      {activePreviewPopupId === user.id && (
                        <div
                          className="admin-restaurant-table-preview-popup-container"
                          ref={reviewPopUpRef}
                        >
                          <div className="admin-restaurant-table-preview-text">
                            {user.restaurantProfile.name}
                          </div>
                          <div className="admin-restaurant-table-preview-text">
                            <a
                              href={`https://www.google.fr/maps/place/${mapOverAddress(
                                user.restaurantProfile.address
                              )}`}
                              target="_blank"
                            >
                              {user.restaurantProfile.address}
                            </a>
                          </div>
                          <div className="admin-restaurant-table-preview-text">
                            <a href={`mailto:${user.restaurantProfile.email}`}>
                              {user.restaurantProfile.email}
                            </a>
                          </div>
                          <div className="admin-restaurant-table-preview-text">
                            <a href={`tel:${user.restaurantProfile.tel}`}>
                              {user.restaurantProfile.tel}
                            </a>
                          </div>
                          <button
                            className="admin-restaurant-table-preview-button"
                            onClick={() =>
                              handleEditRestaurant(user.restaurantProfile)
                            }
                          >
                            <MdOutlineModeEdit />
                            Modifier
                          </button>
                        </div>
                      )}
                    </td>
                    <td>
                      <div
                        className={`admin-users-table-documents-status-container ${className}`}
                      >
                        {icon}
                      </div>
                    </td>
                    <td>{formatDate(user.createdAt)}</td>
                    <td className="relative">
                      <button
                        className="admin-restaurant-table-more-btn"
                        onMouseDown={(e) => togglePopUp(e, user)}
                      >
                        <MdMoreHoriz size={22} />
                      </button>
                      {activePopupId === user.id && (
                        <div
                          className="admin-restaurant-table-popup-container"
                          ref={popUpRef}
                        >
                          <div
                            className="admin-restaurant-table-popup-documents"
                            onClick={() => handleViewDocuments(user)}
                          >
                            <FaFilePdf className="admin-restaurant-table-popup-documents-icon" />
                            <span className="admin-restaurant-table-popup-documents-icon">
                              Documents
                            </span>
                          </div>
                          <div
                            className="admin-restaurant-table-popup-toggle"
                            onClick={() => handleToggleActivateAccount(user)}
                          >
                            {checked ? (
                              <FaToggleOn className="admin-restaurant-table-popup-toggle-icon" />
                            ) : (
                              <FaToggleOff className="admin-restaurant-table-popup-toggle-icon" />
                            )}
                            <span className="admin-restaurant-table-popup-toggle-icon">
                              {checked ? "Désactiver" : "Activer"}
                            </span>
                          </div>
                          <div
                            className="admin-restaurant-table-popup-edit"
                            onClick={() => handleEditUser(user)}
                          >
                            <MdOutlineModeEdit className="admin-restaurant-table-popup-edit-icon" />
                            <span className="admin-restaurant-table-popup-edit-icon">
                              Modifier
                            </span>
                          </div>
                          <div className="admin-restaurants-table-separator-container">
                            <div className="admin-restaurants-table-separator-content"></div>
                          </div>
                          <div
                            className="admin-restaurant-table-popup-delete"
                            onClick={() => handleDeleteUser(user)}
                          >
                            <FaRegTrashAlt className="admin-restaurant-table-popup-delete-icon" />
                            <span className="admin-restaurant-table-popup-delete-icon">
                              Supprimer
                            </span>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8}>Aucun utilisateur trouvé</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="admin-restaurant-table-bottom">
        <div className="admin-restaurant-table-restaurants-number">
          Nombre total d'utilisateurs sur la plateforme :{" "}
          {users.filter((user) => user.isProfessionnal).length}
        </div>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={prev}
          onNext={next}
        />
      </div>
    </div>
  );
};

export default AdminUsersTable;
