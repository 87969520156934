import { FC, useEffect, useState } from "react";
import "./RecipeCounterWidget.css";
import { useNavigate } from "react-router";
import useRestaurant from "../../../../hooks/useRestaurant";

const RecipeCounterWidget: FC = () => {
  const navigate = useNavigate();

  const { restaurant } = useRestaurant();
  const [lastUpdatedDate, setLastUpdatedDate] = useState("Chargement...");

  useEffect(() => {
    const activeRestaurant = restaurant;

    if (
      activeRestaurant?.restaurantRecipe &&
      activeRestaurant.restaurantRecipe.length > 0
    ) {
      const latestRecipe = activeRestaurant.restaurantRecipe.reduce(
        (latest, recipe) => {
          // Convertit en timestamp pour la comparaison
          const latestDate = latest ? Date.parse(latest.updated_at) : 0;
          const currentDate = Date.parse(recipe.updated_at);
          return currentDate > latestDate ? recipe : latest;
        },
        null
      );

      if (latestRecipe && !isNaN(Date.parse(latestRecipe.updated_at))) {
        setLastUpdatedDate(
          new Date(latestRecipe.updated_at).toLocaleDateString()
        );
      } else {
        setLastUpdatedDate("Aucune date valide n'est disponible");
      }
    } else {
      setLastUpdatedDate("Aucune recette disponible");
    }
  }, [restaurant]);

  return (
    <div className={"recipe-counter-widget-card"}>
      <div className="top">
        <div className="heading-top">Nombres de recettes</div>
        <div className="value-top">
          {restaurant && restaurant.restaurantRecipe?.length}
        </div>
      </div>
      <div className="middle">
        <div className="update-date">
          Dernière recette mise à jour le&nbsp;
          <span className="color-value">{lastUpdatedDate}</span>
        </div>
      </div>
    </div>
  );
};

export default RecipeCounterWidget;
