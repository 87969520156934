import { FC, useEffect, useState } from "react";
import "./MenuCounterWidget.css";
import { useNavigate } from "react-router";
import useRestaurant from "../../../../hooks/useRestaurant";

const MenuCounterWidget: FC = () => {
  const navigate = useNavigate();

  const { menus } = useRestaurant();
  const [lastUpdatedDate, setLastUpdatedDate] = useState("Chargement...");

  useEffect(() => {
    if (menus && menus.length !== 0) {
      const latestMenu = menus.reduce((latest, menu) => {
        // Convertit en timestamp pour la comparaison
        const latestDate = latest ? Date.parse(latest.updated_at) : 0;
        const currentDate = Date.parse(menu.updated_at);
        return currentDate > latestDate ? menu : latest;
      }, null);

      if (latestMenu && !isNaN(Date.parse(latestMenu.updated_at))) {
        setLastUpdatedDate(
          new Date(latestMenu.updated_at).toLocaleDateString()
        );
      } else {
        setLastUpdatedDate("Aucune date valide n'est disponible");
      }
    } else {
      setLastUpdatedDate("Aucun menu disponible");
    }
  }, [menus]);

  return (
    <div className={"menu-counter-widget-card"}>
      <div className="top">
        <div className="heading-top">Nombres de menus</div>
        <div className="value-top">{menus && menus.length}</div>
      </div>
      <div className="middle">
        <div className="update-date">
          Dernier menu mis à jour le&nbsp;
          <span className="color-value">{lastUpdatedDate}</span>
        </div>
      </div>
    </div>
  );
};

export default MenuCounterWidget;
