import { useDispatch, useSelector } from "react-redux";
import {
  removeRecipeFromUserChoices,
  selectPublicUserChoices,
} from "../../../../../../store/PublicRestaurant/PublicRestaurant";
import { useState, useRef } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io"; // Import des icônes
import "./PublicRecipeBasketModal.css";
import {
  IRestaurantRecipeCategories,
  IUserChoices,
} from "../../../../../../interface/Public/IPublic";
import { getTextColor } from "../../../../../../helpers/color";
import { FaTimesCircle } from "react-icons/fa";
import colors from "../../../../../constants/colors";
import { AppDispatch } from "../../../../../../interface/Types/AppDispatch";
import { IRestaurantRecipe } from "../../../../../../interface/IRestaurantRecipe";

const PublicRecipeBasketModal = ({ primaryColor, secondaryColor }) => {
  const dispatch = useDispatch<AppDispatch>();

  const userChoices: IUserChoices = useSelector(selectPublicUserChoices) || {
    entrée: [],
    plat: [],
    accompagnement: [],
    dessert: [],
  };

  const [isOpen, setIsOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [startY, setStartY] = useState(0);
  const basketRef = useRef<HTMLDivElement>(null);

  const recipeCategories: IRestaurantRecipeCategories = {
    entrée: userChoices.entrée || [],
    plat: userChoices.plat || [],
    accompagnement: userChoices.accompagnement || [],
    dessert: userChoices.dessert || [],
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const currentY = e.touches[0].clientY;
    const diffY = startY - currentY;

    if (basketRef.current) {
      if (diffY > 50) {
        setIsOpen(true); // Glisser vers le haut pour ouvrir
      } else if (diffY < -50) {
        setIsOpen(false); // Glisser vers le bas pour fermer
      }
    }
  };

  const totalItems =
    recipeCategories.entrée.length +
    recipeCategories.plat.length +
    recipeCategories.accompagnement.length +
    recipeCategories.dessert.length;

  const textColor = primaryColor ? getTextColor(primaryColor) : "black";

  const handleCategoryToggle = (
    category: string,
    e:
      | React.MouseEvent<HTMLHeadingElement>
      | React.TouchEvent<HTMLHeadingElement>
  ) => {
    e.stopPropagation(); // Empêche la propagation du clic
    // Toggle la catégorie active
    setActiveCategory((prev) => (prev === category ? null : category));
  };

  const toggleModal = (e: React.MouseEvent) => {
    e.stopPropagation(); // Empêche la propagation du clic
    setIsOpen((prev) => !prev);
  };

  // Fonction pour supprimer une recette du panier
  const handleRemoveRecipe = (
    recipeId: number,
    category: string,
    e: React.MouseEvent
  ) => {
    e.stopPropagation();

    // Vérifie si la recette est déjà dans les choix de l'utilisateur
    const recipeExists = userChoices[category].some(
      (recipe) => recipe.id === recipeId
    );

    if (!recipeExists) {
      alert("Cette recette n'est pas dans votre panier !");
      return;
    }

    dispatch(removeRecipeFromUserChoices({ category, recipeId })); // Dispatch de l'action avec la catégorie
  };

  return (
    <div
      className={`public-recipe-basket-container ${isOpen ? "open" : ""}`}
      ref={basketRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div
        className="public-recipe-heading"
        style={{ backgroundColor: primaryColor, color: textColor }}
        onClick={toggleModal} // Toggle de la modal au clic sur l'en-tête
      >
        Vos choix ({totalItems})
        {isOpen ? (
          <IoIosArrowDown size={24} color={textColor} />
        ) : (
          <IoIosArrowForward size={24} color={textColor} />
        )}
      </div>

      {isOpen && (
        <div className="public-recipe-basket-list">
          {Object.entries(recipeCategories).map(([category, recipes]) => (
            <div key={category} className="public-recipe-basket-category">
              <h4
                style={{
                  color: secondaryColor
                    ? getTextColor(secondaryColor)
                    : "black",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => handleCategoryToggle(category, e)} // Gestion du clic
                onTouchEnd={(e) => handleCategoryToggle(category, e)} // Gestion du toucher
              >
                {activeCategory === category ? (
                  <IoIosArrowDown
                    size={24}
                    color={
                      secondaryColor ? getTextColor(secondaryColor) : "black"
                    }
                  />
                ) : (
                  <IoIosArrowForward
                    size={24}
                    color={
                      secondaryColor ? getTextColor(secondaryColor) : "black"
                    }
                  />
                )}
                {`${category.charAt(0).toUpperCase() + category.slice(1)} (${
                  recipes.length
                })`}
              </h4>

              {activeCategory === category && (
                <div>
                  {recipes.map((recipe: IRestaurantRecipe) => (
                    <div key={recipe.id} className="public-recipe-basket-item">
                      <div>{recipe.name}</div>
                      <FaTimesCircle
                        size={22}
                        color={colors.red}
                        onClick={(e) =>
                          handleRemoveRecipe(recipe.id, category, e)
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PublicRecipeBasketModal;
