import "./MenusComponent.css";
import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenusAndRecipesLayout from "../../../../layout/MenusAndRecipesLayout/MenusAndRecipesLayout";
import useRestaurant from "../../../../hooks/useRestaurant";
import { IMenu } from "../../../../interface/Menus/IMenu";
import { MenuOrRecipe } from "../../../../layout/MenusAndRecipesLayout/MenusAndRecipes";
import MenusCard from "./MenusCard/MenuCard";

const MenusComponent: FC = () => {
  const navigate = useNavigate();
  const { menus } = useRestaurant();
  const [filteredMenus, setFilteredMenus] = useState<MenuOrRecipe[]>([]);

  useEffect(() => {
    setFilteredMenus(menus as MenuOrRecipe[]);
  }, [menus]);

  return (
    <MenusAndRecipesLayout
      items={menus as MenuOrRecipe[]}
      setFilteredItems={setFilteredMenus}
      searchPlaceholder="Chercher des plats, desserts ..."
      pricePlaceholder="Prix du menu"
      isRecipePage={false}
    >
      <div className="menus-cards-container">
        {filteredMenus.length !== 0 ? (
          filteredMenus.map((card: IMenu, idx: number) => (
            <MenusCard {...card} key={card.id} idx={idx} />
          ))
        ) : (
          <div>Aucun menu existant.</div>
        )}
      </div>
    </MenusAndRecipesLayout>
  );
};

export default MenusComponent;
