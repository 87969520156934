import { formatDate } from "../../../../helpers/getDate";
import {
  FaCheck,
  FaEye,
  FaFilePdf,
  FaRegTrashAlt,
  FaTimes,
} from "react-icons/fa";
import { MdMoreHoriz, MdOutlineFileDownload } from "react-icons/md";
import "./AdminDocumentTable.css";
import { IDocument } from "../../../../interface/Documents/IDocument";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import VisualizeDocument from "./Visualize/VisualizeDocument";
import DocumentStatusIcon from "../../../Auth/Documents/DocumentStatusIcon";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import PaginationButtons from "../../Pagination/PaginationComponent";
import usePagination from "../../../../hooks/usePagination";
import {
  reviewDocumentForAdmin,
  validateDocumentForAdmin,
  refuseDocumentForAdmin,
  deleteDocumentForAdmin,
} from "../../../../store/Admin/Documents/Requests";

interface AdminDocumentTable {
  documents: IDocument[];
}

const AdminDocumentsTable: FC<AdminDocumentTable> = ({ documents }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const itemsPerPage = 5;
  const { currentPage, totalPages, next, prev } = usePagination(
    documents.length,
    itemsPerPage
  );
  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const [activeStatusPopUpId, setActiveStatusPopUpId] = useState<number | null>(
    null
  );
  const [activePopupId, setActivePopupId] = useState<number | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(
    null
  );
  const [viewerActive, setViewerActive] = useState<boolean>(true);

  const statusPopUpRef = useRef(null);
  const popUpRef = useRef(null);

  const lastClickedStatusRef = useRef<number | null>(null);
  const lastClickedRef = useRef<number | null>(null);

  const toggleStatusPopUp = (e, docId: number) => {
    e.stopPropagation();
    if (lastClickedStatusRef.current === docId) {
      setActiveStatusPopUpId(null);
      lastClickedStatusRef.current = null;
    } else {
      setActiveStatusPopUpId(docId);
      lastClickedStatusRef.current = docId;
    }
  };

  const togglePopUp = (e, docId: number) => {
    e.stopPropagation();
    if (lastClickedRef.current === docId) {
      setActivePopupId(null);
      lastClickedRef.current = null;
    } else {
      setActivePopupId(docId);
      lastClickedRef.current = docId;
    }
  };

  const handleVisualizeDocument = (document: IDocument) => {
    setSelectedDocument(document);
    setViewerActive(true);
  };

  const handleDownloadDocument = (doc: IDocument) => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_BASE_URL + doc.file_path;
    link.setAttribute("download", doc.name + ".pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleValidateDocument = (document: IDocument) => {
    if (document.status === "Validé") {
      addToast(
        "info",
        "Document déjà validé ! 🙌",
        "Le document a déjà été validé le " + formatDate(document.updated_at)
      );
      return;
    }

    handleAsyncResponse(
      dispatch,
      validateDocumentForAdmin(document.id),
      {
        successTitle: `Document validé`,
        successMessage: `Le document a été validé ! 🙌`,
        successDescription: "Le document " + document.name + " a été validé 🙌",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleReviewDocument = (document: IDocument) => {
    if (document.status === "En cours") {
      addToast(
        "info",
        "Document déjà en cours ! 🙌",
        "Le document a déjà été visionné le " + formatDate(document.updated_at)
      );
      return;
    }

    handleAsyncResponse(
      dispatch,
      reviewDocumentForAdmin(document.id),
      {
        successTitle: `Document en cours de validation`,
        successMessage: `Le document est en cours de validation ! 🙌`,
        successDescription:
          "Le document " + document.name + " est en cours de validation 🙌",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleRefuseDocument = (document: IDocument) => {
    if (document.status === "Refusé") {
      addToast(
        "info",
        "Document déjà refusé ! 🙌",
        "Le document a déjà été refusé le " + formatDate(document.updated_at)
      );
      return;
    }

    handleAsyncResponse(
      dispatch,
      refuseDocumentForAdmin(document.id),
      {
        successTitle: `Document refusé`,
        successMessage: `Le document a été refusé ! 😢`,
        successDescription: "Le document " + document.name + " a été refusé 😢",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleDeleteDocument = (document: IDocument) => {
    handleAsyncResponse(
      dispatch,
      deleteDocumentForAdmin(document.id),
      {
        successTitle: `Document supprimé`,
        successMessage: `Le document a été supprimé ! 🙌`,
        successDescription:
          "Le document " + document.name + " a été supprimé 🙌",
      },
      { addToast, addNotification, navigate }
    );
  };

  useOutsideAlerter(statusPopUpRef, () => setActiveStatusPopUpId(null));
  useOutsideAlerter(popUpRef, () => setActivePopupId(null));

  const paginatedDocuments = documents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="admin-document-table-documents-container">
      <div className="admin-document-table-documents-content-container">
        <table>
          <thead>
            <tr>
              <th>Icon</th>
              <th>Nom</th>
              <th>Statut</th>
              <th>Date d'inscription</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedDocuments.map((doc, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <div className="admin-restaurant-table-no-logo">
                      <FaFilePdf size={40} color="red" />
                    </div>
                  </td>
                  <td>{doc.name}</td>
                  <td className="relative">
                    <div
                      className={`pointer admin-users-table-documents-status-container ${
                        doc.status === "En cours" ? "inprogress" : "validate"
                      }`}
                      onClick={(e) => toggleStatusPopUp(e, doc.id)}
                    >
                      <DocumentStatusIcon status={doc.status} />
                      {activeStatusPopUpId === doc.id && (
                        <div
                          className="admin-restaurant-table-popup-container document-size"
                          ref={statusPopUpRef}
                        >
                          <div
                            className="admin-restaurant-table-popup-download"
                            onClick={() => handleValidateDocument(doc)}
                          >
                            <FaCheck className="admin-restaurant-table-popup-download-icon" />
                            <span className="admin-restaurant-table-popup-download-icon">
                              Valider
                            </span>
                          </div>
                          <div
                            className="admin-restaurant-table-popup-inprogress"
                            onClick={() => handleReviewDocument(doc)}
                          >
                            <FaCheck className="admin-restaurant-table-popup-inprogress-icon" />
                            <span className="admin-restaurant-table-popup-inprogress-icon">
                              En cours
                            </span>
                          </div>
                          <div className="admin-restaurants-table-separator-container">
                            <div className="admin-restaurants-table-separator-content"></div>
                          </div>
                          <div
                            className="admin-restaurant-table-popup-delete"
                            onClick={() => handleRefuseDocument(doc)}
                          >
                            <FaTimes className="admin-restaurant-table-popup-delete-icon" />
                            <span className="admin-restaurant-table-popup-delete-icon">
                              Refuser
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>{formatDate(doc.updated_at)}</td>
                  <td className="relative">
                    <button
                      className="admin-restaurant-table-more-btn"
                      onMouseDown={(e) => togglePopUp(e, doc.id)}
                    >
                      <MdMoreHoriz size={22} />
                    </button>
                    {activePopupId === doc.id && (
                      <div
                        className="admin-restaurant-table-popup-container"
                        ref={popUpRef}
                      >
                        <div
                          className="admin-restaurant-table-popup-documents"
                          onClick={() => handleVisualizeDocument(doc)}
                        >
                          <FaEye className="admin-restaurant-table-popup-documents-icon" />
                          <span className="admin-restaurant-table-popup-documents-icon">
                            Visualiser
                          </span>
                        </div>
                        <div
                          className="admin-restaurant-table-popup-download"
                          onClick={() => handleDownloadDocument(doc)}
                        >
                          <MdOutlineFileDownload className="admin-restaurant-table-popup-download-icon" />
                          <span className="admin-restaurant-table-popup-download-icon">
                            Télécharger
                          </span>
                        </div>
                        <div className="admin-restaurants-table-separator-container">
                          <div className="admin-restaurants-table-separator-content"></div>
                        </div>
                        <div
                          className="admin-restaurant-table-popup-delete"
                          onClick={() => handleDeleteDocument(doc)}
                        >
                          <FaRegTrashAlt className="admin-restaurant-table-popup-delete-icon" />
                          <span className="admin-restaurant-table-popup-delete-icon">
                            Supprimer
                          </span>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="admin-restaurant-table-bottom">
        <div className="admin-restaurant-table-restaurants-number">
          Nombre total de documents pour cet utilisateur : {documents.length}
        </div>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={prev}
          onNext={next}
        />
      </div>

      {selectedDocument && viewerActive && (
        <VisualizeDocument
          document={selectedDocument}
          setActive={setViewerActive}
        />
      )}
    </div>
  );
};

export default AdminDocumentsTable;
