import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SearchComponent from "../../../../layout/MenusAndRecipesLayout/Search/SearchComponent";
import { selectAdminUsers } from "../../../../store/Admin/Users/AdminUsers";
import { IDocument } from "../../../../interface/Documents/IDocument";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import GenericSelectWithoutRHF from "../../../Reusables/GenericSelectWithoutRHF";
import AdminDocumentsTable from "./AdminDocumentsTable";
import {
  removeDocumentsFromStore,
  selectAdminDocuments,
  selectUserId,
  setUserIdToSore,
} from "../../../../store/Admin/Documents/Documents";
import { fetchAllDocumentsForAdmin } from "../../../../store/Admin/Documents/Requests";

const AdminDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const adminUsers = useSelector(selectAdminUsers);
  const adminDocuments = useSelector(selectAdminDocuments);
  const adminUserId = useSelector(selectUserId);

  const [selectedUser, setSelectedUser] = useState<{
    id?: number;
    name?: string;
  } | null>(null);
  const [filteredDocuments, setFilteredDocuments] = useState<IDocument[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (adminUserId) {
      setSelectedUser((prevState) => ({ ...prevState, id: adminUserId }));
    }
    setFilteredDocuments(adminDocuments);
  }, [adminDocuments]);

  useEffect(() => {
    if (selectedUser && !isLoading) {
      setFilteredDocuments(adminDocuments);
    }
  }, [adminDocuments, selectedUser, isLoading]);

  const handleGoBack = () => {
    dispatch(removeDocumentsFromStore());
    navigate(-1);
  };

  const handleRestaurantChange = async (selectedOption) => {
    const user = adminUsers.find((u) => u.id === selectedOption.id);
    if (user) {
      setIsLoading(true);
      await dispatch(fetchAllDocumentsForAdmin(user.id));
      setSelectedUser(user);
      await dispatch(setUserIdToSore(user.id));
      setFilteredDocuments(adminDocuments);
      setIsLoading(false);
    }
  };

  const restaurantOptions = adminUsers.map((user) => ({
    name: user.firstName + " " + user.lastName,
    id: user.id,
  }));

  return (
    <div className="admin-restaurants-container">
      <h2 className="admin-restaurants-title">
        Tous les documents de l'utilisateur
      </h2>
      <div className="admin-restaurants-content-container">
        <div
          className="admin-restaurants-goback"
          onClick={() => handleGoBack()}
        >
          <IoIosArrowBack size={25} />
          <span>Revenir en arrière</span>
        </div>
        <GenericSelectWithoutRHF
          options={restaurantOptions}
          category="utilisateur"
          onChange={handleRestaurantChange}
          returnObject
          searchBar
          value={selectedUser ? selectedUser.id : ""}
        />
        <div className="admin-restaurants-utility-bar">
          <SearchComponent
            items={adminDocuments}
            setFilteredItems={setFilteredDocuments}
            placeholder="Rechercher un document ..."
          />
        </div>

        {filteredDocuments && filteredDocuments.length > 0 ? (
          <AdminDocumentsTable documents={filteredDocuments} />
        ) : (
          <div>
            Il n'y aucun document de disponible pour cet utilisateur pour le
            moment.
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDocuments;
