import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRestaurantRecipe } from "../../../interface/IRestaurantRecipe";
import {
  addMultipleRecipes,
  addRecipeForAdmin,
  deleteRecipeForAdmin,
  fetchAllRecipesForAdmin,
  toggleArchivedRecipeForAdmin,
  updateRecipeForAdmin,
} from "./Requests";

export const recipesSlice = createSlice({
  name: "adminRecipes",
  initialState: {
    recipes: <IRestaurantRecipe[]>[],
    recipe: <IRestaurantRecipe>{},
    restaurantId: <number>null,
    done: false,
    loading: false,
    errors: {} as any,
  },
  reducers: {
    addRecipeToStore(state: any, action) {
      state.recipe = action.payload;
    },
    setRestaurantIdToStore(state, action) {
      state.restaurantId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMultipleRecipes.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMultipleRecipes.fulfilled, (state, action) => {
        state.loading = false;
        const { recipes } = action.payload;

        state.recipes.push(...recipes);
      })
      .addCase(addMultipleRecipes.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload || {
          message: "Une erreur inconnue est survenue",
        };
      })
      .addCase(addRecipeForAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addRecipeForAdmin.fulfilled,
        (state, action: PayloadAction<IRestaurantRecipe>) => {
          state.loading = false;

          state.recipes.push(action.payload);
        }
      )
      .addCase(addRecipeForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(
        fetchAllRecipesForAdmin.fulfilled,
        (state, action: PayloadAction<IRestaurantRecipe[]>) => {
          state.loading = false;
          state.recipes = action.payload;
        }
      )
      .addCase(fetchAllRecipesForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllRecipesForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRecipeForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateRecipeForAdmin.fulfilled,
        (state, action: PayloadAction<IRestaurantRecipe>) => {
          state.loading = false;
          const recipeIndex = state.recipes.findIndex(
            (recipe: IRestaurantRecipe) => recipe.id === action.payload.id
          );
          state.recipes[recipeIndex] = action.payload;
        }
      )
      .addCase(updateRecipeForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(toggleArchivedRecipeForAdmin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(toggleArchivedRecipeForAdmin.fulfilled, (state, action) => {
        state.loading = false;

        const recipeIndex = state.recipes.findIndex(
          (recipe: IRestaurantRecipe) => recipe.id === action.payload.id
        );
        state.recipes[recipeIndex].archived = action.payload.archived;
      })
      .addCase(toggleArchivedRecipeForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteRecipeForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRecipeForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.recipes = state.recipes.filter(
          (recipe) => recipe.id !== action.payload.recipeId
        );
      })
      .addCase(deleteRecipeForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAdminRecipes = (state: {
  adminRecipes: { recipes: IRestaurantRecipe[] };
}) => state.adminRecipes.recipes;

export const selectAdminRecipe = (state: {
  adminRecipes: { recipe: IRestaurantRecipe };
}) => state.adminRecipes.recipe;

export const selectRestaurantId = (state: {
  adminDocuments: { restaurantId: number };
}) => state.adminDocuments.restaurantId;

export const { addRecipeToStore, setRestaurantIdToStore } =
  recipesSlice.actions;

export default recipesSlice.reducer;
