import NotificationItem from "./NotificationItem";
import { useNotification } from "../../hooks/useNotification";
import "./NotificationList.css";
import { useEffect } from "react";

const NotificationList: React.FC = () => {
  const {
    notifications,
    showNotifications,
    showNotificationsAnimation,
    setShowNotificationsAnimation,
  } = useNotification();

  useEffect(() => {
    if (showNotifications) {
      setTimeout(() => {
        setShowNotificationsAnimation(true);
      }, 1);
    }
  }, []);

  return (
    <div
      className={`notification-list ${
        showNotificationsAnimation ? "open" : ""
      }`}
    >
      {notifications.length === 0 ? (
        <div className="notification-item">
          <div className="notification-content">
            <p>Aucune notifications.</p>
          </div>
        </div>
      ) : (
        notifications.map((notification, idx: number) => (
          <NotificationItem
            key={idx}
            id={notification.id}
            title={notification.title}
            description={notification.description}
            originLink={notification.originLink}
            type={notification.type}
            onClose={notification}
          />
        ))
      )}
    </div>
  );
};

export default NotificationList;
