import { MdEdit } from "react-icons/md";
import "./LastMenusWidget.css";
import { formatDate } from "../../../../helpers/getDate";
import colors from "../../../constants/colors";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { IMenu } from "../../../../interface/Menus/IMenu";
import { addMenuToStore } from "../../../../store/Menus/Menus";
import { FaBookOpen } from "react-icons/fa";

const LastMenusWidget = ({ menus }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  if (!menus || !Array.isArray(menus)) {
    return <div>Aucune menu disponible.</div>;
  }

  const sortedAndLimitedMenus: IMenu[] = [...menus]
    .sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    )
    .slice(0, 2);

  const dispatchToStoreAndRedirect = (menu: IMenu) => {
    dispatch(
      addMenuToStore({
        id: menu.id,
        name: menu.name,
        recipes: menu.recipes,
        price: menu.price,
        note: menu.note,
        availability_start_time: menu.availability_start_time,
        availability_end_time: menu.availability_end_time,
        is_child_menu: menu.is_child_menu,
        archived: menu.archived,
      })
    );
    navigate("/pro/modifier-un-menu");
  };

  return (
    <div className="last-menus-container">
      <div className="last-menu-title">Mes derniers menus</div>
      <div className="last-menus-list-container">
        {sortedAndLimitedMenus.map((menu: IMenu, idx) => (
          <div key={menu.id} className="last-menus-content-wrapper">
            {idx !== sortedAndLimitedMenus.length && (
              <div className="last-menu-separator"></div>
            )}
            <div className="last-menus-content-container">
              <div className="last-menu-left-container">
                <div className="last-menu-icon-container">
                  <FaBookOpen size={30} className="last-menu-icon" />
                </div>
                <div className="last-menu-data">
                  <div className="last-menu-name">{menu.name}</div>
                  <div className="last-menu-date">
                    Modifié le{" "}
                    <span style={{ color: colors.green }}>
                      {formatDate(menu.updated_at)}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="last-menu-goto-edit-container"
                onClick={() => dispatchToStoreAndRedirect(menu)}
              >
                <MdEdit className="last-menu-edit-icon" size={20} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LastMenusWidget;
