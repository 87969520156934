import { FC } from "react";
import LayoutPro from "../../layout/LayoutPro";
import AddMenuComponent from "../../components/Pro/Menu/Add/AddMenuComponent";

const AddMenu: FC = () => {
  return (
    <LayoutPro subHeading="Associé vos recettes à un menu !">
      <AddMenuComponent />
    </LayoutPro>
  );
};

export default AddMenu;
