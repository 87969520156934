import { FC, useState } from "react";
import "./SortByDate.css";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { SortByUpdatedAt } from "../MenusAndRecipes";

const SortByDate: FC<SortByUpdatedAt> = ({ items, setItems }) => {
  const [sortOrder, setSortOrder] = useState("asc");

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");

    const sortedDate = [...items].sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);

      return sortOrder === "asc"
        ? dateB.getTime() - dateA.getTime()
        : dateA.getTime() - dateB.getTime();
    });

    setItems(sortedDate);
  };

  return (
    <div className="m-e-r-l-sort-by-date-container">
      <button onClick={toggleSortOrder} className="m-e-r-l-sort-by-date-btn">
        {sortOrder === "asc" ? (
          <FaSortAmountDown className="m-e-r-l-sort-by-date-icon" />
        ) : (
          <FaSortAmountUp className="m-e-r-l-sort-by-date-icon" />
        )}
      </button>
    </div>
  );
};

export default SortByDate;
