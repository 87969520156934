import { FC } from "react";
import LayoutPro from "../../layout/LayoutPro";
import EditMenuComponent from "../../components/Pro/Menu/Edit/EditMenuComponent";

const EditMenu: FC = () => {
  return (
    <LayoutPro subHeading="Modifier un menu de votre carte !">
      <EditMenuComponent />
    </LayoutPro>
  );
};

export default EditMenu;
