import { FC, useEffect } from "react";
import "./NavigationDrawer.css";
import "./NavigationDrawerMobile.css";
import { RxDashboard } from "react-icons/rx";
import path from "./path";
import { Link, useNavigate } from "react-router-dom";
import {
  RiMenuSearchLine,
  RiMenuAddFill,
  RiDashboardFill,
} from "react-icons/ri";
import {
  MdOutlineQrCodeScanner,
  MdQrCode2,
  MdMenu,
  MdOutlineRestaurantMenu,
} from "react-icons/md";
import { FaConciergeBell } from "react-icons/fa";

import LogoHorizontal from "../../assets/img/logo-navigation-drawer.png";
import LogoMobile from "../../assets/img/safeeat-logo.png";
import { useLocation } from "react-router-dom";
import { GrRestaurant } from "react-icons/gr";
import { IoMenu } from "react-icons/io5";
import colors from "../constants/colors";
import { NavbarProps } from "../../interface/NavbarProps";
import { useUser } from "../../hooks/useUser";
import Avatar from "../Avatar/Avatar";
import { useMediaQuery } from "react-responsive";
import useRestaurant from "../../hooks/useRestaurant";

const NavigationDrawer: FC<{
  showNavigationDrawer: boolean;
  setShowNavigationDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showNavigationDrawer, setShowNavigationDrawer }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, getFullName } = useUser();
  const { restaurant } = useRestaurant();

  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isTablet = useMediaQuery({ maxWidth: 1075 });

  const currentLogo = isTablet ? LogoMobile : LogoHorizontal;

  useEffect(() => {
    handlePosition();
  }, [isMobile, isTablet]);

  const handlePosition = () => {
    if (!isTablet && !isMobile) {
      return showNavigationDrawer ? "translateX(0)" : "translateX(-19rem)";
    } else if (!isMobile) {
      return showNavigationDrawer ? "translateX(0)" : "translateX(-5rem)";
    } else {
      return showNavigationDrawer ? "translateX(0)" : "translateX(-3rem)";
    }
  };

  const { primary, secondary } = path;
  let top = primary;
  let bottom = secondary;

  const handleShowNavigationDrawer = () => {
    setShowNavigationDrawer(!showNavigationDrawer);
  };

  return (
    <section
      className="navigation-drawer"
      style={{
        transform: handlePosition(),
      }}
    >
      <div className="logo" onClick={() => navigate("/pro/tableau-de-bord")}>
        <img src={currentLogo} alt="logo safeeat" />
      </div>
      <div className="navigation-drawer-links">
        {top.map((path: NavbarProps, idx: number) => (
          <Link className="drawer-item" to={path.path} key={idx}>
            {path.title === "Tableau de bord" && (
              <>
                {path.path === location.pathname ? (
                  <RiDashboardFill
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <RxDashboard
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            {path.title === "Mon restaurant" && (
              <>
                {path.path === location.pathname ? (
                  <GrRestaurant
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <GrRestaurant
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            {path.title === "Mes recettes" && (
              <>
                {path.path === location.pathname ? (
                  <RiMenuSearchLine
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <FaConciergeBell
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            {path.title === "Mes menus" && (
              <>
                {path.path === location.pathname ? (
                  <RiMenuSearchLine
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <MdMenu
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            {path.title === "Mes QR Codes" && (
              <>
                {path.path === location.pathname ? (
                  <MdOutlineQrCodeScanner
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <MdQrCode2
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.brown
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            <p
              className="drawer-item-text"
              style={{
                color: path.path === location.pathname ? "#0B1324" : "#bac1cc",
              }}
            >
              {path.title}
            </p>
            {path.path === location.pathname && (
              <div className="active-sidebar"></div>
            )}
          </Link>
        ))}
        <div className="separator"></div>
        {bottom.map((path, idx: number) => (
          <Link className="drawer-item" to={path.path} key={idx}>
            {path.title === "Ajouter un restaurant" && !restaurant && (
              <>
                {path.path === location.pathname ? (
                  <GrRestaurant
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.pink
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <RiMenuAddFill
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.pink
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            {path.title === "Ajouter une recette" && (
              <>
                {path.path === location.pathname ? (
                  <MdOutlineRestaurantMenu
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.pink
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <RiMenuAddFill
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.pink
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            {path.title === "Ajouter un menu" && (
              <>
                {path.path === location.pathname ? (
                  <MdOutlineRestaurantMenu
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.pink
                          : "#bac1cc",
                    }}
                  />
                ) : (
                  <RiMenuAddFill
                    className="drawer-item-icon"
                    style={{
                      color:
                        path.path === location.pathname
                          ? colors.pink
                          : "#bac1cc",
                    }}
                  />
                )}
              </>
            )}
            {(path.title !== "Ajouter un restaurant" || !restaurant) && (
              <p
                key={path.path}
                className="drawer-item-text"
                style={{
                  color:
                    path.path === location.pathname ? "#0B1324" : "#bac1cc",
                }}
              >
                {path.title}
              </p>
            )}
          </Link>
        ))}
      </div>
      <div
        className="my-profile-container"
        onClick={() => navigate("/pro/mon-profil")}
      >
        <div className="my-profile-left">
          <Avatar width="50px" height="50px" />
          <div className="info-container">
            <div className="profile-name">{getFullName()}</div>
            <div className="profile-restaurant">{user.email}</div>
          </div>
        </div>

        <div className="more-container">
          <div className="more-btn more-green"></div>
          <div className="more-btn more-brown"></div>
          <div className="more-btn more-pink"></div>
        </div>
      </div>
      <div
        className={`burger-menu-container ${
          showNavigationDrawer ? "move-burger-menu-onclick" : "closed-nd"
        }`}
        onClick={() => handleShowNavigationDrawer()}
      >
        <IoMenu className="burger-menu" size={30} />
      </div>
    </section>
  );
};

export default NavigationDrawer;
