import { useEffect, useRef, useState } from "react";
import "./GenericSelect.css";
import { FaTag } from "react-icons/fa";
import { Controller, useFormContext } from "react-hook-form";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import colors from "../../constants/colors";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const GenericSelect = ({
  name,
  rules,
  options,
  category,
  defaultValue = "",
  Icon = FaTag,
  returnObject = false,
  searchBar = false,
}) => {
  const { control, setValue } = useFormContext();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    string | { name: string }
  >(defaultValue || "");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const genericSelectRef = useRef(null);

  useEffect(() => {
    setValue(name, defaultValue || (returnObject ? {} : ""));
  }, [setValue, name, defaultValue, returnObject]);

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen);
    setSearchTerm("");
  };

  const handleSelectOption = (option) => {
    setSelectedOption(returnObject ? option : option.name);
    setIsOpen(false);
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useOutsideAlerter(genericSelectRef, () => setIsOpen(false));

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || (returnObject ? {} : "")}
      render={({ field, fieldState: { error } }) => (
        <div className="input-container" ref={genericSelectRef}>
          <div
            className="relative generic-select-container pointer"
            style={{ flex: 1 }}
            onClick={handleSetIsOpen}
          >
            <div className="generic-select-names-container">
              <Icon className="generic-select-icon" />
              <div
                className={`color-placeholder ${
                  selectedOption && "color-black"
                }`}
              >
                {selectedOption
                  ? typeof selectedOption === "string"
                    ? selectedOption
                    : selectedOption.name
                  : `Sélectionnez une ${category}`}
              </div>
            </div>
            {isOpen ? (
              <IoIosArrowUp color={colors.green} />
            ) : (
              <IoIosArrowDown color={colors.green} />
            )}
            {isOpen && (
              <ul className="generic-select-list">
                {searchBar && (
                  <li style={{ marginBottom: "0.3rem" }}>
                    <input
                      type="text"
                      className="generic-select-search"
                      placeholder={`Rechercher un(e) ${category}`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on search input click
                    />
                  </li>
                )}
                {filteredOptions.map((option, index) => (
                  <li
                    key={index}
                    className="generic-select-item"
                    onClick={() => {
                      handleSelectOption(option);
                      returnObject
                        ? field.onChange(option)
                        : field.onChange(option.name);
                    }}
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {error && <p className="is-mandatory error-msg">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default GenericSelect;
