// hooks/usePagination.js
import { useState } from "react";

function usePagination(totalCount, pageSize) {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalCount / pageSize);

  const next = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const prev = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  return { currentPage, totalPages, next, prev };
}

export default usePagination;
