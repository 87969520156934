import { createAsyncThunk } from "@reduxjs/toolkit";
import { restaurantRecipe } from "../../../api/api";
import { IRestaurantRecipe } from "../../../interface/IRestaurantRecipe";
import { ApiErrorResponse } from "../../../interface/RejectValue";

export interface IRestaurantRecipeArray {
  restaurantId: string;
  recipes: IRestaurantRecipe[];
}
export const addMultipleRecipes = createAsyncThunk<
  IRestaurantRecipeArray, // Type correctement mis à jour
  FormData,
  { rejectValue: ApiErrorResponse }
>(
  "admin/addMultipleRecipes",
  async (fileData: FormData, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.addMultipleRecipes(fileData);
      const restaurantId = fileData.get("restaurantProfileId").toString(); // Assurez-vous que ceci retourne une string

      // Assurez-vous que la structure de `response` correspond à ce que vous attendez
      return { restaurantId, recipes: response.recipes }; // Ajustez `response.data` selon ce que votre API renvoie
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const addRecipeForAdmin = createAsyncThunk<
  IRestaurantRecipe, // Type de la réponse en cas de succès
  FormData, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "admin/addRecipeForAdmin",
  async (recipeData: FormData, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.createRecipeForAdmin(recipeData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const updateRecipeForAdmin = createAsyncThunk<
  IRestaurantRecipe, // Type de la réponse en cas de succès
  { id: number; formData: FormData }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "admin/updateRecipeForAdmin",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      // Appel API pour la mise à jour de la recette
      const response = await restaurantRecipe.updateRecipeForAdmin(
        formData,
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const toggleArchivedRecipeForAdmin = createAsyncThunk<
  { archived: boolean; id: number }, // Type de la réponse en cas de succès
  { id: number; checked: boolean }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "admin/toggleArchivedRecipeForAdmin",
  async ({ id, checked }, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.updateToggleRecipeForAdmin(
        { archived: checked },
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteRecipeForAdmin = createAsyncThunk(
  "admin/deleteRecipeForAdmin",
  async (recipeId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.deleteRecipeForAdmin(recipeId);
      return { recipeId, ...response }; // Renvoie l'ID de la recette supprimée pour mise à jour de l'état
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllRecipesForAdmin = createAsyncThunk(
  "admin/fetchAllRecipessForAdmin",
  async (restaurantId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.getAllRecipesForAdmin(
        restaurantId
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
