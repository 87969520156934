// Définition des règles de validation pour les fichiers
export const documentRules = {
  file: {
    required: "Le fichier est requis",
    validate: {
      isValidFormat: (fileList: FileList) => {
        if (!fileList.length) return true; // No file selected is fine if file is optional
        const file = fileList[0];
        const supportedFormats = ["application/pdf"];
        return supportedFormats.includes(file.type)
          ? true
          : "Veuillez sélectionner un fichier PDF valide.";
      },
      isUnderSizeLimit: (fileList: FileList) => {
        if (!fileList.length) return true;
        const file = fileList[0];
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        return file.size <= maxSizeInBytes
          ? true
          : "Le fichier doit être de moins de 5MB.";
      },
    },
  },
  name: {
    required: "Le nom du document est obligatoire",
    minLength: {
      value: 5,
      message: "Le nom du document ne peut pas faire moins de 5 caractères",
    },
    maxLength: {
      value: 100,
      message: "Le nom du document ne peut pas dépasser 100 caractères",
    },
  },
};
