import "./AdminEditUser.css";

import { FormProvider, useForm } from "react-hook-form";
import { IEditUser, IUser } from "../../../../interface/IUser";
import handleAxiosError from "../../../../helpers/handleAxiosError";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import { FaEnvelope, FaUser } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AdminCreateUserValidationRules } from "../../../../helpers/Validators/AdminCreateUserValidationRules";
import {
  addNewUser,
  deleteUserForAdmin,
  editUser,
} from "../../../../store/Admin/Users/Requests";
import { selectAdminUser } from "../../../../store/Admin/Users/AdminUsers";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";

const AdminEditUser = () => {
  const methods = useForm<IUser>();
  const validationRules = AdminCreateUserValidationRules(methods.getValues);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const user = useSelector(selectAdminUser);

  const onSubmit = async (data: IEditUser) => {
    const userData = new FormData();

    // Check each field for changes
    const fieldsToUpdate = ["avatar", "firstName", "lastName", "email", "tel"];
    fieldsToUpdate.forEach((field) => {
      if (data[field] !== user[field]) {
        if (field === "avatar" && data.avatar) {
          userData.append("avatar", data.avatar);
        } else {
          userData.append(field, data[field]);
        }
      }
    });

    userData.append("id", user.id.toString());

    handleAsyncResponse(
      dispatch,
      editUser(userData),
      {
        successTitle: `Utilisateur modifié`,
        successMessage: "L'utilisateur a été modifié ! 🙌",
        successDescription:
          "Vous avez modifié un compte professionnel SafeEat ! 🙌",
        navigateTo: "/admin/tous-les-utilisateurs",
      },
      { addToast, addNotification, navigate }
    );
  };

  const imagePath = process.env.REACT_APP_BASE_URL + "uploads/";
  const avatarPreview =
    user.avatar && user.avatar.breakpoints
      ? imagePath + user.avatar.breakpoints.thumbnail.name
      : null;

  const handleDeleteUser = () => {
    try {
      dispatch(deleteUserForAdmin(user.id));

      addToast(
        "success",
        "Utilisateur supprimée",
        `L'utilisateur ${
          user.firstName + " " + user.lastName
        } a été supprimé ! 🙌`
      );
      addNotification({
        title: `${
          user.firstName + " " + user.lastName
        } a été supprimée avec succès ! 🙌`,
        description:
          "Vous avez supprimé l'utilisateur : " +
          user.firstName +
          " " +
          user.lastName +
          " de SafeEat",
        originLink: "/pro/les-utilisateurs",
        type: "users",
      });

      navigate("/pro/les-utilisateurs");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
      addToast(
        "error",
        "Erreur de suppression de l'utilisateur",
        `Une erreur a été détecté lors de la suppression d'utilisateur. 😓`
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Modifier un utilisateur</h1>
          <p>Remplissez ces champs pour modifier un utilisateur.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="logo">
                La photo <span className="is-mandatory">*</span>
              </label>
              <ImageInput
                name="avatar"
                rules={validationRules.avatar}
                defaultValue={user.avatar}
                imageUrl={avatarPreview}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="firstName">
                Le prénom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="firstName"
                rules={validationRules.firstName}
                placeholder="Exemple : Patrick"
                defaultValue={user.firstName}
                id="firstName"
                icon={FaUser}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="lastName">
                Le nom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="lastName"
                rules={validationRules.lastName}
                placeholder="Exemple : Dupont"
                defaultValue={user.lastName}
                id="lastName"
                icon={FaUser}
              />
            </div>
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="email">
                L'email <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                type="email"
                name="email"
                rules={validationRules.email}
                placeholder="Exemple : patrick@safeeat.fr"
                defaultValue={user.email}
                id="email"
                icon={FaEnvelope}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="tel">
                Le téléphone <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="tel"
                rules={validationRules.tel}
                step={0.01}
                placeholder="Exemple : 07 08 09 04 05"
                defaultValue={user.tel}
                id="tel"
                icon={BsFillTelephoneFill}
                isFlex1={false}
              />
            </div>
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left">
              <button
                type="button"
                className="recipe-form-delete-button"
                onClick={() => handleDeleteUser()}
              >
                Supprimer l'utilisateur
              </button>
            </div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Modifier l'utilisateur
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default AdminEditUser;
