import AdminEditUser from "../../components/Admin/Users/EditUser/AdminEditUser";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminEditUserPage = () => {
  return (
    <LayoutAdmin subHeading="Modifier un utilisateur de SafeEat">
      <AdminEditUser />
    </LayoutAdmin>
  );
};

export default AdminEditUserPage;
