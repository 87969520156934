import { FC, useEffect, useState } from "react";
import "./PublicRestaurantComponent.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectErrors,
  selectLoading,
  selectPublicRestaurant,
  selectUserAllergens,
} from "../../store/PublicRestaurant/PublicRestaurant";
import PublicRestaurantCard from "./PublicRestaurantCard/PublicRestaurantCard";
import { IRestaurantProfile } from "../../interface/IRestaurantProfile";
import { AppDispatch } from "../../interface/Types/AppDispatch";
import { useLocation } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  fetchRestaurantMenuByRestaurantName,
  fetchUserRestaurantProfileByRestaurantName,
} from "../../store/PublicRestaurant/Requests";

const PublicRestaurantComponent: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const restaurantPublic = useSelector(selectPublicRestaurant);
  const loading = useSelector(selectLoading);
  const errors = useSelector(selectErrors);
  const userAllergens = useSelector(selectUserAllergens);

  const [restaurantScanned, setRestaurantScanned] =
    useState<IRestaurantProfile | null>(null);

  useEffect(() => {
    const restaurantName = decodeURIComponent(location.pathname.split("/")[2])
      .toLowerCase()
      .replace(/[-\/\\^$*+?.()|[\]{}]/g, " ")
      .trim();

    console.log(restaurantName);

    if (restaurantName) {
      dispatch(fetchUserRestaurantProfileByRestaurantName(restaurantName));
      dispatch(fetchRestaurantMenuByRestaurantName(restaurantName));
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (restaurantPublic && restaurantPublic.id) {
      setRestaurantScanned(restaurantPublic);
    }
  }, [restaurantPublic]);

  return (
    <div className={`public-restaurant-container`}>
      {loading && <div className="loading-container">Chargement...</div>}
      {!loading && !restaurantScanned && Object.keys(errors).length === 0 && (
        <div className="pop-up-container">
          <p>Ce restaurant n'existe pas.</p>
        </div>
      )}
      {userAllergens && <PublicRestaurantCard />}
    </div>
  );
};

export default PublicRestaurantComponent;
