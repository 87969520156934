import LayoutAdmin from "../../layout/LayoutAdmin";
import AdminMenus from "../../components/Admin/Menus/AdminMenus/AdminMenus";

const AdminMenusPage = () => {
  return (
    <LayoutAdmin subHeading="Tous les menus du restaurant">
      <AdminMenus />
    </LayoutAdmin>
  );
};

export default AdminMenusPage;
