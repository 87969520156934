import { FC } from "react";
import LayoutPro from "../../layout/LayoutPro";
import AddRestaurantComponent from "../../components/Pro/Restaurant/Add/AddRestaurantComponent";

const AddRestaurantProfile: FC = () => {
  return (
    <LayoutPro subHeading="Ajouter un restaurant à votre compte !">
      <AddRestaurantComponent />
    </LayoutPro>
  );
};

export default AddRestaurantProfile;
