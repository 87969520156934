import { FC, useState } from "react";
import "./LayoutAdmin.css";
import NavbarAdmin from "../components/Admin/Navbar/NavbarPro";
import NavigationDrawerAdmin from "../components/Admin/NavigationDrawer/NavigationDrawerAdmin";

const LayoutAdmin: FC<{
  children: JSX.Element | string;
  subHeading: string;
}> = ({ children, subHeading }) => {
  const [showNavigationDrawer, setShowNavigationDrawer] =
    useState<boolean>(true);

  return (
    <main className="container">
      <NavigationDrawerAdmin
        showNavigationDrawer={showNavigationDrawer}
        setShowNavigationDrawer={setShowNavigationDrawer}
      />
      <div
        className="right-panel"
        style={{
          left: !showNavigationDrawer && 0,
          width: !showNavigationDrawer && "100vw",
        }}
      >
        <NavbarAdmin
          subHeading={subHeading}
          showNavigationDrawer={showNavigationDrawer}
        />
        <div className="content-wrapper-admin">{children}</div>
      </div>
    </main>
  );
};

export default LayoutAdmin;
