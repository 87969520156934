/**
 * Handles Redux Toolkit async thunks with additional UI feedback mechanisms.
 */

import { decapitalize } from "./capitalize";

function handleAsyncResponse(
  dispatch,
  action,
  { successTitle, successMessage, successDescription, navigateTo = null },
  { addToast, addNotification, navigate }
) {
  return dispatch(action)
    .unwrap()
    .then((payload) => {
      // Display a success toast
      addToast("success", successTitle, successMessage);

      // Add a success notification
      addNotification({
        title: successTitle,
        description: successDescription,
        originLink: navigateTo,
        type: "success",
      });

      // Navigate if a navigateTo URL is provided
      if (navigateTo) {
        navigate(navigateTo);
      }

      return payload;
    })
    .catch((error) => {
      console.log(error);

      let errors: string = "";
      let isFirstError: boolean = true;
      if (error.error === "Request failed with status code 401") {
        errors =
          "Vous n'êtes pas autorisé à effectuer cette action. Veuillez contacter un administrateur si le problème persiste. 😓";
      } else {
        Array.isArray(error)
          ? error.forEach((e) => {
              if (isFirstError) {
                errors += e.message + ", ";
                isFirstError = false; // Le premier message a été ajouté, tous les suivants ne le sont pas
              } else {
                errors += decapitalize(e.message) + ", ";
              }
            })
          : (errors =
              "Erreur interne. Veuillez contacter un administrateur si le problème persiste. 😓");
      }

      if (isFirstError) {
        errors = errors.trim().slice(0, -1);
      }

      addToast("error", "Erreur rencontrée", errors);
    });
}

export default handleAsyncResponse;
