import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { IRestaurantRecipe } from "../interface/IRestaurantRecipe";
import { capitalize } from "./capitalize";
import { formatDate } from "./getDate";
import LogoHorizontalGreen from "../assets/img/logo-horizontal-green.png";
import colors from "../components/constants/colors";
import { IAllergens } from "../interface/IAllergens";

export const loadImageAsBase64 = (url: string): Promise<string> => {
  return fetch(url)
    .then((response) => {
      if (!response.ok) throw new Error("Failed to load the image");
      return response.blob();
    })
    .then((blob) => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

// Mappage des allergènes spécifiques à des allergènes généraux
const allergenMapping: { [key: string]: string[] } = {
  "gluten/blé": ["blé", "gluten", "avoine", "orge", "seigle", "épeautre"],
  crustacés: [
    "crustacés",
    "crabe",
    "crevette",
    "homard",
    "langoustine",
    "écrevisse",
  ],
  œufs: ["œufs", "oeufs"],
  poisson: [
    "poisson",
    "saumon",
    "thon",
    "cabillaud",
    "aiglefin",
    "flétan",
    "dorade",
    "merlan",
    "maquereau",
    "mérou",
    "perche",
    "raie",
    "sardine",
    "sole",
    "tilapia",
    "truite",
    "turbot",
    "hareng",
    "colin",
    "bar",
    "rouget",
    "anguille",
    "anchois",
    "loup",
    "lotte",
    "morue",
    "vivaneau",
  ],
  "arachide / cacahuètes": ["arachide", "cacahuètes", "cacahuete"],
  soja: ["soja", "tofu", "tempeh"],
  lait: [
    "lait",
    "fromage",
    "lait de vache",
    "lait de bufflone",
    "lait de brebis",
    "lait de chèvre",
    "lait de coco",
  ],
  "fruit à coques": [
    "noix",
    "amande",
    "noisette",
    "noix de coco",
    "pignon de pin",
    "pistache",
    "châtaigne",
  ],
  céleri: ["céleri", "celeri"],
  moutarde: ["moutarde"],
  sésame: ["sésame", "sesame"],
  sulfites: ["sulfites", "vin", "vinaigre", "vinaigre balsamique"],
  lupin: ["lupin"],
  mollusques: [
    "mollusques",
    "calamar",
    "huître",
    "ormeau",
    "coquille Saint-Jacques",
    "praire",
    "bulot",
    "palourde",
    "bigorneau",
    "poulpe",
  ],
};

const containsAllergen = (allergens: IAllergens[], allergenKey: string) => {
  const allergenTerms = allergenMapping[allergenKey.toLowerCase()] || [];
  return allergens.some((a) => allergenTerms.includes(a.name.toLowerCase()));
};

const exportAllergensToPdfWidget = async (
  restaurantLogo: string,
  restaurantRecipes: IRestaurantRecipe[],
  restaurantName: string,
  restaurantAddress: string,
  restaurantEmail: string,
  restaurantTel: string
) => {
  try {
    const doc = new jsPDF({ orientation: "landscape" });
    const pageWidth = doc.internal.pageSize.getWidth();
    const logoSize = 30; // Square size for logos
    const margin = 15;

    const safeEatWidth = 41;
    const safeEatHeight = 10;

    // Add Restaurant logo
    if (restaurantLogo) {
      const logoData = await loadImageAsBase64(restaurantLogo);
      doc.addImage(logoData, "JPEG", margin, 15, logoSize, logoSize);
    }

    // Add SafeEat logo
    doc.addImage(
      LogoHorizontalGreen,
      "JPEG",
      pageWidth - safeEatWidth - margin,
      15,
      safeEatWidth,
      safeEatHeight
    );

    // Adjust the text positions by 35 units down
    const textYPosition = 15 + logoSize + 8;

    doc.setFontSize(14);
    doc.text(restaurantName, margin, textYPosition);
    const currentDate = new Date().toLocaleDateString("fr-FR");
    doc.setFontSize(10);
    doc.text(`Adresse : ${restaurantAddress}`, margin, textYPosition + 7);
    doc.text(`Email : ${restaurantEmail}`, margin, textYPosition + 13);
    doc.text(`Tel : ${restaurantTel}`, margin, textYPosition + 19);
    doc.text(`Date : ${currentDate}`, margin, textYPosition + 25);

    doc.setFontSize(14);
    doc.text(
      "Informations allergènes selon la loi n°1169/2011 (INCO)",
      margin,
      textYPosition + 38
    );

    const tableColumns = ["Nom", "Allergènes", "Dernière modification"];
    const tableRows = restaurantRecipes.map((recipe) => [
      recipe.name,
      recipe.allergens.map((a) => capitalize(a.name)).join(", "),
      formatDate(recipe.updated_at),
    ]);

    const primaryColorToRgb = colors.green;

    (doc as any).autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: textYPosition + 43,
      theme: "grid",
      headStyles: {
        fillColor: primaryColorToRgb,
        textColor: [0, 0, 0],
        halign: "center",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        alternateRowStyles: { fillColor: [240, 240, 240] },
        valign: "middle",
      },
    });

    // Calculate the position for the second table
    const firstTableFinalY = (doc as any).lastAutoTable.finalY + 10;

    // New table for known allergens
    const allergenTableColumns = [
      "Produits",
      "Gluten/Blé",
      "Crustacés",
      "Œufs",
      "Poisson",
      "Arachide / Cacahuètes",
      "Soja",
      "Lait",
      "Fruit à coques",
      "Céleri",
      "Moutarde",
      "Sésame",
      "Sulfites",
      "Lupin",
      "Mollusques",
    ];

    const allergenTableRows = restaurantRecipes.map((recipe) => {
      const row = [recipe.name];
      allergenTableColumns.slice(1).forEach((allergen) => {
        const contains = containsAllergen(recipe.allergens, allergen);
        row.push(contains ? "X" : "");
      });
      return row;
    });

    const rowFillColors = [
      [255, 229, 204], // Light orange
      [204, 255, 204], // Light green
      [204, 204, 255], // Light blue
      [255, 204, 255], // Light pink
    ];

    (doc as any).autoTable({
      head: [allergenTableColumns],
      body: allergenTableRows,
      startY: firstTableFinalY,
      theme: "grid",
      headStyles: {
        fillColor: primaryColorToRgb,
        textColor: [0, 0, 0],
        halign: "center",
      },
      bodyStyles: (rowIndex) => {
        return {
          fillColor: rowFillColors[rowIndex % rowFillColors.length],
          textColor: [0, 0, 0],
          valign: "middle",
        };
      },
    });

    doc.save(
      `Tableau-Allergènes-${restaurantName}-${currentDate.replace(
        /\//g,
        "-"
      )}.pdf`
    );
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

export default exportAllergensToPdfWidget;
