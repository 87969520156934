import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useProtectedRoute } from "../../hooks/useProtectedRoute";
import { selectIsAuthenticated } from "../../store/User";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  useProtectedRoute(); // L'appel du hook reste, mais nous n'utilisons pas sa valeur de retour
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/connexion" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
