import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import { ICreateRecipesWithFile } from "../../../../interface/IRestaurantRecipe";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { addMultipleRecipes } from "../../../../store/Admin/Recipes/Requests";
import FileInput from "../../../Reusables/InputField/FileInput";
import { FileValidationRules } from "../../../../helpers/Validators/RecipeValidationRules";
import GenericSelect from "../../../Reusables/InputField/GenericSelect";
import { selectAdminRestaurants } from "../../../../store/Admin/Restaurants/AdminRestaurants";

const AdminCreateRecipes = () => {
  const methods = useForm<ICreateRecipesWithFile>();
  const validationRules = FileValidationRules(methods.getValues);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const restaurants = useSelector(selectAdminRestaurants);
  const restaurantOptions = restaurants.map((restaurant) => ({
    id: restaurant.id,
    name: restaurant.name,
  }));

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const onSubmit = async (data: ICreateRecipesWithFile) => {
    const fileData = new FormData();

    if (data.file) {
      fileData.append("file", data.file);
      fileData.append(
        "restaurantProfileId",
        String(data.restaurantProfileId.id)
      );
    }

    handleAsyncResponse(
      dispatch,
      addMultipleRecipes(fileData),
      {
        successTitle: `Recettes ajoutées`,
        successMessage: `Les recettes ont été ajouté ! 🙌`,
        successDescription:
          "Les recetes ont été ajouté au restaurant: " +
          data.restaurantProfileId.name +
          " 🙌",
        navigateTo: "/admin/toutes-les-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Les données des recettes</h1>
          <p>Remplissez ce champ pour ajouter plusieurs recettes d'un coup.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="restaurantProfileId">
                Le restaurant associé <span className="is-mandatory">*</span>
              </label>
              <GenericSelect
                options={restaurantOptions}
                category={"association à un restaurant"}
                name={"restaurantProfileId"}
                rules={validationRules.restaurantId}
                returnObject
                searchBar
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="image">
                Le fichier excel <span className="is-mandatory">*</span>
              </label>
              <FileInput name={"file"} rules={validationRules.file} />
            </div>
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left"></div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Ajouter les recettes
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default AdminCreateRecipes;
