import AdminEditRecipe from "../../components/Admin/Recipes/Edit/AdminEditRecipe";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminEditRecipePage = () => {
  return (
    <LayoutAdmin subHeading="Modifier la recette du restaurant">
      <AdminEditRecipe />
    </LayoutAdmin>
  );
};

export default AdminEditRecipePage;
