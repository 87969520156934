import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenu } from "../../interface/Menus/IMenu";
import {
  createMenu,
  deleteRestaurantMenu,
  getAllMenus,
  toggleArchivedRestaurantMenu,
  updateRestaurantMenu,
} from "./Requests";
import { ApiErrorResponse } from "../../interface/RejectValue";

interface MenusState {
  menus: IMenu[];
  menu: IMenu;
  loading: boolean;
  errors: ApiErrorResponse[];
}

interface MenuResponse {
  id: number;
  response: any;
}

interface RejectValue {
  error: string;
}

const initialState: MenusState = {
  menus: [],
  menu: {
    name: "",
    recipes: {
      starter: [],
      dish: [],
      accompaniment: [],
      dessert: [],
    },
    price: 0,
    note: "",
    is_child_menu: false,
    archived: false,
    availability_start_time: {
      hour: "",
      minute: "",
    },
    availability_end_time: {
      hour: "",
      minute: "",
    },
  },
  loading: false,
  errors: [],
};

export const menusSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {
    addMenuToStore(state: any, action) {
      state.menu = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMenu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createMenu.fulfilled, (state, action: PayloadAction<IMenu>) => {
        state.loading = false;

        state.menus.push(action.payload);
      })
      .addCase(createMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllMenus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllMenus.fulfilled,
        (state, action: PayloadAction<IMenu[]>) => {
          state.loading = false;
          state.menus = action.payload;
        }
      )
      .addCase(getAllMenus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateRestaurantMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateRestaurantMenu.fulfilled,
        (state, action: PayloadAction<IMenu>) => {
          state.loading = false;
          const index = state.menus.findIndex(
            (menu) => menu.id === action.payload.id
          );
          if (index !== -1) {
            state.menus[index] = action.payload;
          }
        }
      )
      .addCase(updateRestaurantMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(toggleArchivedRestaurantMenu.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(toggleArchivedRestaurantMenu.fulfilled, (state, action) => {
        state.loading = false;

        const menuIndex = state.menus.findIndex(
          (recipe: IMenu) => recipe.id === action.payload.id
        );
        state.menus[menuIndex].archived = action.payload.archived;
      })
      .addCase(toggleArchivedRestaurantMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteRestaurantMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteRestaurantMenu.fulfilled,
        (state, action: PayloadAction<MenuResponse>) => {
          state.loading = false;
          state.menus = state.menus.filter(
            (menu) => menu.id !== action.payload.id
          );
        }
      )
      .addCase(
        deleteRestaurantMenu.rejected,
        (state, action: PayloadAction<RejectValue>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export const selectMenus = (state: { menus: { menus: IMenu[] } }) =>
  state.menus.menus;

export const selectMenu = (state: { menus: { menu: IMenu } }) =>
  state.menus.menu;

export const { addMenuToStore } = menusSlice.actions;

export default menusSlice.reducer;
