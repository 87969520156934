import "./assets/css/App.css";
import "./assets/fonts/TT-Commons-Light.otf";
import "./assets/fonts/TT-Commons-Regular.otf";
import "./assets/fonts/TT-Commons-Medium.otf";
import "./assets/fonts/TT-Commons-DemiBold.otf";
import "./assets/fonts/TT-Commons-Bold.otf";

import CustomRouter from "./components/router/CustomRouter";
import { useState } from "react";
import Menu from "./contexts/Menu";
import AddRestaurantImagePreview from "./contexts/AddRestaurantImagePreview";
import restaurantLogo from "./assets/img/placeholder-logo.svg";
import restaurantBanner from "./assets/img/placeholder-banner.png";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./store/Store";
import { NotificationProvider } from "./contexts/NotificationContext";
import { RestaurantPreview } from "./interface/Types/RestaurantPreview";
import ToastContainer from "./components/Toast/ToastContainer";
import { ToastProvider } from "./contexts/Toast/ToastProvider";

const App = () => {
  const [menu, setMenuContext] = useState(" la recette");

  const [restaurantPreview, setRestaurantPreview] = useState<RestaurantPreview>(
    {
      banner: restaurantBanner,
      logo: restaurantLogo,
      restaurantName: "Le nom de votre restaurant",
    }
  );

  let persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NotificationProvider>
          <Menu.Provider value={{ menu, setMenuContext }}>
            <AddRestaurantImagePreview.Provider
              value={{ restaurantPreview, setRestaurantPreview }}
            >
              <ToastProvider>
                <CustomRouter />
                <ToastContainer />
              </ToastProvider>
            </AddRestaurantImagePreview.Provider>
          </Menu.Provider>
        </NotificationProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
