import { FC, useEffect, useRef, useState } from "react";
import "./ProDashboard.css";
import "./ProDashboardMobile.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./../../../interface/Types/AppDispatch";
import Tabs from "../../Tabs/Tabs";
import useRestaurant from "../../../hooks/useRestaurant";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IRestaurantRecipe } from "../../../interface/IRestaurantRecipe";
import RecipeCounterWidget from "../Widgets/RecipeCounterWidget/RecipeCounterWidget";
import { getAllMenus } from "../../../store/Menus/Requests";
import { useToast } from "../../../contexts/Toast/ToastProvider";
import { handleAsyncGetResponse } from "../../../helpers/handleAsyncGetResponse";
import { fetchUserRestaurantsProfile } from "../../../store/Restaurant/Profile/Requests";
import { TbDownload } from "react-icons/tb";
import exportAllergensToPdfWidget, {
  loadImageAsBase64,
} from "../../../helpers/exportAllergensToPdfWidget";
import LastRecipesWidget from "../Widgets/LastRecipesWidget/LastRecipesWidget";
import LastMenusWidget from "../Widgets/LastMenusWidget/LastMenusWidget";
import RecipesByDateChart from "../Widgets/RecipesByDateChart/RecipesByDateChart";
import MenusByDateChart from "../Widgets/MenusByDateChart/MenusByDateChart";
import MenuCounterWidget from "../Widgets/MenuCounterWidget/MenuCounterWidget";

const ProDashboard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { restaurant, menus } = useRestaurant();
  const { addToast, clearToasts } = useToast();

  const [restaurantRecipes, setRestaurantRecipes] = useState<
    IRestaurantRecipe[]
  >([]);

  const dataLoadedRef = useRef<boolean>(false);

  const handleExportAllergens = async () => {
    const imageUrl = process.env.REACT_APP_BASE_URL + "uploads/";
    const restaurantLogo =
      restaurant.logo && restaurant.logo.breakpoints
        ? imageUrl + restaurant.logo.breakpoints.thumbnail.name
        : null;

    const logo = await loadImageAsBase64(restaurantLogo);

    exportAllergensToPdfWidget(
      logo,
      restaurantRecipes,
      restaurant.name,
      restaurant.address,
      restaurant.email,
      restaurant.tel
    );
  };

  useEffect(() => {
    if (!dataLoadedRef.current) {
      handleAsyncGetResponse(
        dispatch,
        fetchUserRestaurantsProfile(),
        addToast,
        "restaurant",
        (payload) => {
          handleAsyncGetResponse(
            dispatch,
            getAllMenus(payload.id),
            addToast,
            "menu"
          );
        }
      );
      setRestaurantRecipes(restaurant.restaurantRecipe);
      dataLoadedRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (restaurant.restaurantRecipe) {
      setRestaurantRecipes(restaurant.restaurantRecipe);
    }
  }, [restaurant.restaurantRecipe]);

  useEffect(() => {
    clearToasts();
  }, [clearToasts]);

  return (
    <div className={"dashboard-container"}>
      <Tabs restaurant={restaurant} canAddInRestaurantPage={false} />
      <div className="dashboard-data-container">
        <div className="dashboard-card-container">
          <RecipeCounterWidget />
          <MenuCounterWidget />
          <div
            className={
              "recipe-counter-widget-card recipe-counter-widget-card-chart"
            }
          >
            <div className="top top-chart">
              <div className="heading-top">Classement de vos allergènes</div>
            </div>
            <div
              className="bottom"
              onClick={() => navigate("/pro/widgets/classement-des-allergènes")}
            >
              <div>Détails</div>
              <MdOutlineKeyboardDoubleArrowRight
                className="card-arrow"
                style={{ cursor: "pointer" }}
                size={19}
              />
            </div>
          </div>
          <div
            className={
              "recipe-counter-widget-card recipe-counter-widget-card-chart"
            }
          >
            <div className="top top-chart">
              <div className="heading-top">Vos retours</div>
            </div>
            <div
              className="bottom"
              onClick={() => navigate("/pro/widgets/reviews")}
            >
              <div>Détails</div>
              <MdOutlineKeyboardDoubleArrowRight
                className="card-arrow"
                style={{ cursor: "pointer" }}
                size={19}
              />
            </div>
          </div>
          <div
            className={
              "recipe-counter-widget-card recipe-counter-widget-card-chart"
            }
          >
            <div className="top top-chart">
              <div className="heading-top">
                Exportez le tableau des allergènes
              </div>
            </div>
            <div className="bottom" onClick={() => handleExportAllergens()}>
              <div>Exportez</div>
              <TbDownload
                className="card-arrow"
                style={{ cursor: "pointer" }}
                size={19}
              />
            </div>
          </div>
        </div>
        <div className="dashboard-informations-widgets-container">
          {restaurantRecipes && restaurantRecipes.length !== 0 ? (
            <>
              <LastRecipesWidget recipes={restaurantRecipes} />
              {menus && menus.length !== 0 ? (
                <LastMenusWidget menus={menus} />
              ) : (
                <div>Aucun menu pour ce restaurant.</div>
              )}
              <RecipesByDateChart recipes={restaurantRecipes} />
              {menus && menus.length !== 0 ? (
                <MenusByDateChart menus={menus} />
              ) : (
                <div>Aucun menu pour ce restaurant.</div>
              )}
            </>
          ) : (
            <div>Aucun menu ou recette de disponible pour le moment.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProDashboard;
