import { FC } from "react";
import "./../css-pages/widget.css";
import LayoutPro from "../../layout/LayoutPro";
import ReviewWidgetComponent from "../../components/Pro/Widgets/ReviewWidget/ReviewWidgetComponent";

const ReviewWidget: FC = () => {
  return (
    <LayoutPro subHeading="Le graphique du classement de vos allergènes !">
      <main className="widget">
        <ReviewWidgetComponent />
      </main>
    </LayoutPro>
  );
};

export default ReviewWidget;
