import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import userReducer from "./User";
import restaurantsReducer from "./Restaurant";
import publicRestaurantsReducer from "./PublicRestaurant/PublicRestaurant";
import adminRestaurantsReducer from "./Admin/Restaurants/AdminRestaurants";
import adminUsersReducer from "./Admin/Users/AdminUsers";
import MenusReducer from "./Menus/Menus";
import adminRecipesReducer from "./Admin/Recipes/Recipes";
import adminMenusReducer from "./Admin/Menus/Menus";
import adminDocumentsReducer from "./Admin/Documents/Documents";

const reducers = combineReducers({
  user: userReducer,
  restaurants: restaurantsReducer,
  menus: MenusReducer,
  publicRestaurants: publicRestaurantsReducer,
  adminUsers: adminUsersReducer,
  adminRestaurants: adminRestaurantsReducer,
  adminRecipes: adminRecipesReducer,
  adminMenus: adminMenusReducer,
  adminDocuments: adminDocumentsReducer,
});

export type RootState = ReturnType<typeof reducers>;

const persistConfig = {
  key: "safeeat",
  storage,
  timeout: 250,
  whitelist: [
    "user",
    "restaurants",
    "menus",
    "publicRestaurants",
    "adminUsers",
    "adminRestaurants",
    "adminRecipes",
    "adminMenus",
    "adminDocuments",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
