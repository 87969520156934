import { createContext } from "react";

interface IMenu {
  menu: string;
  setMenuContext: (value: string) => void;
}

const defaultState: IMenu = {
  menu: " la recette",
  setMenuContext: (value: string) => {},
};

export default createContext<IMenu>(defaultState);
