import AdminRecipes from "../../components/Admin/Recipes/AdminRecipes/AdminRecipes";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminRecipesPage = () => {
  return (
    <LayoutAdmin subHeading="Toutes les recettes du restaurant">
      <AdminRecipes />
    </LayoutAdmin>
  );
};

export default AdminRecipesPage;
