import { FC, useRef } from "react";
import "./PublicAllergensModal.css";
import { IPublicAllergensInformationModal } from "../../../../../../interface/Public/IPublic";
import useOutsideAlerter from "../../../../../../hooks/useOutsideAlerter";
import { getTextColor } from "../../../../../../helpers/color";
import { capitalize } from "../../../../../../helpers/capitalize";
import { formatDate } from "../../../../../../helpers/getDate";
import { BsCalendarDate } from "react-icons/bs";

const PublicAllergensInformationModal: FC<IPublicAllergensInformationModal> = ({
  toggleAllergensInformationModal,
  setToggleAllergensInformationModal,
  name,
  date,
  primaryColor,
}) => {
  const allergensModalRef = useRef(null);
  const textColor: "black" | "white" = getTextColor(primaryColor);

  useOutsideAlerter(allergensModalRef, () =>
    setToggleAllergensInformationModal(false)
  );

  return (
    <div className="public-allergens-modal-overlay">
      <div
        className="public-allergens-modal-container"
        style={{
          width: "85%",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          padding: "1.5rem",
        }}
        ref={allergensModalRef}
      >
        <div
          className="public-allergens-modal-title"
          style={{ color: "#000000", textAlign: "left" }}
        >
          Informations relatives à : {capitalize(name)}
        </div>
        <div className="public-allergens-modal-list">
          <div className="public-allergens-modal-information-date">
            La recette a été mise à jour le : {formatDate(date)}{" "}
            <BsCalendarDate color={primaryColor} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicAllergensInformationModal;
