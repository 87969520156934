import { createAsyncThunk } from "@reduxjs/toolkit";
import { restaurantRecipe } from "../../../api/api";
import { ApiErrorResponse } from "../../../interface/RejectValue";
import { IRestaurantRecipe } from "../../../interface/IRestaurantRecipe";

export const fetchAllAllergens = createAsyncThunk(
  "recipes/fetchAllAllergens",
  async (_, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.getAllAllergens();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewRestaurantRecipe = createAsyncThunk<
  IRestaurantRecipe, // Type de la réponse en cas de succès
  FormData, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "restaurants/addNewRestaurantRecipe",
  async (restaurantRecipeData: FormData, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.createRestaurantRecipe(
        restaurantRecipeData
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const updateRestaurantRecipe = createAsyncThunk<
  IRestaurantRecipe, // Type de la réponse en cas de succès
  { id: number; formData: FormData }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "restaurants/updateRestaurantRecipe",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      // Appel API pour la mise à jour de la recette
      const response = await restaurantRecipe.updateRestaurantRecipe(
        formData,
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const toggleArchivedRestaurantRecipe = createAsyncThunk<
  { archived: boolean; id: number }, // Type de la réponse en cas de succès
  { id: number; checked: boolean }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "restaurants/toggleArchivedRestaurantRecipe",
  async ({ id, checked }, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.updateToggleRestaurantRecipe(
        { archived: checked },
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteRestaurantRecipe = createAsyncThunk(
  "restaurants/deleteRestaurantRecipe",
  async (recipeId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantRecipe.deleteRestaurantRecipe(recipeId);
      return { recipeId, ...response }; // Renvoie l'ID de la recette supprimée pour mise à jour de l'état
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
