import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { MdAutorenew, MdSend, MdRemove } from "react-icons/md";

interface DocumentStatusIconProps {
  status: string; // Statut unique sous forme de chaîne de caractères
}

const DocumentStatusIcon: React.FC<DocumentStatusIconProps> = ({ status }) => {
  // Sélection de l'icône basée sur le statut
  const getIcon = (status: string) => {
    switch (status) {
      case "Envoyé":
        return <MdSend size={25} color="#4A90E2" />;
      case "En cours":
        return <MdAutorenew size={25} color="#F5A623" />;
      case "Validé":
        return <FaCheck size={25} color="#4CAF50" />;
      case "Refusé":
        return <FaTimes size={25} color="#D32F2F" />;
      default:
        return <MdRemove size={25} color="#757575" />; // Aucun ou inconnu
    }
  };

  return getIcon(status);
};

export default DocumentStatusIcon;
