import { createAsyncThunk } from "@reduxjs/toolkit";
import { restaurantMenu, restaurantProfile } from "../../api/api";

export const fetchUserRestaurantProfileByRestaurantName = createAsyncThunk(
  "restaurants/fetchUserRestaurantsProfileByRestaurantName",
  async (restaurantName: string, { rejectWithValue }) => {
    try {
      const response =
        await restaurantProfile.getUserRestaurantProfileByRestaurantName(
          restaurantName
        );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRestaurantMenuByRestaurantName = createAsyncThunk(
  "restaurants/fetchRestaurantMenuByRestaurantName",
  async (restaurantName: string, { rejectWithValue }) => {
    try {
      const response = await restaurantMenu.getRestaurantMenuByRestaurantName(
        restaurantName
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
