import "./ReviewWidgetComponent.css";
import { Fragment, useEffect, useState } from "react";
import Tabs from "../../../Tabs/Tabs";
import useRestaurant from "../../../../hooks/useRestaurant";
import { ReviewHeader } from "./ReviewHeader";
import { Review } from "../../../../interface/Review";
import { restaurantProfile } from "../../../../api/api";
import { useMediaQuery } from "react-responsive";
import "./../../../Table/TableProps.css";
import "./../../../Table/TablePropsMobile.css";
import { IAllergens } from "../../../../interface/IAllergens";
import LogoFilledDarkerGreen from "./../../../../assets/img/Logo-Filled-DarkerGreen.svg"; // Path to the filled logo SVG
import "./../../../Public/Review/RatingStars/RatingStars.css";

const ReviewWidgetComponent = () => {
  const { restaurant } = useRestaurant();

  const [reviews, setReviews] = useState<Review[]>([]);

  const getAllReviews = async () => {
    const response = await restaurantProfile.getAllReviews(restaurant.id);

    const sortedReviews = response.sort((a, b) => {
      const dateA = new Date(a.updated_at).getTime();
      const dateB = new Date(b.updated_at).getTime();

      return dateB - dateA;
    });

    setReviews(sortedReviews);
  };

  const isMobile = useMediaQuery({ maxWidth: 800 });

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <div className="reviews-container">
      <Tabs restaurant={restaurant} canAddInRestaurantPage={false} />
      {reviews.length !== 0 ? (
        <div className="reviews-content-container">
          <div className="table-title-container">
            <div className="left">Commentaires des utilisateurs</div>
          </div>
          <table>
            <thead>
              <tr>
                {ReviewHeader?.map((head: { title: string }, idx: number) => (
                  <th key={idx}>{head.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {reviews &&
                reviews.map((review, idx: number) => (
                  <tr className="row" key={idx}>
                    <td>{review.id}</td>
                    <td>
                      {Array.from({ length: review.rating }, (_, index) => (
                        <img
                          src={LogoFilledDarkerGreen}
                          alt="Nombre d'étoiles (logo safeeat)"
                          width={18}
                          height={18}
                        />
                      ))}
                    </td>
                    <td>{review.description}</td>
                    <td>
                      {review.allergens && review.allergens.length > 0 ? (
                        review.allergens.map(
                          (allergen: IAllergens, idx: number) => (
                            <div key={idx}>
                              {allergen.name[0].toUpperCase() +
                                allergen.name.substring(1)}
                            </div>
                          )
                        )
                      ) : (
                        <div>Aucun allergènes</div>
                      )}
                    </td>
                    <td>{review.recommend ? "Oui" : "Non"}</td>
                    <td>
                      {new Date(review.updated_at).toLocaleDateString("fr-FR", {
                        day: "2-digit", // 2 chiffres pour le jour
                        month: "long", // mois en toutes lettres
                        year: "numeric", // année sur 4 chiffres
                      })}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>Aucun commentaire n'est disponible.</div>
      )}
    </div>
  );
};

export default ReviewWidgetComponent;
