import AdminCreateUser from "../../components/Admin/Users/CreateUser/AdminCreateUser";
import LayoutAdmin from "../../layout/LayoutAdmin";

const AdminCreateUserPage = () => {
  return (
    <LayoutAdmin subHeading="Créer un compte pour un restaurateur">
      <AdminCreateUser />
    </LayoutAdmin>
  );
};

export default AdminCreateUserPage;
