import { FC, useState } from "react";
import "./PublicRecipeCard.css";
import { IPublicRecipeCard } from "../../../../../interface/Public/IPublic";
import { formatDate } from "../../../../../helpers/getDate";
import { getTextColor } from "../../../../../helpers/color";
import PublicAllergensModal from "./PublicAllergensModal/PublicAllergensModal";
import { IoIosAddCircle, IoIosInformationCircle } from "react-icons/io";
import colors from "../../../../constants/colors";
import PublicAllergensInformationModal from "./PublicAllergensModal/PublicAllergensInformationModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../interface/Types/AppDispatch";
import {
  addRecipeToUserChoices,
  selectPublicUserChoices,
} from "../../../../../store/PublicRestaurant/PublicRestaurant";

const PublicRecipeCard: FC<IPublicRecipeCard> = ({
  id,
  imageUrl,
  name,
  description,
  allergens,
  updated_at,
  category,
  primaryColor,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const userChoices = useSelector(selectPublicUserChoices) || {
    entrée: [],
    plat: [],
    accompagnement: [],
    dessert: [],
  };

  const [toggleAllergensModal, setToggleAllergensModal] =
    useState<boolean>(false);
  const [toggleAllergensInformationModal, setToggleAllergensInformationModal] =
    useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);

  const handleAllergensModal = () => {
    setToggleAllergensModal(!toggleAllergensModal);
  };

  const handleAllergensInformationModal = () => {
    setToggleAllergensInformationModal(!toggleAllergensInformationModal);
  };

  const handleAddRecipe = () => {
    const recipeData = {
      category,
      recipe: {
        id,
        imageUrl: imageUrl || null,
        name,
        description,
        allergens,
        updated_at,
      },
    };

    const recipeExists = userChoices[category].some(
      (recipe) => recipe.id === id
    );

    if (recipeExists) {
      alert("Cette recette est déjà dans votre panier !");
      return;
    }

    if (["entrée", "plat", "accompagnement", "dessert"].includes(category)) {
      dispatch(addRecipeToUserChoices(recipeData));
      setIsAdded(true);
    } else {
      console.error(`Catégorie non valide: ${category}`);
    }
  };

  return (
    <>
      <div className="public-recipe-card-container">
        <div className="public-recipe-card-img-container">
          {imageUrl ? (
            <img
              src={imageUrl}
              alt={`Image de la recette ${name}`}
              className="public-recipe-card-img"
            />
          ) : (
            <div className="public-recipe-card-img"></div>
          )}
        </div>
        <div className="public-recipe-card-informations">
          <div className="public-recipe-card-informations-title">{name}</div>
          <div className="public-recipe-card-informations-description">
            {description}
          </div>
          <div className="public-recipe-card-informations-bottom">
            <div
              className="public-recipe-card-informations-allergens-btn pointer"
              style={{ color: primaryColor }}
              onClick={handleAllergensModal}
            >
              Afficher les allergènes
            </div>
            <div className="public-recipe-card-action-btn">
              <IoIosInformationCircle
                size={25}
                color={colors.blue}
                onClick={handleAllergensInformationModal}
                style={{ cursor: "pointer" }}
              />
              <IoIosAddCircle
                size={25}
                color={colors.green}
                onClick={handleAddRecipe}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      </div>
      {toggleAllergensModal && (
        <PublicAllergensModal
          toggleAllergensModal={toggleAllergensModal}
          setToggleAllergensModal={setToggleAllergensModal}
          allergens={allergens}
          category={category}
          name={name}
          primaryColor={primaryColor}
        />
      )}
      {toggleAllergensInformationModal && (
        <PublicAllergensInformationModal
          toggleAllergensInformationModal={toggleAllergensInformationModal}
          setToggleAllergensInformationModal={
            setToggleAllergensInformationModal
          }
          name={name}
          date={updated_at}
          primaryColor={primaryColor}
        />
      )}
    </>
  );
};

export default PublicRecipeCard;
