// Utility function to format date
function formatToLocaleDate(date, locale = "fr-FR") {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return new Date(date).toLocaleDateString(locale, options);
}

// React component to display the current date
export function CurrentDate() {
  const now = new Date();
  const formattedDate = formatToLocaleDate(now); // Use the utility function
  return <div>{formattedDate}</div>;
}

// Function to format a given date
export function formatDate(date: Date | string) {
  return formatToLocaleDate(date); // Reuse the utility function
}

export function parseTime(timeString: string) {
  if (!timeString || !timeString.includes(":")) {
    return { hour: "", minute: "" };
  }
  const [hour, minute] = timeString.split(":");
  return { hour, minute };
}
