import { FormProvider, useForm } from "react-hook-form";
import "./EditProfileComponent.css";
import { IEditUser, IUser } from "../../../../interface/IUser";
import handleAxiosError from "../../../../helpers/handleAxiosError";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserProfiles } from "../../../../api/api";
import { useNotification } from "../../../../contexts/NotificationContext";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import { useUser } from "../../../../hooks/useUser";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { addUserToStore } from "../../../../store/User";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import { AuthUpdateUserValidationRules } from "../../../../helpers/Validators/AuthUpdateValidationRules";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import { FaEnvelope, FaUser } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";

const EditProfileComponent = () => {
  const methods = useForm<IUser>();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { addToast } = useToast();
  const { addNotification } = useNotification();

  const { user } = useUser();

  const onSubmit = async (data: IEditUser) => {
    try {
      const formData = new FormData();

      if (data.avatar) formData.append("avatar", data.avatar);
      if (data.firstName) formData.append("firstName", data.firstName);
      if (data.lastName) formData.append("lastName", data.lastName);
      if (data.email) formData.append("email", data.email);
      if (data.tel) formData.append("tel", data.tel);

      const response = await UserProfiles.updateUser(formData);

      dispatch(addUserToStore(response));

      addToast("success", "Profil modifié", `Votre profil a été modifié ! 🙌`);

      addNotification({
        title: `Votre profil a été modifié ! 🙌`,
        description:
          "Vous avez modifié les données de votre compte professionnel SafeEat.",
        originLink: "/pro/mon-profil",
        type: "userProfile",
      });

      navigate("/pro/tableau-de-bord");
    } catch (error: any) {
      handleAxiosError(error);
      addToast(
        "error",
        "Erreur de mise à jour du profil",
        `Une erreur a été détecté lors de la modification du profil. 😓`
      );
    }

    navigate("/pro/tableau-de-bord");
  };

  const imagePath = process.env.REACT_APP_BASE_URL + "uploads/";
  const avatarPreview =
    user.avatar && user.avatar.breakpoints
      ? imagePath + user.avatar.breakpoints.thumbnail.name
      : null;

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Modifier votre profil</h1>
          <p>Remplissez ces champs pour modifier votre profil.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="logo">Votre photo</label>
              <ImageInput
                name="avatar"
                rules={AuthUpdateUserValidationRules.avatar}
                defaultValue={user.avatar}
                imageUrl={avatarPreview}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="firstName">
                Votre prénom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="firstName"
                rules={AuthUpdateUserValidationRules.firstName}
                defaultValue={user.firstName}
                placeholder="Exemple : Patrick"
                id="firstName"
                icon={FaUser}
              />
            </div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="lastName">
                Votre nom<span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="lastName"
                rules={AuthUpdateUserValidationRules.lastName}
                defaultValue={user.lastName}
                placeholder="Exemple : Dupont"
                id="lastName"
                icon={FaUser}
              />
            </div>
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="email">
                Votre email <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                type="email"
                name="email"
                rules={AuthUpdateUserValidationRules.email}
                defaultValue={user.email}
                placeholder="Exemple : patrick@safeeat.fr"
                id="email"
                icon={FaEnvelope}
              />
            </div>
          </div>
          <div className="recipe-input-wrapper-container">
            <label htmlFor="tel">
              Le téléphone mobile <span className="is-mandatory">*</span>
            </label>
            <GenericInput
              name="tel"
              type="tel"
              rules={AuthUpdateUserValidationRules.tel}
              defaultValue={user.tel}
              placeholder="Exemple : 07 08 09 04 05"
              id="tel"
              icon={BsFillTelephoneFill}
              isFlex1={false}
            />
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left"></div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Modifier mon profil
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default EditProfileComponent;
