import { createContext } from "react";
import logo from "./../assets/img/placeholder-logo.svg";
import banner from "./../assets/img/placeholder-banner.png";

type RestaurantPreviewType = {
  banner?: string;
  logo?: string;
  restaurantName: string;
};

interface RestaurantImagePreview {
  restaurantPreview: RestaurantPreviewType;
  setRestaurantPreview: React.Dispatch<
    React.SetStateAction<RestaurantPreviewType>
  >;
}

const defaultRestaurantImageContext: RestaurantImagePreview = {
  restaurantPreview: {
    banner: banner,
    logo: logo,
    restaurantName: "Le nom de votre restaurant",
  },
  setRestaurantPreview: () => {},
};

const RestaurantImageContext = createContext<RestaurantImagePreview>(
  defaultRestaurantImageContext
);

export default RestaurantImageContext;
