import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../../interface/RejectValue";
import { restaurantProfile } from "../../../api/api";
import { IRestaurantProfile } from "../../../interface/IRestaurantProfile";

export const fetchUserRestaurantsProfile = createAsyncThunk(
  "restaurants/fetchUserRestaurantsProfiles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.getUserRestaurantsProfile();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewRestaurantProfile = createAsyncThunk<
  IRestaurantProfile, // Specify the expected type of the data returned by the API
  FormData, // Parameters passed to the thunk action
  { rejectValue: ApiErrorResponse } // Type of the error object
>(
  "restaurants/addNewRestaurantProfile",
  async (restaurantData: FormData, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.createRestaurantProfile(
        restaurantData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const updateRestaurantProfile = createAsyncThunk<
  IRestaurantProfile, // Type de la réponse en cas de succès
  { id: number; formData: FormData }, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>(
  "restaurants/updateRestaurantProfile",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      // Appel API pour la mise à jour du profil du restaurant
      const response = await restaurantProfile.updateRestaurantProfile(
        formData,
        id
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteRestaurantProfile = createAsyncThunk(
  "restaurants/deleteRestaurantProfile",
  async (restaurantId: number, { rejectWithValue }) => {
    try {
      const response = await restaurantProfile.deleteRestaurantProfile(
        restaurantId
      );
      return { restaurantId, ...response }; // Renvoie les données de réponse pour une utilisation dans le fulfilled reducer
    } catch (error: any) {
      return rejectWithValue(error.response.data); // Gère les erreurs en renvoyant les données d'erreur
    }
  }
);
