import { useEffect, useState } from "react";
import { IRestaurantRecipe } from "../../../../interface/IRestaurantRecipe";
import { FormProvider, useForm } from "react-hook-form";
import { FaTag, FaEuroSign, FaPencilAlt } from "react-icons/fa";
import ImagePlaceholder from "../../../../assets/img/placeholder-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../interface/Types/AppDispatch";
import { selectRestaurant } from "../../../../store/Restaurant";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../hooks/useNotification";
import { useToast } from "../../../../contexts/Toast/ToastProvider";
import handleAsyncResponse from "../../../../helpers/handleAsyncResponse";
import AllergensSelect from "../../../Reusables/InputField/AllergensSelect";
import GenericSelect from "../../../Reusables/InputField/GenericSelect";
import ImageInput from "../../../Reusables/InputField/ImageInput";
import { GenericInput } from "../../../Reusables/InputField/InputField";
import categoryOptionsModel from "../../../../models/categoryOptionsModel";
import {
  deleteRecipeForAdmin,
  updateRecipeForAdmin,
} from "../../../../store/Admin/Recipes/Requests";
import { selectAdminRecipe } from "../../../../store/Admin/Recipes/Recipes";
import { RecipeValidationRules } from "../../../../helpers/Validators/RecipeValidationRules";

const AdminEditRecipe = () => {
  const methods = useForm<IRestaurantRecipe>();
  const validationRules = RecipeValidationRules(methods.getValues);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const recipe = useSelector(selectAdminRecipe);
  const restaurant = useSelector(selectRestaurant);

  const { addNotification } = useNotification();
  const { addToast, clearToasts } = useToast();

  const imagePath = process.env.REACT_APP_BASE_URL + "uploads/";
  const imagePreview =
    recipe.image && recipe.image.breakpoints
      ? imagePath + recipe.image.breakpoints.thumbnail.name
      : ImagePlaceholder;

  const description = methods.watch("description");
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    const words = description ? description.match(/\b\w+\b/g) : [];
    setWordCount(words ? words.length : 0);
  }, [description, recipe]);

  const onSubmit = async (data: IRestaurantRecipe) => {
    if (data.archived !== recipe.archived) {
      data.archived = true;
    }

    if (!data.price) {
      data.price = null;
    }

    const formData = new FormData();

    if (restaurant.id)
      formData.append("restaurantProfileId", restaurant.id.toString());

    // Ajouter des champs seulement s'ils ont changé
    if (data.category !== recipe.category)
      formData.append("category", data.category);
    if (data.name !== recipe.name) formData.append("name", data.name);
    if (data.description !== recipe.description)
      formData.append("description", data.description);
    if (JSON.stringify(data.allergens) !== JSON.stringify(recipe.allergens))
      formData.append("allergens", JSON.stringify(data.allergens));
    if (data.price !== recipe.price)
      formData.append("price", data.price.toString());
    formData.append("archived", String(data.archived || recipe.archived));

    // Gestion de l'image différemment car il s'agit d'un fichier
    if (data.image && data.image !== recipe.image) {
      formData.append("image", data.image);
    }

    // Appel API pour mise à jour
    const id = recipe.id;
    handleAsyncResponse(
      dispatch,
      updateRecipeForAdmin({
        id,
        formData,
      }),
      {
        successTitle: `Recette modifiée`,
        successMessage: `La recette ${
          data.name || recipe.name
        } a été modifiée ! 🙌`,
        successDescription: `La recette du restaurant ${restaurant.name} a été modifiée 🙌`,
        navigateTo: "/admin/toutes-les-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleDeleteRecipe = () => {
    clearToasts();
    handleAsyncResponse(
      dispatch,
      deleteRecipeForAdmin(recipe.id),
      {
        successTitle: "Recette supprimée",
        successMessage: `Recette ${recipe.name} a été supprimée ! 🙌`,
        successDescription:
          "Vous avez supprimé la recette : " +
          recipe.name +
          " de votre restaurant " +
          restaurant.name,
        navigateTo: "/admin/toutes-les-recettes",
      },
      { addToast, addNotification, navigate }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="recipe-padding">
        <form
          className="recipe-form-container"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h1>Les données de la recette</h1>
          <p>Remplissez ces champs pour modifier la recette.</p>
          <div>
            <div className="recipe-input-wrapper-container">
              <label htmlFor="image-input">
                L'image <span className="is-mandatory">*</span>
              </label>
              <ImageInput
                name="image"
                rules={validationRules.image}
                defaultValue={recipe.image}
                imageUrl={imagePreview}
              />
            </div>
          </div>
          <div className="form-recipe-grid">
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-end" }}
            >
              <label htmlFor="name">
                Le nom <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="name"
                rules={validationRules.name}
                placeholder="Ecrivez le nom de votre recette ici"
                defaultValue={recipe.name}
                id="name"
                icon={FaTag}
              />
            </div>
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-end" }}
            >
              <label htmlFor="name">
                La catégorie <span className="is-mandatory">*</span>
              </label>
              <GenericSelect
                name="category"
                rules={validationRules.category}
                options={categoryOptionsModel}
                defaultValue={recipe.category}
                category={"catégorie"}
              />
            </div>
          </div>
          <div className="form-recipe-grid">
            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="description">
                La description <span className="is-mandatory">*</span>
              </label>
              <GenericInput
                name="description"
                rules={validationRules.description}
                placeholder="Ecrivez la description de votre recette ici"
                defaultValue={recipe.description}
                id="name"
                icon={FaPencilAlt}
              />
              <div className="recipe-form-input-words">
                {wordCount} / 50 mots utilisés
              </div>
            </div>

            <div
              className="recipe-input-wrapper-container"
              style={{ justifyContent: "flex-start" }}
            >
              <label htmlFor="price">Le prix</label>
              <GenericInput
                name="price"
                rules={validationRules.price}
                min={0}
                step={0.01}
                max={1000}
                placeholder="Le prix de votre recette"
                defaultValue={recipe.price}
                id="price"
                icon={FaEuroSign}
                isFlex1={false}
              />
            </div>
          </div>
          <div className="recipe-input-wrapper-container">
            <label htmlFor="price">
              Les allergènes <span className="is-mandatory">*</span>
            </label>
            <AllergensSelect
              name="allergens"
              rules={validationRules.allergens}
              defaultValue={recipe.allergens}
            />
          </div>
          <div className="recipe-form-buttons-container">
            <div className="recipe-form-actions-left">
              <button
                type="button"
                className="recipe-form-delete-button"
                onClick={() => handleDeleteRecipe()}
              >
                Supprimer la recette
              </button>
            </div>
            <div className="recipe-form-actions-right">
              <button
                type="button"
                className="recipe-form-reset-button"
                onClick={() => methods.reset()}
              >
                Réinitialiser
              </button>

              <button type="submit" className="recipe-form-next-button">
                Modifier la recette
              </button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default AdminEditRecipe;
