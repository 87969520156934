import { FC } from "react";
import { useUser } from "../../hooks/useUser";
import "./Avatar.css";

const Avatar: FC<{ width: string; height: string }> = ({ width, height }) => {
  const { user, getProfilePicture } = useUser();
  return (
    <>
      {user.avatar && user.avatar.breakpoints ? (
        <img
          src={getProfilePicture()}
          width={width}
          height={height}
          style={{ borderRadius: "50%" }}
          alt="Ma photo de profile"
        />
      ) : (
        <div
          className="avatar-background"
          style={{ width: width, height: height }}
        >
          {user.firstName[0].toUpperCase() +
            " " +
            user.lastName[0].toUpperCase()}
        </div>
      )}
    </>
  );
};

export default Avatar;
