export const RecipeValidationRules = (getValues = undefined) => ({
  restaurantId: {
    required: "Le restaurant est requis",
  },
  name: {
    required: "Le nom de la recette est requis",
    maxLength: {
      value: 150,
      message: "Le nom de la recette ne doit pas dépasser 150 caractères",
    },
  },
  category: {
    required: "La catégorie de la recette est requise",
    validate: (value) =>
      [
        "Entrée",
        "Plat",
        "Accompagnement",
        "Dessert",
        "Cocktail",
        "Mocktail",
        "Sans Alcool",
      ].includes(value) ||
      "La catégorie doit être une Entrée, un Plat, un Accompagnement, un Dessert, un Cocktail, un Mocktail ou un Sans Alcool",
  },
  description: {
    required: "La description de la recette est requise",
    maxLength: {
      value: 200,
      message: "La description ne doit pas dépasser 200 caractères",
    },
  },
  price: {
    min: {
      value: 0,
      message: "Le prix ne peut pas être négatif",
    },
    max: {
      value: 1000,
      message: "Le prix ne peut pas dépasser 1000€",
    },
  },
  image: {
    validate: {
      isImage: (file) => {
        if (!file) return true;
        const supportedFormats = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
        ];
        return supportedFormats.includes(file.type || file.mimeType)
          ? true
          : "Veuillez sélectionner une image valide (JPEG, JPG, PNG, WEBP).";
      },
      isUnderSizeLimit: (file) => {
        if (!file) return true;
        const maxSizeInBytes = 2097152; // 2MB
        return file.size <= maxSizeInBytes
          ? true
          : "L'image doit être moins de 2MB.";
      },
    },
  },
  allergens: {
    required: "Au moins un allergène est requis",
    validate: (value) =>
      value && value.length > 0
        ? true
        : "Veuillez sélectionner au moins un allergène.",
  },
});

export const FileValidationRules = (getValues = undefined) => ({
  restaurantId: {
    required: "Le restaurant est requis",
  },
  file: {
    required: "Le fichier est requis",
    validate: {
      isValidFormat: (file) => {
        if (!file) return true;
        const supportedFormats = [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "text/csv",
        ];
        return supportedFormats.includes(file.type || file.mimeType)
          ? true
          : "Veuillez sélectionner un fichier valide (Excel ou CSV).";
      },
      isUnderSizeLimit: (file) => {
        if (!file) return true;
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        return file.size <= maxSizeInBytes
          ? true
          : "Le fichier doit être moins de 5MB.";
      },
    },
  },
});
