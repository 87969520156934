import { FC, useState } from "react";
import "./Documents.css";
import "./../ResetPassword/AskEmail/AskEmailComponent.css";
import { useNavigate, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { FaFilePdf, FaUpload, FaTag, FaRegTrashAlt } from "react-icons/fa";
import { useToast } from "../../../contexts/Toast/ToastProvider";
import handleAsyncResponse from "../../../helpers/handleAsyncResponse";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../interface/Types/AppDispatch";
import {
  addDocument,
  deleteDocument,
  fetchMyDocuments,
  selectUser,
} from "../../../store/User";
import { useNotification } from "../../../hooks/useNotification";
import FileInput from "../../Reusables/InputField/FileInput";
import { documentRules } from "../../../helpers/Validators/DocumentValidationRules";
import {
  IDocument,
  IDocumentToSend,
} from "../../../interface/Documents/IDocument";
import { GenericInput } from "../../Reusables/InputField/InputField";
import { formatDate } from "../../../helpers/getDate";
import DocumentStatusIcon from "./DocumentStatusIcon";
import Tooltip from "../../Reusables/Tooltip/Tooltip";
import { IoReload } from "react-icons/io5";
import { handleAsyncGetResponse } from "../../../helpers/handleAsyncGetResponse";
import colors from "../../constants/colors";
import BackgroundImage from "../../../assets/img/background-login.png";

const Documents: FC = () => {
  const methods = useForm<IDocumentToSend>();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("send");

  const { addNotification } = useNotification();
  const { addToast } = useToast();

  const user = useSelector(selectUser);

  const handleDeleteDocument = (document: IDocument) => {
    handleAsyncResponse(
      dispatch,
      deleteDocument(document.id),
      {
        successTitle: `Document supprimé`,
        successMessage: `Le document a été supprimé ! 🙌`,
        successDescription:
          "Le document " + document.name + " a été supprimé 🙌",
      },
      { addToast, addNotification, navigate }
    );
  };

  const handleRefreshDocument = () => {
    handleAsyncGetResponse(
      dispatch,
      fetchMyDocuments(),
      addToast,
      "documents",
      undefined,
      {
        optionalTitle: "Aucun document disponible",
        optionalText: "Vous n'avez aucun document sur votre compte SafeEat 😢",
      }
    );
  };

  const onSubmit = async (data: IDocumentToSend) => {
    const documentData = new FormData();

    documentData.append("document", data.document);
    documentData.append("name", data.name);

    handleAsyncResponse(
      dispatch,
      addDocument(documentData),
      {
        successTitle: `Document envoyé`,
        successMessage: `Le document a été envoyé ! 🙌`,
        successDescription: "Le document a été envoyé ! 🙌",
      },
      { addToast, addNotification, navigate }
    );
    setActiveTab("view");
  };

  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <FormProvider {...methods}>
      <div className="account-pending-my-documents-container" style={style}>
        <div className="account-pending-document-tabs">
          <button
            onClick={() => setActiveTab("send")}
            className={activeTab === "send" ? "account-pending-tab-active" : ""}
          >
            Envoyer mes documents
          </button>
          <button
            onClick={() => setActiveTab("view")}
            className={activeTab === "view" ? "account-pending-tab-active" : ""}
          >
            Voir mes documents
          </button>
        </div>
        {activeTab === "send" && (
          <div className="upload-document-container">
            <div className="upload-document-box-container">
              <h2 className="upload-document-title">Envoyer votre document</h2>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="upload-document-form-container"
              >
                <GenericInput
                  name={"name"}
                  icon={FaTag}
                  placeholder={"Le nom de votre document"}
                  id={"name"}
                  rules={documentRules.name}
                />
                <FileInput name={"document"} rules={documentRules.file} />

                <div className="upload-document-actions-container">
                  <button
                    type="submit"
                    className={`upload-document-btn ${
                      isUploaded && "isUploaded"
                    }`}
                    disabled={isUploaded}
                  >
                    <FaUpload className="upload-document-btn-icon" />
                    Envoyer le document
                  </button>
                  <div className="upload-document-login-page-container"></div>
                </div>
              </form>
              <p className="upload-document-copyright">
                © 2024 SafeEat. Tous droits réservés.
              </p>
            </div>
          </div>
        )}
        {activeTab === "view" && (
          <div className="upload-document-container">
            <div className="upload-document-box-container account-pending-box-container">
              <h2 className="upload-document-title">
                {user.documents.length > 1
                  ? "Cheminement de vos documents envoyés"
                  : "Cheminement de votre document envoyé"}
              </h2>
              <div
                className="account-pending-document-icon"
                onClick={() => handleRefreshDocument()}
              >
                <IoReload
                  size={22}
                  className="account-pending-document-refresh-icon"
                />
                <span className="account-pending-document-refresh-icon">
                  Recharger les documents
                </span>
              </div>
              <div className="account-pending-view-status-documents-container">
                {user.documents && user.documents.length !== 0 ? (
                  user.documents.map((doc) => (
                    <div
                      className="account-pending-document-container"
                      key={doc.id}
                    >
                      <div className="account-pending-document-wrapper">
                        <Tooltip content={doc.status}>
                          <div className="account-pending-document-content-container">
                            <div className="account-pending-document-informations">
                              <FaFilePdf
                                size={55}
                                color="red"
                                className="account-pending-documents-file-icon"
                              />
                              <div className="account-pending-document-texts">
                                <div title={doc.name}>{doc.name}</div>
                                <div>{formatDate(doc.created_at)}</div>
                              </div>
                            </div>
                            <div
                              className={`account-pending-document-status-icon ${
                                doc.status === "En cours" &&
                                "account-pending-document-status-icon-inprogress"
                              }`}
                            >
                              <DocumentStatusIcon status={doc.status} />
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                      <div
                        className="account-pending-document-icon"
                        onClick={() => handleDeleteDocument(doc)}
                      >
                        <FaRegTrashAlt className="account-pending-document-delete-icon" />
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Aucun document envoyé pour le moment.</div>
                )}
              </div>
              <Link
                to="/connexion"
                className="upload-document-copyright"
                style={{ color: colors.brown }}
              >
                Se connecter
              </Link>
              <p className="upload-document-copyright">
                © 2024 SafeEat. Tous droits réservés.
              </p>
            </div>
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default Documents;
