import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEditUser, IToken, IUser } from "../interface/IUser";
import { removeTokenFromLocalStorage } from "../helpers/localStorage";
import { RootState } from "./Store";
import { UserDocuments, UserProfiles } from "../api/api";
import { IDocument, IDocumentToSend } from "../interface/Documents/IDocument";
import { ApiErrorResponse } from "../interface/RejectValue";

export const addDocument = createAsyncThunk<
  IDocumentToSend, // Type de la réponse attendue de l'API
  FormData, // Le type de paramètres passés au thunk, ici un FormData pour l'upload de fichier
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées en cas d'erreur
>("addDocument", async (documentData: FormData, { rejectWithValue }) => {
  try {
    const response = await UserDocuments.addDocument(documentData);
    return response; // Assurez-vous de renvoyer les données appropriées
  } catch (error) {
    // Assurez-vous que le backend renvoie un objet d'erreur qui contient `data.errors`
    return rejectWithValue(error.response.data.errors);
  }
});

export const fetchMyDocuments = createAsyncThunk<
  IDocument[], // Type de la réponse attendue de l'API
  undefined, // Pas de paramètres nécessaires pour cette action
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées en cas d'erreur
>("documents/fetchMyDocuments", async (_, { rejectWithValue }) => {
  try {
    const response = await UserDocuments.getMyDocuments();
    return response; // Assurez-vous de renvoyer les données appropriées
  } catch (error) {
    // Assurez-vous que le backend renvoie un objet d'erreur qui contient `data.errors`
    return rejectWithValue(error.response.data.errors);
  }
});

export const deleteDocument = createAsyncThunk(
  "documents/deleteDocument",
  async (documentId: number, { rejectWithValue }) => {
    try {
      const response = await UserDocuments.deleteDocument(documentId);
      return { documentId, ...response }; // Renvoie l'ID de la recette supprimée pour mise à jour de l'état
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editMyAccountForAdmin = createAsyncThunk<
  IEditUser, // Type de la réponse en cas de succès
  FormData, // Type de l'argument passant au thunk
  { rejectValue: ApiErrorResponse } // Type pour les valeurs rejetées
>("admin/editMyAccountForAdmin", async (formData, { rejectWithValue }) => {
  try {
    // Appel API pour la mise à jour de la recette
    const response = await UserProfiles.updateMyAccountForAdmin(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data.errors);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: <IUser>{},
    isProfessionnal: <boolean>false,
    token: <IToken>{},
    document: <IDocument>{},
    isValid: false,
    done: false,
    loading: false,
    errors: {} as any,
  },
  reducers: {
    addUserToStore(state: any, action: PayloadAction<IUser | IEditUser>) {
      state.user = action.payload;
    },
    addTokenToStore(state: any, action: PayloadAction<IToken>) {
      state.token = action.payload;
    },
    setProfessionnalStatus(
      state: any,
      action: PayloadAction<boolean | undefined>
    ) {
      state.isProfessionnal = action.payload;
    },
    logoutAndRemoveUserToStore: (state) => {
      state.isValid = false;
      state.user = <IUser>{};
      state.isProfessionnal = false;
      state.token = <IToken>{};
      removeTokenFromLocalStorage();
      window.localStorage.removeItem("hasVisitedDashboard");
      UserProfiles.logout();
    },
    setAuthenticated: (state, action) => {
      state.isValid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMyDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.user.documents = action.payload;
        state.errors = {};
      })
      .addCase(fetchMyDocuments.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(addDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (!state.user.documents) {
          state.user.documents = [];
        }
        state.user.documents.push(action.payload);
        state.errors = {};
      })
      .addCase(addDocument.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;

        state.user.documents = state.user.documents.filter(
          (doc) => doc.id !== action.payload.documentId
        );

        state.errors = {};
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(editMyAccountForAdmin.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(editMyAccountForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = {};
        state.user = action.payload;
      })
      .addCase(editMyAccountForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
        state.done = true;
      });
  },
});

export const selectUser = (state: { user: { user: IUser } }) => state.user.user;
export const selectToken = (state: { user: { token: IToken } }) =>
  state.user.token;
export const selectIsProfessionnal = (state: {
  user: { isProfessionnal: boolean };
}) => state.user.isProfessionnal;
export const selectIsAuthenticated = (state: RootState) => state.user.isValid;

export const {
  setAuthenticated,
  addUserToStore,
  addTokenToStore,
  setProfessionnalStatus,
  logoutAndRemoveUserToStore,
} = userSlice.actions;

export default userSlice.reducer;
