import "./SelectAllergens.css";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllAllergens } from "../../../../../store/Restaurant";
import { IPublicAllergensFilter } from "../../../../../interface/Public/IPublic";
import MutliSelect from "../../../../Reusables/MutliSelect";
import { IAllergens } from "../../../../../interface/IAllergens";
import { getTextColor } from "../../../../../helpers/color";

const allergenGroups = {
  "huile d'olive": ["huile d'olive", "olive"],
  olive: ["huile d'olive", "olive"],
  raisin: ["raisin", "vinaigre", "vinaigre balsamique"],
  vinaigre: ["raisin", "vinaigre", "vinaigre balsamique"],
  "vinaigre balsamique": ["raisin", "vinaigre", "vinaigre balsamique"],
  "noix de coco": ["noix de coco", "lait de coco"],
  "lait de coco": ["noix de coco", "lait de coco"],
  gluten: ["gluten", "blé"],
  blé: ["gluten", "blé"],
  "fruit de la passion": ["fruit de la passion", "maracuja"],
  maracuja: ["fruit de la passion", "maracuja"],
  "fruit du dragon": ["fruit du dragon", "pitaya"],
  pitaya: ["fruit du dragon", "pitaya"],
};

const SelectAllergens: FC<IPublicAllergensFilter> = ({
  setAllergenFilters,
  primaryColor,
  secondaryColor,
}) => {
  const allergens = useSelector(selectAllAllergens);
  const [selectedAllergens, setSelectedAllergens] = useState<IAllergens[]>([]);

  // Function to get all related allergens
  const getGroupAllergens = (allergenName: string) => {
    return allergenGroups[allergenName.toLowerCase()] || [allergenName];
  };

  const addToCurrentTags = (allergenToAdd: IAllergens) => {
    const groupAllergens = getGroupAllergens(allergenToAdd.name.toLowerCase());
    const updatedAllergens = [
      ...selectedAllergens,
      ...allergens.filter(
        (allergen) =>
          groupAllergens.includes(allergen.name.toLowerCase()) &&
          !selectedAllergens.some((a) => a.name === allergen.name)
      ),
    ];
    setSelectedAllergens(updatedAllergens);
    setAllergenFilters(updatedAllergens.map((allergen) => allergen.name));
  };

  const removeFromCurrentTags = (allergenToRemove: IAllergens) => {
    const groupAllergens = getGroupAllergens(
      allergenToRemove.name.toLowerCase()
    );
    const updatedAllergens = selectedAllergens.filter(
      (allergen) => !groupAllergens.includes(allergen.name.toLowerCase())
    );
    setSelectedAllergens(updatedAllergens);
    setAllergenFilters(updatedAllergens.map((allergen) => allergen.name));
  };

  return (
    <div className="public-select-allergens-container">
      <div
        className="public-select-allergens-title"
        style={{ color: primaryColor }}
      >
        Saisissez vos allergènes
      </div>
      <MutliSelect
        data={allergens}
        currentTags={selectedAllergens}
        addToCurrentTags={addToCurrentTags}
        removeFromCurrentTags={removeFromCurrentTags}
        publicColor={secondaryColor}
        publicTextColor={getTextColor(secondaryColor)}
        iconColor={primaryColor}
      />
    </div>
  );
};

export default SelectAllergens;
