import { FC, useState, useMemo, useEffect, MouseEvent } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { getTextColor } from "../../../../../helpers/color";
import { IRecipeInMenuCounts } from "../../../../../interface/Menus/IRecipeInMenuCounts";
import "./PublicMenuCard.css";
import { IPublicMenus } from "../../../../../interface/Public/IPublic";
import { IRestaurantRecipe } from "../../../../../interface/IRestaurantRecipe";
import PublicMenuRecipesList from "../../PublicRecipes/PublicMenuRecipesList/PublicMenuRecipesList";

type IPublicMenuCard = IPublicMenus & {
  primaryColor: string;
  secondaryColor: string;
};

const MenuCard: FC<IPublicMenuCard> = ({
  id,
  name,
  price,
  is_child_menu,
  availability_start_time,
  availability_end_time,
  recipes,
  updated_at,
  primaryColor,
  secondaryColor,
}) => {
  const [toggleCard, setToggleCard] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const recipeTags = useMemo(() => {
    if (!recipes) {
      return [];
    }

    const counts: IRecipeInMenuCounts = {
      entrée: recipes.starters?.length || 0,
      plat: recipes.dishes?.length || 0,
      accompagnement: recipes.accompaniments?.length || 0,
      dessert: recipes.desserts?.length || 0,
    };

    return Object.entries(counts)
      .map(([key, count]) => ({
        category: key,
        tag: `${count} ${key}${count > 1 ? "s" : ""}`,
        count: count,
      }))
      .filter((item) => item.count > 0);
  }, [recipes]);

  const textColor = getTextColor(primaryColor);

  const handleToggleCard = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setToggleCard(!toggleCard);
  };

  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="public-menu-card-container" onClick={handleToggleCard}>
      <div className="public-menu-card-top">
        <div className="public-menu-card-top-title">{name}</div>
        <div className="public-menu-card-top-price">{price + " €"}</div>
      </div>
      <div className="public-menu-card-middle">
        {recipeTags.map((tag, idx) => (
          <div
            key={idx}
            className="public-menu-card-recipe-tag"
            style={{ backgroundColor: primaryColor, color: textColor }}
          >
            {tag.tag}
          </div>
        ))}
      </div>
      {toggleCard && (
        <PublicMenuRecipesList
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          starters={recipes.starters}
          dishes={recipes.dishes}
          accompaniments={recipes.accompaniments}
          desserts={recipes.desserts}
        />
      )}
      <div className="public-menu-card-bottom">
        {toggleCard ? (
          <IoIosArrowUp size={24} color={primaryColor} />
        ) : (
          <IoIosArrowDown size={24} color={primaryColor} />
        )}
      </div>
    </div>
  );
};

export default MenuCard;
