import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "../../assets/img/safeeat-logo.png";
import { FaSignInAlt } from "react-icons/fa";
import "./login.css";
import { IUserAuth } from "../../interface/IUser";
import { AppDispatch } from "../../interface/Types/AppDispatch";
import { EmailInput, PasswordInput } from "../Reusables/InputField/InputField";
import {
  addTokenToStore,
  addUserToStore,
  setAuthenticated,
} from "../../store/User";
import { UserProfiles } from "../../api/api";
import { useToast } from "../../contexts/Toast/ToastProvider";
import BackgroundImage from "../../assets/img/background-login.png";
import Roles from "../../enums/Roles";
import ChooseSide from "./ChooseSide/ChooseSide";

const LoginComponent: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserAuth>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [firstVisit, setFirstVisit] = useState<boolean>(true);
  const [isChef, setIsChef] = useState<boolean>(null);

  const { addToast } = useToast();

  const onSubmit = async (data: IUserAuth) => {
    try {
      localStorage.clear();
      const response = await UserProfiles.authUser(data);
      const { token, rolesId, ...restOfUser } = response;
      dispatch(addUserToStore({ ...restOfUser, rolesId }));
      dispatch(addTokenToStore({ token }));
      dispatch(setAuthenticated(true));
      localStorage.setItem(
        "userToken",
        JSON.stringify({ token: token?.token })
      );

      let welcomeMessage = "Bienvenue sur SafeEat ! 🙌";
      let redirectPath = "/connexion";

      if (rolesId === Roles.PROFESSIONNAL) {
        if (restOfUser.isActivated) {
          welcomeMessage =
            "Bienvenue sur votre espace restaurateur SafeEat ! 🙌";
          redirectPath = "/pro/tableau-de-bord";
        } else {
          welcomeMessage =
            "Visualiser et envoyer le.s contrat.s signé.s pour activer votre compte ! 🤝";
          redirectPath = "/pro/activation/mes-documents";
        }
      } else if (rolesId === Roles.ADMIN) {
        welcomeMessage =
          "Bienvenue sur votre espace administrateur SafeEat ! 🙌";
        redirectPath = "/admin/tous-les-utilisateurs";
      }

      addToast("info", "Bienvenue", welcomeMessage);
      navigate(redirectPath);
    } catch (error) {
      addToast(
        "error",
        "Erreur rencontrée",
        error.response.data.errors[0].message.split(":")[1]
      );
    }
  };

  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="login-container" style={style}>
      <div className="logo-container">
        <img src={Logo} className="login-safeeat-logo" alt="SafeEat Logo" />
      </div>
      <div className="login-box-container">
        <h2 className="login-title">Connexion à SafeEat</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="login-form-container"
        >
          <EmailInput control={control} name="email" />
          <PasswordInput control={control} name="password" />
          <div className="login-actions-container">
            <button type="submit" className="login-btn">
              <FaSignInAlt className="login-btn-icon" />
              Se connecter
            </button>
            <div className="login-reset-password-container">
              <Link
                to="/envoyer-une-demande-de-réinitialisation-de-mon-mot-de-passe"
                className="login-brown"
              >
                Mot de passe oublié ?
              </Link>
            </div>
          </div>
        </form>
        <p className="login-copyright">© 2024 SafeEat. Tous droits réservés.</p>
        <p className="login-copyright">Version 1.0.0</p>
      </div>
      <div className="login-box-container">
        <div className="login-legals-container">
          <div className="login-legals">
            <Link
              to="/conditions-generales-d-utilisation"
              className="login-brown"
            >
              Conditions générales d'utiisation
            </Link>
          </div>
          <div className="login-legals">
            <Link to="/politique-de-confidentialite" className="login-brown">
              Politique de confidentialité
            </Link>
          </div>
          <div className="login-legals">
            <Link to="/mentions-legales" className="login-brown">
              Mentions légales
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginComponent;
