const restaurantTypeOptions = [
  {
    name: "Brasserie",
  },
  {
    name: "Bistrot",
  },
  {
    name: "Gastronomique",
  },
  {
    name: "Rôtisserie",
  },
  {
    name: "Tapas",
  },
  {
    name: "Pizzeria",
  },
  {
    name: "Autres",
  },
];

export default restaurantTypeOptions;
