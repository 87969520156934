import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAllergens } from "../../interface/IAllergens";
import { IRestaurantProfile } from "../../interface/IRestaurantProfile";
import { IRestaurantRecipe } from "../../interface/IRestaurantRecipe";
import {
  fetchRestaurantMenuByRestaurantName,
  fetchUserRestaurantProfileByRestaurantName,
} from "./Requests";
import {
  IPublicMenus,
  IRestaurantRecipeCategoryForBasket,
  IUserChoices,
} from "../../interface/Public/IPublic";

export const publicRestaurants = createSlice({
  name: "publicRestaurants",
  initialState: {
    publicRestaurant: <IRestaurantProfile>{},
    publicMenus: <IPublicMenus[]>[],
    userChoices: {
      entrée: [],
      plat: [],
      accompagnement: [],
      dessert: [],
    } as IUserChoices, // Initialisation avec une structure par défaut
    restaurantRecipes: <IRestaurantRecipe[]>[],
    userAllergens: <IAllergens[]>[],
    done: false,
    loading: false,
    errors: {} as any,
  },
  reducers: {
    addPublicRestaurantToStore(state: any, action) {
      state.publicRestaurant = action.payload;
    },
    addUserAllergensToStore(state: any, action) {
      state.userAllergens = action.payload;
    },
    addRecipeToUserChoices(state, action) {
      const { category, recipe } = action.payload;

      console.log(state.userChoices);

      // Vérification si la catégorie est valide
      if (!state.userChoices || !state.userChoices.hasOwnProperty(category)) {
        console.error(
          `Catégorie invalide ou userChoices est undefined: ${category}`
        );
        state.userChoices = {
          entrée: [],
          plat: [],
          accompagnement: [],
          dessert: [],
        };
        return;
      }

      const categoryIndex = state.userChoices[category].findIndex(
        (r) => r.id === recipe.id
      );

      if (categoryIndex > -1) {
        // Si la recette existe déjà, ne pas l'ajouter à nouveau
        return;
      }

      // Ajoute la recette à la catégorie correspondante
      state.userChoices[category].push(recipe);
    },

    removeRecipeFromUserChoices(state, action) {
      const { category, recipeId } = action.payload;

      // Filtre les recettes pour supprimer celle avec l'ID donné
      state.userChoices[category] = state.userChoices[category].filter(
        (recipe) => recipe.id !== recipeId
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRestaurantProfileByRestaurantName.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(
        fetchUserRestaurantProfileByRestaurantName.fulfilled,
        (state, action) => {
          state.loading = false;
          state.errors = {};
          state.publicRestaurant = action.payload;
          state.restaurantRecipes = action.payload.restaurantRecipe;
          state.done = true;
        }
      )
      .addCase(
        fetchUserRestaurantProfileByRestaurantName.rejected,
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
          state.done = true;
        }
      )
      .addCase(fetchRestaurantMenuByRestaurantName.pending, (state) => {
        state.loading = true;
        state.errors = {};
      })
      .addCase(
        fetchRestaurantMenuByRestaurantName.fulfilled,
        (state, action) => {
          state.loading = false;
          state.publicMenus = action.payload;
          state.errors = {};
        }
      )
      .addCase(
        fetchRestaurantMenuByRestaurantName.rejected,
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
          state.done = true;
        }
      );
  },
});

export const selectPublicRestaurant = (state: {
  publicRestaurants: { publicRestaurant: IRestaurantProfile };
}) => state.publicRestaurants.publicRestaurant;

export const selectPublicRestaurantRecipes = (state: {
  publicRestaurants: { restaurantRecipes: IRestaurantRecipe[] };
}) => state.publicRestaurants.restaurantRecipes;

export const selectPublicMenus = (state: {
  publicRestaurants: { publicMenus: IPublicMenus[] };
}) => state.publicRestaurants.publicMenus;

export const selectUserAllergens = (state: {
  publicRestaurants: { userAllergens: IAllergens[] };
}) => state.publicRestaurants.userAllergens;

export const selectPublicUserChoices = (state: {
  publicRestaurants: { userChoices: IUserChoices };
}) => state.publicRestaurants.userChoices;

export const selectLoading = (state: {
  publicRestaurants: { loading: boolean };
}) => state.publicRestaurants.loading;
export const selectErrors = (state: { publicRestaurants: { errors: any } }) =>
  state.publicRestaurants.errors;

export const {
  addPublicRestaurantToStore,
  addUserAllergensToStore,
  addRecipeToUserChoices,
  removeRecipeFromUserChoices,
} = publicRestaurants.actions;

export default publicRestaurants.reducer;
