import { FC } from "react";
import PublicRestaurantComponent from "../../components/Public/PublicRestaurantComponent";

const PublicRestaurant: FC = () => {
  return (
    <div className="public-restaurant">
      <PublicRestaurantComponent />
    </div>
  );
};

export default PublicRestaurant;
